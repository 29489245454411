import { ProviderUtils } from "./providerUtils";

const providerTWPrefixes = ProviderUtils.prodProviderList
  .map((Provider) => Provider.twPrefix)
  .filter((twPrefix) => twPrefix);

// console.log("providerTWPrefixes", providerTWPrefixes);

/**
 * Generate a list of tailwind utility classes with different prefixes.
 * @param {string} twUtility - tailwind utility (e.g. text, bg, border, etc.)
 * @param {string} twValue - tailwind value (e.g. sm, md, lg, etc.)
 * @returns {string} - a string of tailwind classes
 * @example
 * generateTWClasses('bg', 'primary-light') 
 *  returns 'bg-primary-light bc:bg-bc-primary-light lotte:bg-lotte-primary-light hana:bg-hana-primary-light gme:bg-gme-primary-light redtable:bg-redtable-primary-light'
 */
export const generateTWClasses = (twUtility: string, twValue: string) => {
  const baseTWClass = `${twUtility}-${twValue}`;

  const twClasses = providerTWPrefixes.map((twPrefix) => {
    return `${twPrefix}:${twUtility}-${twPrefix}-${twValue}`;
  });

  const finalClasses = `${baseTWClass} ${twClasses.join(" ")}`;

  return finalClasses;
};
