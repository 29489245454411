import React, { createContext } from "react";
import { TravelReservationProduct } from "services/travelService";

export interface JTRPassenger {
  name: string;
  email: string;
}

interface ActivityReservationContextType {
  bookerInfo: {
    name: string;
    email: string;
    phone: string;
  };
  setBookerInfo: React.Dispatch<
    React.SetStateAction<{
      name: string;
      email: string;
      phone: string;
    }>
  >;

  travelDate: string;
  setTravelDate: React.Dispatch<React.SetStateAction<string>>;
  productList: TravelReservationProduct[];
  setProductList: React.Dispatch<
    React.SetStateAction<TravelReservationProduct[]>
  >;
  number: number;
  setNumber: React.Dispatch<React.SetStateAction<number>>;

  passengerDetails: JTRPassenger[];
  setPassengerDetails: React.Dispatch<React.SetStateAction<JTRPassenger[]>>;
  isPassengersValid: boolean;
  setIsPassengersValid: React.Dispatch<React.SetStateAction<boolean>>;

  passengersDetailRef: React.RefObject<HTMLDivElement>;
}

export const ActivityReservationContext =
  createContext<ActivityReservationContextType>(null!);
