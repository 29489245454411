import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";
import { useLogoutMutation } from "app/apiSlice";
import useReduxUtils from "hooks/useReduxUtils";
import { FormattedMessage } from "react-intl";
// import useReduxUtils from "hooks/useReduxUtils";

interface LogoutButtonProps {
  size?: "small" | "medium" | "large";
  icon?: boolean;
}
const LogoutButton = ({ size = "medium", icon = true }: LogoutButtonProps) => {
  const navigate = useNavigate();
  const { resetStore } = useReduxUtils({ retainToken: false });

  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    logout().then(() => {
      // AuthService.logout().then(() => {
      // resetStore();
      // dispatch(deleteUser());
      // dispatch(deleteReservation());
      // dispatch(deleteMyOrders());
      // dispatch(resetCard());

      // // localStorage에 있는 redux-persist 삭제
      // dispatch(logout());
      resetStore();

      navigate("/");
    });
  };
  /* 
  In the real world, it's very common that  
  */

  return (
    <CustomButton
      className="!bg-white"
      variant="outlined"
      size={size}
      onClick={handleLogout}
    >
      {icon && <LogoutIcon />}
      <FormattedMessage id="user.signOut" />
    </CustomButton>
  );
};

export default LogoutButton;
