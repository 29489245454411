import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentService from "services/paymentService";
import LoadingSpinner from "components/LoadingSpinner";
import { OrderType } from "types/orderType";
// import PaymentPageContent from "./PaymentPageContent";
import OrderDetailPage from "pages/ktx/order-history-panel/order-detail-panel/OrderDetailPage";
import TravelService from "services/travelService";

const initialAgreements = {
  term1: false,
  term2: false,
  term3: false,
  term4: false,
};

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

interface PaymentPageProps {
  isHistory: boolean;
}

const PaymentPage = ({ isHistory }: PaymentPageProps) => {
  const navigate = useNavigate();
  const [reservationDetails, setReservationDetails] = useState<OrderType>();
  const { orderId } = useParams();

  // const isPending = reservationDetails?.orderStatus === "PENDING";

  // Fetch reservation details from server
  useEffect(() => {
    if (orderId) {
      PaymentService.reserveDetail(orderId)
        .then((data) => {
          if (data.errorMessage) {
            // train reservation이 없는 경우 travel reservation 찾기
            TravelService.getTravelReservation(orderId)
              .then((data) => {
                setReservationDetails(data);
              })
              .catch((error) => {
                throw error;
              });
            return;
          }
          setReservationDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
          navigate(-1);
        });
    }
  }, [navigate, orderId]);

  if (!reservationDetails) {
    return <LoadingSpinner overlap />;
  }

  return <OrderDetailPage reservationDetails={reservationDetails} />;
  // return isPending ? (
  //   <PaymentPageContent
  //     isHistory={isHistory}
  //     reservationDetails={reservationDetails}
  //   />
  // ) : (
  //   <OrderDetailPage reservationDetails={reservationDetails} />
  // );
};

export default PaymentPage;
