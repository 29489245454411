import { Stack, useTheme } from "@mui/material";
import Layout from "components/layout/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderType } from "types/orderType";
import useCancelReservation from "hooks/useCancelReservation";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";
import CustomButton from "components/button/CustomButton";
import OrderMainContent from "../OrderMainContent";
import useSaveAsImage from "hooks/useSaveAsImage";
import { __PROD__ } from "const/properties";

interface OrderDetailPageProps {
  reservationDetails: OrderType;
}

const OrderDetailPage = ({ reservationDetails }: OrderDetailPageProps) => {
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const navigate = useNavigate();
  const hanacard = useLocation().state?.hanacard ?? false;
  const orderId = reservationDetails?.orderId;
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";
  const saveAsImage = useSaveAsImage(orderId);

  const backToOrderList = useCallback(async () => {
    if (hanacard) {
      navigate("/hanacard/reservations");
      return;
    }
    navigate(-1);

    // navigate("/order-history", { replace: true });
  }, [hanacard, navigate]);

  // cancel reservation
  const [CancelDetailsComponent] = useCancelReservation(reservationDetails);

  const productType = reservationDetails?.productType;

  return (
    <Layout
      text={<FormattedMessage id="orders.detailTitle" />}
      onBack={backToOrderList}
    >
      {!reservationDetails ? (
        <LoadingSpinner />
      ) : (
        <Stack gap={1.5} sx={{ my: 2 }}>
          <OrderMainContent reservationDetails={reservationDetails} />
          {/* 하나카드 - 내역 저장하기 버튼 */}
          {ProviderUtils.isHanacard && (
            <CustomButton
              className="hide-from-saved-image"
              size="medium"
              variant="outlined"
              style={{ backgroundColor: theme.palette.white.main }}
              onClick={saveAsImage}
            >
              {isKorean ? "내역 저장하기" : "Save as Image"}
            </CustomButton>
          )}

          {__PROD__ && productType === "TRAVEL"
            ? null
            : isPurchased && (
                <div className="hide-from-saved-image">
                  {/* 결제 완료한 경우 취소 예상 금액 보여주기 */}
                  {CancelDetailsComponent}
                </div>
              )}
        </Stack>
      )}
    </Layout>
  );
};

export default OrderDetailPage;
