import { Skeleton } from "@mui/material";
import clsx from "clsx";
import { HEADER_HEIGHT } from "const/layout";

interface ThumbnailSkeletonProps {
  number?: number;
  direction: "row" | "column";
}

const ThumbnailSkeleton = ({ number, direction }: ThumbnailSkeletonProps) => {
  const screenHeight = window.screen.height;
  const numOfSkeleton = number
    ? number
    : Math.floor((screenHeight - HEADER_HEIGHT * 2) / 120);

  return Array.from(Array(numOfSkeleton)).map((_, key) => (
    <div
      key={key}
      className={clsx(
        "flex w-full gap-2",
        direction === "column" && "flex-col"
      )}
    >
      <Skeleton
        variant="rounded"
        height={direction === "row" ? 120 : 160}
        className={clsx({ "w-1/2": direction === "row" })}
      />
      <div
        className={clsx(
          "flex w-full flex-col justify-between",
          direction === "row" && "h-full"
        )}
      >
        <div>
          <Skeleton variant="text" />
          {direction === "row" && <Skeleton variant="text" />}
        </div>
        <Skeleton variant="text" className="w-1/2 self-end" />
      </div>
    </div>
  ));
};

export default ThumbnailSkeleton;
