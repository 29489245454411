import { forwardRef } from "react";
import ActivityDetailLayout from "../components/ActivityDetailLayout";
import parse from "html-react-parser";

interface ProductCancelRefundInfoSectionProps {
  title: string;
  content: string;
}

const ProductCancelRefundInfoSection = forwardRef<
  HTMLDivElement,
  ProductCancelRefundInfoSectionProps
>(({ title, content }, ref) => {
  return (
    <ActivityDetailLayout ref={ref} title={title}>
      <div className="min-h-[50vh] pb-4">{parse(content)}</div>
    </ActivityDetailLayout>
  );
});

export default ProductCancelRefundInfoSection;
