import BookingInfos from "../../components/booking_info/BookingInfos";
import QRCode from "components/QRCode";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import useLanguages from "hooks/useLanguages";
import { useLocation, useNavigate } from "react-router-dom";
import TickerNotice from "pages/tickets/components/TickerNotice";
import { OrderType } from "types/orderType";
import { useEffect, useState } from "react";
import PaymentService from "services/paymentService";
import LoadingSpinner from "components/LoadingSpinner";

interface TicketProps {
  reservationDetails?: OrderType;
}

const Ticket = ({ reservationDetails: order }: TicketProps) => {
  const navigate = useNavigate();
  const { isKorean } = useLanguages();

  const [reservationDetails, setReservationDetails] = useState<
    OrderType | undefined
  >(undefined);

  // Fetch reservation details from server
  useEffect(() => {
    if (order) {
      const orderId = order.orderId;
      PaymentService.reserveDetail(Number(orderId))
        .then((data) => {
          console.log(data);
          setReservationDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
          navigate(-1);
        });
    }
  }, [navigate, order]);

  const state = useLocation().state ?? reservationDetails;
  const productType = state.productType;

  const voucher = state.travelGoods?.voucher[0];
  // console.log("voucher", voucher);

  // const ticketType = voucher?.type;
  const ticketUrl =
    voucher?.voucherUrl ??
    voucher?.pdfUrl ??
    voucher?.qrcodeUrl ??
    voucher?.code;

  // console.log("ticketType", ticketType);
  // console.log("ticketUrl", ticketUrl);

  return !state ? (
    <LoadingSpinner className="h-1/2" />
  ) : productType === "TRAIN" ? (
    state.orderStatus === "COMPLETE" ? (
      <Container
        sx={{
          mt: reservationDetails ? 0 : 2,
          mb: 2,
          px: 0,
          wordBreak: "keep-all",
        }}
      >
        <BookingInfos
          order={state}
          qrCode={
            <QRCode
              src={`data:image/png;base64,${state?.trainList[0].qrCodeImage}`}
            />
          }
        />
        <TickerNotice />
      </Container>
    ) : (
      <Stack
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: "100%", minHeight: "50dvh" }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ width: "100%", my: 6, textAlign: "center" }}
        >
          <CancelIcon color="error" sx={{ fontSize: 120 }} />
          <Typography
            variant="h6"
            sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
          >
            {isKorean ? "취소된 승차권입니다." : "Cancelled train ticket."}
          </Typography>
        </Stack>
      </Stack>
    )
  ) : (
    <iframe
      src={ticketUrl}
      title="ticket"
      className="absolute left-0 h-screen w-screen"
    />
  );
};

export default Ticket;
