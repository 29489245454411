import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionInterface } from "services/cardService";
import { NumericString } from "types/numericString";

export interface CardInterface {
  acnRmd: number; // 카드 잔액
  iapCdno: NumericString; // 카드 번호 16자리
  iapCrdStcd: "01" | "02" | "03"; // 카드 상태 -  정상, 정지, 해지
  rpstCrdYn: "Y" | "N"; // 대표카드 여부

  //
  transactionList: TransactionInterface[];
}

const initialState: CardInterface[] = [];
const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    fetchCards: (state, action: PayloadAction<CardInterface[]>) => {
      return [...action.payload];
      // state = action.payload;
      // return state;
    },
    addCard: (state, action: PayloadAction<CardInterface>) => {
      state = [...state, action.payload];
    },

    updateCard: (
      state,
      action: PayloadAction<{
        cardNumber: NumericString;
        balance?: number;
        mainCard?: "Y" | "N";
        status?: "01" | "02" | "03";
      }>
    ) => {
      const { cardNumber, balance, mainCard, status } = action.payload;
      const card = state.find((card) => card.iapCdno === cardNumber);
      if (card) {
        if (balance) {
          card.acnRmd = balance;
        }
        if (mainCard) {
          card.rpstCrdYn = mainCard;
        }
        if (status) {
          card.iapCrdStcd = status;
        }
      }
    },
    deleteCard: (state, action: PayloadAction<NumericString>) => {
      state = state.filter((card) => card.iapCdno !== action.payload);
    },

    changeOrder: (
      state,
      action: PayloadAction<{ from: number; to: number }>
    ) => {
      const { from, to } = action.payload;
      const card = state[from];
      state[from] = state[to];
      state[to] = card;
    },

    fetchTransactions: (
      state,
      action: PayloadAction<{
        cardNumber: NumericString;
        transactions: TransactionInterface[];
      }>
    ) => {
      const { cardNumber, transactions } = action.payload;
      const card = state.find((card) => card.iapCdno === cardNumber);
      if (card) {
        card.transactionList = transactions;
      }
    },

    resetCard: () => {
      return initialState;
    },
  },
});

export const {
  fetchCards,
  addCard,
  updateCard,
  deleteCard,
  changeOrder,
  // setMainCard,
  fetchTransactions,
  resetCard,
} = cardsSlice.actions;

export default cardsSlice.reducer;
