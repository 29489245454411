import { Grid, Typography, useTheme, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";
import { useAppSelector } from "app/hooks";
import { selectTrainParams } from "app/reservationSlice";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

interface OrderSummarySectionProps {
  originalPrice: number;
  pointEarned?: number;
  // reservationDetails: OrderType;
  point: number;
  hanpassCouponDiscountAmount?: number;
}

const OrderSummarySection = ({
  // reservationDetails,
  originalPrice,
  pointEarned = 0,
  point,
  hanpassCouponDiscountAmount = 0,
}: OrderSummarySectionProps) => {
  // const {
  // originalPrice,
  // totalDiscount: discount,
  //   // totalPrice: finalPrice, // 할인 금액
  // } = reservationDetails;

  const intl = useIntl();
  const { isKorean } = useLanguages();
  const theme = useTheme();

  const formatPrice = useFormatPrice();
  const { costDetails } = useAppSelector(selectTrainParams);
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  )!;

  // data
  // const totalDiscount = discount + hanpassCouponDiscountAmount;
  const totalDiscount = hanpassCouponDiscountAmount;

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {intl.formatMessage({ id: "payment.priceInfo" })}
      </Typography>
    );
  };

  // const getAdditionalInfo = () => {
  //   return (
  //     <Stack
  //       direction="row"
  //       justifyContent="space-between"
  //       sx={{ width: "100%" }}
  //     >
  //       <p>예상 적립금: </p>
  //       {formatPrice(
  //         Math.round(
  //           adultPriceDetail?.csmrFare *
  //             (1 - adultPriceDetail?.sellDscntRt / 100) *
  //             (adultPriceDetail?.pointSavingRt / 100)
  //         )
  //       )}
  //     </Stack>
  //   );
  // };

  const getAdditionalInfo = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <p>적립예정 적립금: </p>
        {formatPrice(pointEarned)}
      </Stack>
    );
  };

  return (
    <CardLayout
      header={getHeader()}
      additionalInfo={
        adultPriceDetail?.buyDscntRt ? getAdditionalInfo() : undefined
      }
    >
      {/* 복합상품금액 표시 */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>
            <FormattedMessage id="payment.combined" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              textDecoration: ProviderUtils.discountAvailable
                ? "line-through"
                : "none",
            }}
          >
            {formatPrice(originalPrice)}
          </Typography>
        </Grid>
      </Grid>

      {/* 할인 금액 표시 */}
      {totalDiscount > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>{isKorean ? "할인금액" : "Discount amount"}</Typography>
          </Grid>
          <Grid item>
            <Typography>{formatPrice(totalDiscount ?? 0)}</Typography>
          </Grid>
          {ProviderUtils.isHanpass && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                paddingInlineStart: 1,
                color: theme.palette.black.main,
              }}
            >
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: "start" }}>
                  * {isKorean ? "한패스 할인" : "Hanpass discount"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ textAlign: "end" }}>
                  {formatPrice(hanpassCouponDiscountAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {/* 수수료 */}
      {/* {commission > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="payment.commission" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{formatPrice(commission ?? 0)}</Typography>
          </Grid>
        </Grid>
      )} */}

      {/* 사용 적립금 */}
      {point > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="point.point" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>-{formatPrice(point)}</Typography>
          </Grid>
        </Grid>
      )}

      {/* 포인트파크 */}
      {/* {(__DEV__ || !ProviderUtils.provider) && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={5} sx={{ textAlign: "start" }}>
            <Typography sx={{ wordBreak: "keep-all" }}>
              <FormattedMessage id="pointpark.pointpark" />
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="end" gap={0.5}>
              <TextField
                value={pointParkPoint}
                disabled
                fullWidth
                InputProps={{
                  sx: {
                    height: "48px",
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                    },
                  },
                  startAdornment: pointParkPoint > 0 ? "-" : "",
                  endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
                }}
              />
              <Box sx={{ width: "40%" }}>
                <CustomButton
                  size="large"
                  onClick={
                    pointParkPoint ? cancelPointParkPoint : checkPointParkPoint
                  }
                >
                  {pointParkPoint
                    ? intl.formatMessage({ id: "pointpark.cancel" })
                    : intl.formatMessage({ id: "pointpark.apply" })}
                </CustomButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )} */}

      {/* 총주문금액 표시 */}
      <Grid container justifyContent="space-between" alignItems="end">
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="payment.totalPrice" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="error" sx={{ fontWeight: "bold" }}>
            {formatPrice(originalPrice - point - hanpassCouponDiscountAmount)}
          </Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default OrderSummarySection;
