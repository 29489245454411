import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useNavigate } from 'react-router-dom';

const MyCartIcon = () => {
  const navigate = useNavigate();

  return <ShoppingCartIcon onClick={() => navigate("/cart")} />;
};

export default MyCartIcon;
