import Layout from "components/layout/Layout";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import PaymentService from "services/paymentService";
import { Box, styled } from "@mui/material";
import { HEADER_HEIGHT } from "const/layout";

const PaymentProcessingPage = () => {
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const {
    state: { lottecardResponse },
  } = useLocation();

  const iframe = useRef<HTMLIFrameElement>(null!);
  const { startUrl, startParams } = lottecardResponse;
  const IFRAME_NAME = "lottecard-danal";

  const backToPaymentPage = () => {
    navigate(-1);
  };

  const handleClose = () => {
    if (paymentId) {
      // navigate(`/order-details/${paymentId}`);
      navigate(`/cart`);
      return;
    }
    navigate("/order-history");
  };

  useEffect(() => {
    try {
      PaymentService.redirectToDanal(startUrl, startParams, IFRAME_NAME);
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
      navigate(-1);
    }
  }, [navigate, startParams, startUrl]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "auto";
    };
  });

  useEffect(() => {
    window.closeLotteCompleteIframe = () => {
      navigate("/train/creditPayment");
    };
    window.closeLotteCancelIframe = () => {
      navigate("/train/creditCancel");
    };

    return () => {
      delete window.closeLotteCompleteIframe;
      delete window.closeLotteCancelIframe;
    };
  }, [navigate]);

  return (
    <Layout onBack={backToPaymentPage}>
      <PositionWrapper>
        <iframe
          ref={iframe}
          title={IFRAME_NAME}
          name={IFRAME_NAME}
          style={{
            border: "none",
          }}
        />
        <CloseIcon
          onClick={handleClose}
          fontSize="large"
          sx={{
            position: "fixed",
            top: `calc(env(safe-area-inset-top) + 8px)`,
            right: 8,
            cursor: "pointer",
            zIndex: 100000,
          }}
        />
      </PositionWrapper>
    </Layout>
  );
};

export default PaymentProcessingPage;

const PositionWrapper = styled(Box)`
  & > iframe {
    position: absolute;
    width: 100%;

    left: 0;
    top: calc(${HEADER_HEIGHT}px + env(safe-area-inset-top));
    top: calc(${HEADER_HEIGHT}px + constant(safe-area-inset-top));
    -webkit-top: calc(${HEADER_HEIGHT}px + env(safe-area-inset-top));
    -webkit-top: calc(${HEADER_HEIGHT}px + constant(safe-area-inset-top));

    height: calc(100dvh - ${HEADER_HEIGHT}px - env(safe-area-inset-top));
    /* max-height: 100vh; */

    background-color: grey;
  }
`;
