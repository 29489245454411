import { Button } from "@mui/material";
import useLanguages from "hooks/useLanguages";
import React from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface SectionHeadersProps {
  header: React.ReactNode;
  handleClick: () => void;
  buttonText?: React.ReactNode;
}

const SectionHeaders = ({
  header,
  handleClick,
  buttonText,
}: SectionHeadersProps) => {
  const { isLanguage } = useLanguages();

  return (
    <section className="flex items-center justify-between">
      <h6 className="mx-2 text-xl font-bold">
        {header}
        {/* <FormattedMessage id="user.recentOrders" /> */}
      </h6>
      <Button
        endIcon={isLanguage("ur") ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        color="secondary"
        onClick={handleClick}
        className="self-end !font-bold"
      >
        {/* <FormattedMessage id="user.more" /> */}
        {buttonText}
      </Button>
    </section>
  );
};

export default SectionHeaders;
