import { Grid, Stack, Typography } from "@mui/material";
import useFormatPrice from "hooks/useFormatPrice";
import TransactionIcon from "./TransactionIcon";
import { TransactionInterface } from "services/cardService";
import { NumericString } from "types/numericString";
import { LanguageCodeType } from "const/languageCodes";
import { useIntl } from "react-intl";
import { formatDateTimeFromString } from "utils/dateUtils";

// const formatDateTime = (date: NumericString, locale: LanguageCodeType) => {
//   const year = date.slice(0, 4);
//   const month = date.slice(4, 6);
//   const day = date.slice(6, 8);
//   return new Date(`${year}-${month}-${day}`).toLocaleDateString(locale);
// };

interface TransactionProps {
  transaction: TransactionInterface;
}

const Transaction = ({ transaction }: TransactionProps) => {
  const locale = useIntl().locale as LanguageCodeType;
  const formatPrice = useFormatPrice();

  // const isDeposit = transaction.trSmrCts === "충전";
  const isDeposit = transaction.trAfRmd > transaction.trBfRmd;

  return (
    <Grid container>
      <Grid item xs={7}>
        <Stack direction="row" justifyContent="start" gap={1}>
          <TransactionIcon category={"DEPOSIT"} />
          <Stack justifyContent="space-between">
            {/* <TransactionIcon category={transaction.category} /> */}
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {transaction.jngbrNm}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {formatDateTimeFromString(
                `${transaction.trDt.slice(2)}${transaction.trTm}`
              )}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={5}>
        <Stack
          justifyContent="space-between"
          alignItems="end"
          sx={{ height: "100%" }}
        >
          <Typography
            variant="body1"
            color={isDeposit ? "success.main" : "error.main"}
            sx={{ fontWeight: "bold" }}
          >
            {isDeposit ? "+" : "-"}
            {formatPrice(transaction.trAmt)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {formatDateTime(transaction.trDt, locale)} */}
            잔액: {formatPrice(transaction.trAfRmd)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Transaction;
