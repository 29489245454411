import { twMerge } from "tailwind-merge";

interface TWCardLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  additionalInfo?: React.ReactNode;
  className?: string;
  // sx?: React.CSSProperties | SxProps;
  onClick?: (e?: React.MouseEvent) => void;
}
export default function TWCardLayout({
  children,
  header,
  additionalInfo,
  className,
  onClick,
}: TWCardLayoutProps) {
  return (
    <section
      className={twMerge(
        "space-y-2 overflow-hidden rounded-2xl border border-gray-200 bg-white p-4 shadow-md",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      {header && (
        <>
          <div className="flex flex-row items-center">{header}</div>
          <div className="border-t border-gray-200"></div>
        </>
      )}
      <div className="w-full">{children}</div>
      {additionalInfo && (
        <>
          <div className="border-t border-gray-200"></div>
          <div className="flex flex-row items-center">{additionalInfo}</div>
        </>
      )}
    </section>
  );
}
