import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";

interface LachaPointSectionProps {
  expectedPoint?: number;
}

const LachaPointSection = ({ expectedPoint = 0 }: LachaPointSectionProps) => {
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }} gutterBottom>
        {isKorean ? (ProviderUtils.isHanacard ? "적립 예정 금액" : "기차표 예매 시 라차 적립금을 제공하며 다음 예매 시 사용 가능해요") : "Expected Lacha point"}
        {/* <FormattedMessage id="booking.noTrainOnlyBooking" /> */}
      </Typography>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <Stack sx={{ width: "95%" }}>
        <Typography variant="body1" sx={{ width: "95%" }}>
          {isKorean ? "라차 적립금" : "Lacha point"}
        </Typography>

        <FormControlLabel
          control={
            <Radio
              color="secondary"
              checked
              // checked={selectedCoupon?.goodsId === good.goodsId}
              // onChange={() => handleCouponChange(good)}
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", textAlign: "end" }}
            >{`${formatPrice(expectedPoint)}`}</Typography>
          }
        />
      </Stack>
    </CardLayout>
  );
};

export default LachaPointSection;
