import { Divider, Skeleton } from "@mui/material";

const ActivityPageSkeleton = () => {
  return (
    <div className="space-y-2">
      <div className="relative right-4 mb-4 w-screen max-w-lg">
        <Skeleton variant="rounded" height={300} />
      </div>
      <div className="space-y-2">
        <Skeleton variant="rounded" height={48} />
        <Skeleton variant="text" height={24} />
        <Skeleton variant="text" height={24} className="w-2/3" />
      </div>
      <Divider />
      <div>
        <Skeleton variant="text" height={48} />
        <Skeleton variant="text" height={24} className="w-1/3" />
        <Skeleton variant="text" height={24} className="w-1/3" />
        <Skeleton variant="text" height={24} className="w-1/3" />
        <Skeleton variant="text" height={24} className="w-1/3" />
      </div>
    </div>
  );
};

export default ActivityPageSkeleton;
