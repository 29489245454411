import {
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useOutletContext } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CardContext } from "../CardPageLayout";
import CardService from "services/cardService";
import { fetchCards, updateCard } from "app/cardsSlice";
import LoadingSpinner from "components/LoadingSpinner";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormattedMessage, useIntl } from "react-intl";
import LogoutButton from "components/button/LogoutButton";

const CardSettings = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { email } = useAppSelector((state) => state.user);

  const { currentCard, resetCarousel } = useOutletContext<CardContext>();
  const {
    iapCdno: cardNumber,
    iapCrdStcd: status,
    rpstCrdYn,
  } = currentCard || {};

  const [isMainCard, setIsMainCard] = useState(rpstCrdYn === "Y");
  const [isActive, setIsActive] = useState(parseInt(status) === 1);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteCardExpanded, setDeleteCardExpanded] = useState(false);
  const [changePasswordExpanded, setChangePasswordExpanded] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setIsMainCard(rpstCrdYn === "Y");
    setIsActive(parseInt(status) === 1);
  }, [rpstCrdYn, status]);

  useEffect(() => {
    setDeleteCardExpanded(false);
    setChangePasswordExpanded(false);
  }, [cardNumber]);

  const navigateToRegisterCard = () => {
    navigate("/card/register");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (/[0-9]/.test(value)) {
      setNewPassword(value);
    }
  };

  const setAsMainCard = async () => {
    setIsLoading(true);
    try {
      const response = await CardService.setToMainCard(cardNumber);
      if (response === "success") {
        const { data } = await CardService.getCardList();
        if (data) {
          dispatch(fetchCards(data.list));
        }
      }
      // reset carousel to first item
      resetCarousel();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleIsActive = async () => {
    const newStatus = isActive ? "02" : "01";
    try {
      setIsActive(!isActive);
      await CardService.updateCard(cardNumber, newStatus);
      dispatch(updateCard({ cardNumber, status: newStatus }));
    } catch (error) {
      setIsActive(isActive);
      console.error(error);
    }
  };

  const deleteCard = async () => {
    // try {
    //   const response = await CardService.updateCard(cardNumber, "03");
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const savePassword = async () => {
    setIsLoading(true);

    try {
      await CardService.changePassword(email, newPassword);
      alert("비밀번호 변경 성공");
      setChangePasswordExpanded(false);
      setNewPassword("");
    } catch (error) {
      alert(intl.formatMessage({ id: "forgot.newPasswordFaild" }));
    } finally {
      setIsLoading(false);
    }
  };

  const getAdditionalInfo = () => {
    return (
      <>
        <div className="flex w-full items-center justify-between pr-4">
          <p className="font-bold">
            <FormattedMessage id="card.addNewCard" />
          </p>

          <ChevronRightIcon
            fontSize="medium"
            onClick={navigateToRegisterCard}
            sx={{ fontWeight: "bold", cursor: "pointer" }}
            className="text-text-secondary"
          />
        </div>

        <Accordion
          expanded={changePasswordExpanded}
          disableGutters
          elevation={0}
          sx={{
            bgcolor: "transparent",
            width: "100%",
            "&:before": { height: "0px" },
          }}
          onChange={() => setChangePasswordExpanded(!changePasswordExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ justifySelf: "end" }} />}
            aria-controls="user.delete"
            id="user.delete"
            sx={{ pl: 0 }}
          >
            <p className="font-bold">
              <FormattedMessage id="card.changePassword" />
            </p>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <div className="flex gap-2">
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "forgot.newPassword" })}
                InputProps={{
                  inputProps: {
                    maxLength: 6,
                    sx: {
                      py: 0,
                      height: 40,
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <CustomButton
                variant="outlined"
                size="medium"
                fullWidth={false}
                onClick={savePassword}
                disabled={newPassword.length !== 6}
                id="user.info.save"
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <LogoutButton size="small" />
      </>
    );
  };

  return (
    <CardLayout
      additionalInfo={getAdditionalInfo()}
      sx={{ border: "none", boxShadow: "none", p: 1 }}
    >
      <div className="w-full">
        <div className="flex w-full items-center justify-between">
          <p className="font-bold">
            <FormattedMessage id="card.setAsDefault" />
          </p>

          <Switch checked={isMainCard} onChange={setAsMainCard} />
        </div>

        <div className="flex w-full items-center justify-between">
          <p className="font-bold">
            <FormattedMessage id="card.activate" />
          </p>
          <Switch checked={isActive} onChange={toggleIsActive} />
        </div>

        {/* <Accordion
          expanded={deleteCardExpanded}
          disableGutters
          elevation={0}
          sx={{
            bgcolor: "transparent",
            "&:before": { height: "0px" },
          }}
          onChange={() => setDeleteCardExpanded(!deleteCardExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ justifySelf: "end" }} />}
            aria-controls="user.delete"
            id="user.delete"
            sx={{ pl: 0 }}
          >
            <p className="font-bold">
              <FormattedMessage id="card.delete" />
            </p>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <div className="flex justify-end">
              <CustomButton
                variant="outlined"
                size="medium"
                fullWidth={false}
                onClick={deleteCard}
                id="card.delete"
                color="error"
              />
            </div>
          </AccordionDetails>
        </Accordion> */}
      </div>
      {isLoading && <LoadingSpinner overlap />}
    </CardLayout>
  );
};

export default CardSettings;
