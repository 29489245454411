import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Stack, Divider, useTheme, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import TrainService from "services/trainService";
import {
  BookerInfo,
  Coupon,
  ReservationInput,
  createReservationData,
} from "utils/reservationUtils";
import PassengerInfoSection, {
  PassengerDetailField,
} from "./sections/PassengerInfoSection";
import CouponSection from "./sections/CouponSection";
import TravelInfoSection from "./sections/TravelInfoSection";
import PriceSummarySection from "./sections/PriceSummarySection";
import BookerInfoSection from "./sections/BookerInfoSection";
import LoadingSpinner from "components/LoadingSpinner";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import useValidate, { isFormValid } from "hooks/useValidate";
import {
  PassengerDetail,
  TrainParams,
  updateSearchParams,
} from "app/reservationSlice";
import { updatePayment } from "app/paymentSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ScheduleType } from "types/scheduleType";
import { PriceType, calculateFilteredTotalPrice } from "./utils/priceUtils";
import { isAxiosError } from "axios";
import UserService from "services/userService";
import { fetchUser } from "app/userSlice";
import useModalSheet from "hooks/overlay/useModalSheet";
import { getTotalPassengersNumber } from "utils/urlSearchParamsUtils";
import LachaPointSection from "./sections/LachaPointSection";
import { ProviderUtils } from "utils/providerUtils";
import BookingGuidelines from "pages/tickets/components/BookingGuidelines";
import { makeAllOptionsTrue } from "utils/consentUtils";
import useLanguages from "hooks/useLanguages";
import AgreementsSection from "./sections/AgreementsSection";
import { useMyInfoQuery } from "app/apiSlice";
import PaymentService from "services/paymentService";

const initialAgreements = {
  term1: false,
  term2: false,
  term3: false,
  term4: false,
};

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

/**
 * Checks if a given forms are filled.
 * If the form is an array of passenger details, check if all passengers have a name and birth date.
 * If the form is a BookerInfo object, check if the booker has a name, phone number, email, and birth date
 *
 * @param forms - An array of PassengerDetail objects or a BookerInfo object.
 * @returns A boolean indicating if all forms are filled.
 */
const isFormFilled = (forms: PassengerDetail[] | BookerInfo): boolean => {
  let isFormFilled = false;

  if (forms instanceof Array) {
    isFormFilled = forms.every(
      (passenger) => passenger.name && passenger.birthDate
    );
  } else {
    isFormFilled = Boolean(
      forms.name && forms.phone && forms.email && forms.birthDate
    );
  }

  return isFormFilled;
};

/**
 * Converts a ReservationInput object into a FormData object.
 *
 * @param reservationInput - A ReservationInput object that has reservation data.
 * @returns A FormData object containing the reservation data.
 */
const createReservationFormData = (reservationInput: ReservationInput) => {
  const reservationData = createReservationData(reservationInput);
  const formData = new FormData();
  Object.entries(reservationData).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

/**
 * Generates an array of passenger details with initial values.
 * The initial passenger detail objects have the following properties:
 * - name: empty string
 * - birthDate: empty string
 * - seat: a seat object from the selected seats array
 *
 * @param reservation - The reservation state object.
 * @returns An array of passenger detail objects with initial values.
 */
const getInitialPassengerDetails = (
  trainParams: TrainParams
): PassengerDetail[] => {
  const passengerCount = getTotalPassengersNumber();
  const seats = trainParams.selectedSeats;
  return Array.from({ length: passengerCount }, (_, index) => ({
    name: "",
    birthDate: "",
    seat: seats![index],
  }));
};

/**
 * Generates an object with initial values for passenger birthdate validation.
 * The keys are in the format of "birthDate{index}" and the values are all true.
 *
 * @param reservation - The reservation state object.
 * @returns An object with initial values for passenger birthdate validation.
 */
const getInitialPassengerValidState = () => {
  const passengerCount = getTotalPassengersNumber();
  const passengerValidState: PassengerValidState = {};

  for (let i = 0; i < passengerCount; i++) {
    passengerValidState[`birthDate${i}`] = true;
  }
  return passengerValidState;
};

const initialBookerValidState = {
  name: true,
  phone: true,
  email: true,
  birthDate: true,
};

export type PassengerValidState = Record<`birthDate${number}`, boolean>;

const PaymentBookingPage = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const { schedule } = useLocation().state as { schedule: ScheduleType };

  const [agreements, setAgreements] = useState(initialAgreements);

  // modal
  const [CartModal, setCartModalVisible] = useModalSheet({
    callbackOk: () => {
      navigate("/cart");
    },
  });

  // redux
  const dispatch = useAppDispatch();
  const { trainParams } = useAppSelector((state) => state.reservation);

  const { data: user } = useMyInfoQuery();
  const { name = "", phone = "", email = "", birthDate = "" } = user ?? {};

  // Validation modal
  const [ValidationModal, setModalVisible] = useModalSheet({
    callbackOk: () => window.scrollTo({ top: 280, behavior: "smooth" }),
  });
  const [validationModalContent, setValidationModalContent] = useState("");

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [hanpassAgencyCode, setHanpassAgencyCode] = useState("");

  const [relatedGoods, setRelatedGoods] = useState<Coupon[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null); // 선택한 카카오 쿠폰 상태 관리
  const originalPrice = useRef<PriceType>(
    calculateFilteredTotalPrice(trainParams)
  );

  const price = originalPrice.current; // 총액 상태

  // 계산
  const [bookerValidState, bookerValidate] = useValidate(
    initialBookerValidState
  );
  const [passengerValidState, passengerValidate] = useValidate(
    getInitialPassengerValidState()
  );

  // 예매자 정보 - userInfo없는 경우에만 서버로 전달하기
  const [bookerInfo, setBookerInfo] = useState<BookerInfo>({
    name,
    phone,
    email,
    birthDate,
  });

  // 첫 rendering 시에 user가 undefined 일 수 있으므로 useEffect로 초기화
  useEffect(() => {
    setBookerInfo({ name, phone, email, birthDate });
  }, [birthDate, email, name, phone]);

  // 승객 상세 정보
  const [passengerDetails, setPassengerDetails] = useState(() =>
    getInitialPassengerDetails(trainParams)
  );

  // data
  const isBookerInfoVisible = !(
    name?.trim() &&
    phone?.trim() &&
    email?.trim() &&
    birthDate?.trim()
  );
  const description = useRef("");
  const seats = trainParams.selectedSeats;
  const reservationInput: ReservationInput = {
    selectedCoupon,
    bookerInfo: { ...bookerInfo, agencyCode: hanpassAgencyCode },
    passengerDetails,
    schedule,
    trainParams,
  };

  // action
  const handleAllAgree = useCallback(() => {
    setAgreements(makeAllOptionsTrue(agreements));
  }, [agreements]);

  useEffect(() => {
    const fetchRelatedGoods = async () => {
      // 결합상품 조회 실패 시 이전 페이지로 이동
      if (trainParams.costDetails?.[0].couponAmount === 0) {
        alert("결합상품 조회 실패");
        navigate(-1);
        return;
      }

      try {
        const stationCode = schedule.arvRsStnCd;
        const data = await TrainService.getRelatedGoods(
          stationCode,
          trainParams.costDetails?.[0].couponAmount || 0
        );

        if (data && data.length > 0) {
          setSelectedCoupon(data[0]);
          // 복합상품이나 쿠폰 선택시 가격 변경하는 코드
          // 카카오 T의 경우는 가격 변화없이 original price에 포함되는 상품으로 가격 없데이트 하지 않아도 된다.
          // setPrice(calculateNewPrice(originalPrice.current, data[0]));
          description.current = data[0].description;
        }
        setRelatedGoods(data);
      } catch (error) {
        console.error("결합상품 조회 실패:", error);
        // 결합상품 조회 실패 시 이전 페이지로 이동
        navigate(-1);
      }
    };

    // 결합 상품 금액이 0이 아닌 경우에만 결합상품 조회
    if (trainParams.costDetails?.[0].couponAmount) {
      fetchRelatedGoods();
    }
  }, [
    navigate,
    schedule.arvRsStnCd,
    trainParams.costDetails,
    trainParams.couponAmount,
  ]);

  // 탑승자 정보 변경 핸들러
  const handleChangePassengerDetail =
    (index: number, field: PassengerDetailField) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      switch (field) {
        case "birthDate":
          passengerValidate(e);
          break;
        default:
          break;
      }

      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];

        // 특정 인덱스의 탑승자 정보를 복사하고, 필요한 필드를 업데이트합니다.
        updatedDetails[index] = {
          ...updatedDetails[index],
          [field]: value,
        };

        dispatch(updateSearchParams({ passengerDetails: updatedDetails }));
        // 업데이트된 탑승자 정보 배열을 반환합니다.
        return updatedDetails;
      });
    };

  // Coupon selection change handler
  const handleCouponChange = (coupon: Coupon) => {
    setSelectedCoupon(coupon);

    // 복합상품이나 쿠폰 선택시 가격 변경하는 코드
    // 카카오 T의 경우는 가격 변화없이 original price에 포함되는 상품으로 가격 없데이트 하지 않아도 된다.
    // setPrice(calculateNewPrice(originalPrice.current, coupon));
  };

  const validateBookerInfo = () => {
    const handleFail = (message: string) => {
      setModalVisible(true);
      setValidationModalContent(message);
    };

    if (!bookerInfo.name || !bookerInfo.name) {
      handleFail(intl.formatMessage({ id: "signup.enterName" }));
      return false;
    }

    if (!bookerInfo.phone) {
      handleFail(intl.formatMessage({ id: "signup.enterPhone" }));
      return false;
    }
    if (!bookerValidState.phone) {
      handleFail(intl.formatMessage({ id: "signup.phoneInvalid" }));
      return false;
    }

    if (!bookerInfo.email) {
      handleFail(intl.formatMessage({ id: "signup.enterEmail" }));
      return false;
    }
    if (!bookerValidState.email) {
      handleFail(intl.formatMessage({ id: "signup.emailInvalid" }));
      return false;
    }

    if (!bookerValidState.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.invalidBirthDate" }));
      return false;
    }
    if (!bookerInfo.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.birthDate" }));
      return false;
    }

    return true;
  };

  const handleReservation = async () => {
    try {
      const orderId = await makeReservation();

      if (orderId) {
        const paymentId = await PaymentService.createPaymentId([orderId]);
        navigate(`/payment/${paymentId}`);
        //   navigate(`/payment/confirmation/${orderId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddToCart = async () => {
    try {
      await makeReservation();
      setCartModalVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  const makeReservation = async () => {
    if (!checkAllAgree(agreements)) {
      alert(
        isKorean
          ? "이용약관을 확인 후 동의에 체크해주세요"
          : "Please consent to the forms before proceeding"
      );
      throw Error("이용약관 미동의 에러");
    }

    // 예매자 정보 validation
    if (isBookerInfoVisible && !validateBookerInfo()) {
      return;
    }

    // 탑승자 정보 validation
    if (!isFormFilled(passengerDetails)) {
      setModalVisible(true);
      setValidationModalContent(
        intl.formatMessage({ id: "booking.enterPassengerDetails" })
      );
      return;
    }
    if (!isFormValid(passengerValidState)) {
      setModalVisible(true);
      setValidationModalContent(
        intl.formatMessage({ id: "booking.enterValidPassengerInfo" })
      );
      return;
    }

    const reservationFormData = createReservationFormData(reservationInput);

    setIsLoading(true);
    try {
      const { data } = await TrainService.reserveTicket(reservationFormData);

      // 예매 결과를 paymentParams에 저장
      dispatch(
        updatePayment({
          couponList: data.goodsList,
          amount: data.totalPrice, // 예매 총 금액
          finalAmount: data.purchasePrice, // 결제 금액
          transactionId: data.apiReserveNumber, // 거래 ID
          isPaid: true, // 결제 여부를 true로 설정
        })
      );

      dispatch(deleteMyOrders());

      // 처음 예매하는 경우 유저 정보 redux에 추가
      if (isBookerInfoVisible) {
        const userInfoResponse = await UserService.getMyInfo();
        dispatch(fetchUser(userInfoResponse));
      }

      return data.orderId;
    } catch (error) {
      console.error("Reservation failed:", error);
      if (isAxiosError(error)) {
        if (error.response?.data.errorMessage === "INVALID_BIRTHDAY") {
          alert("탑승자에 맞는 유효한 생년월일을 입력하세요. ");
          return;
        }
        alert(error.response?.data.errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "booking.title" })}>
      <Container
        maxWidth={false}
        sx={{
          mt: 2,
          p: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TravelInfoSection schedule={schedule} seats={seats} />

        <Divider />

        {isBookerInfoVisible && (
          <BookerInfoSection
            bookerInfo={bookerInfo}
            setBookerInfo={setBookerInfo}
            validState={bookerValidState}
            validate={bookerValidate}
          />
        )}

        {/* TODO: Props를 더 깔끔하게 내려주는 방법 없을까? 너무 많음 지금 - Context 사용? */}
        <PassengerInfoSection
          agencyCode={hanpassAgencyCode}
          setAgencyCode={setHanpassAgencyCode}
          bookerDetails={bookerInfo}
          passengerDetails={passengerDetails}
          setPassengerDetails={setPassengerDetails}
          onChangePassengerDetail={handleChangePassengerDetail}
          validState={passengerValidState}
        />

        <Divider />

        {/* 라차 적립금 */}
        <LachaPointSection expectedPoint={price.pointAmount} />

        {/* 카카오 쿠폰 표시 섹션 - 롯데카드는 보여주지 않음*/}
        {!ProviderUtils.isLottecard && selectedCoupon && (
          <CouponSection
            selectedCoupon={selectedCoupon}
            handleCouponChange={handleCouponChange}
            relatedGoods={relatedGoods}
            description={description.current}
          />
        )}

        {/* 총액 표시 섹션 */}
        <PriceSummarySection
          totalPrice={price.originalPrice}
          discount={price.discount}
          commission={price.commission}
          finalPrice={price.paymentPrice}
        />

        <BookingGuidelines />
        <AgreementsSection
          agreements={agreements}
          setAgreements={setAgreements}
          handleAllAgree={handleAllAgree}
        />

        {/* 예매 버튼 */}
        <Stack direction="row" gap={1}>
          <CustomButton
            // id="booking.cancelText"
            variant="outlined"
            // color="error"
            onClick={handleAddToCart}
            size="medium"
            style={{
              backgroundColor: theme.palette.white.main,
            }}
            disabled={isLoading}
          >
            {isKorean ? "장바구니 담기" : "Add to cart"}
          </CustomButton>
          {/* <CustomButton
            id="booking.cancelText"
            variant="outlined"
            color="error"
            onClick={() => navigate(-1)}
            size="medium"
            style={{
              backgroundColor: theme.palette.white.main,
            }}
            disabled={isLoading}
          /> */}
          <CustomButton
            id="booking.confirmText"
            onClick={handleReservation}
            size="medium"
            disabled={isLoading}
          />
        </Stack>
      </Container>

      {isLoading && <LoadingSpinner overlap />}
      <ValidationModal modal>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ fontWeight: "normal" }}
        >
          {validationModalContent}
        </Typography>
      </ValidationModal>

      {/* 장바구니 담기 모달 */}
      <CartModal modal>
        <div>
          <p className="text-lg font-bold">
            {isKorean
              ? "상품을 장바구니에 담았어요."
              : "Products have been added to your cart."}
          </p>
          <p className="text-lg font-bold">
            {isKorean
              ? "장바구니에 담긴 상품은 20분 후에 자동으로 삭제됩니다."
              : "Products will be automatically removed from cart in 20 minutes."}
          </p>
        </div>
      </CartModal>
    </Layout>
  );
};

export default PaymentBookingPage;
