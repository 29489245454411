import CustomButton from "components/button/CustomButton";
import TrainIcon from "@mui/icons-material/Train";
import { HEADER_HEIGHT } from "const/layout";

const DefaultBgImage = () => {
  return (
    <CustomButton
      className={`relative h-48 cursor-default overflow-hidden !rounded-none`}
      style={{
        top: `${HEADER_HEIGHT}px`,
      }}
      color="secondary"
    >
      <div
        className="grid max-w-md grid-cols-12"
        // justifyContent="space-between"
      >
        <div className="col-span-8 mx-4 content-center">
          <p className="text-start font-normal">Let's</p>
          <p className="leading-slug mb-4 text-start text-2xl">
            Book Your Next Trip
          </p>
        </div>
        <div
          className="col-start-10"
          // xs={3}
        >
          <TrainIcon
            fontSize="large"
            className="relative -top-3 right-20 !text-[220px]"
          />
        </div>
      </div>
    </CustomButton>
  );
};

export default DefaultBgImage;
