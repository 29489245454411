import { forwardRef, useState } from "react";
import ActivityDetailLayout from "../components/ActivityDetailLayout";
import parse from "html-react-parser";
import CustomButton from "components/button/CustomButton";
import clsx from "clsx";

interface ProductInfoSectionProps {
  title: string;
  content: string;
}

const ProductInfoSection = forwardRef<HTMLDivElement, ProductInfoSectionProps>(
  ({ title, content }, ref) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <ActivityDetailLayout ref={ref} title={title}>
        <section
          className={clsx(
            "max-w-lg",
            expanded && "h-auto",
            !expanded && "h-96 overflow-hidden"
            // !expanded && "h-screen overflow-hidden"
          )}
        >
          <section>{parse(content)}</section>
        </section>
        {!expanded && (
          <div className="absolute bottom-0 left-0 flex w-full max-w-lg items-end justify-center">
            <div
              className="h-96 w-full bg-gradient-to-b from-transparent to-white blur-sm"
              // style={{
              //   background: "linear-gradient(transparent, #FFF)",
              //   filter: "blur(4px)",
              // }}
            ></div>
            <div className="absolute bottom-2 w-60 bg-white">
              <CustomButton
                variant="outlined"
                onClick={() => setExpanded(true)}
              >
                상품 정보 더보기
              </CustomButton>
            </div>
          </div>
        )}
      </ActivityDetailLayout>
    );
  }
);

export default ProductInfoSection;
