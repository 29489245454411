import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormattedMessage } from "react-intl";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import MyCartIcon from "components/MyCartIcon";
import { useMyInfoQuery } from "app/apiSlice";

const PersonalInfoSettingSection = () => {
  const navigate = useNavigate();
  // const matches = useMediaQuery("(min-width:321px)");
  // const { name } = useAppSelector((state) => state.user);

  const { data: user } = useMyInfoQuery();

  const handleEditClick = () => {
    navigate("/user/personal-info");
  };

  return (
    <>
      <section
        className="relative flex h-14 items-center justify-between overflow-hidden rounded-b-xl bg-white px-5"
        // sx={{
        // width: `calc(100% + ${16 * (matches ? 2 : 1)}px)`,
        // right: 8 * (matches ? 2 : 1),
        // }}
      >
        <h3 className="font-bold">
          <FormattedMessage
            id="user.welcome"
            values={{ name: user?.name ?? "..." }}
          />
        </h3>
        <div className="flex items-center justify-center gap-2">
          <LanguageSelectIcon />
          <MyCartIcon />
          <SettingsIcon onClick={handleEditClick} />
        </div>
      </section>
    </>
  );
};

export default PersonalInfoSettingSection;
