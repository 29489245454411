import { Divider } from "@mui/material";
import Transaction from "./Transaction";
import { TransactionInterface } from "services/cardService";

interface TransactionListProps {
  transactions?: TransactionInterface[];
}

const TransactionList = ({ transactions }: TransactionListProps) => {
  return transactions?.length ? (
    <div className="space-y-2">
      {transactions?.map((transaction) => (
        <div className="space-y-2" key={transaction.trDt + transaction.trTm}>
          <Divider />
          <Transaction transaction={transaction} />
        </div>
      ))}
    </div>
  ) : null;
};

export default TransactionList;
