import { Skeleton } from "@mui/material";
import TWCardLayout from "pages/tailwind/TWCardLayout";

interface OrderSkeletonProps {
  number?: number;
}

const OrderSkeleton = ({ number = 1 }: OrderSkeletonProps) => {
  const skeletonHeader = () => {
    return (
      <div className="ml-2 flex w-full items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Skeleton variant="rounded" width={72} height={32} />
          <Skeleton variant="rounded" width={120} />
        </div>
        <Skeleton variant="rounded" width={24} />
      </div>
    );
  };

  const skeletonAdditionalInfo = () => {
    return (
      <div className="flex w-full justify-end">
        <Skeleton variant="rounded" width={96} height={24} />
      </div>
    );
  };

  return Array.from({ length: number }).map((_, key) => (
    <TWCardLayout
      key={key}
      className="max-h-[184px] w-full p-3"
      header={skeletonHeader()}
      additionalInfo={skeletonAdditionalInfo()}
    >
      <section className="flex w-full flex-col items-center gap-3">
        <Skeleton variant="rounded" height={56} width="100%" />
        {/* <Skeleton variant="rounded" height={28} width="40%" /> */}
      </section>
    </TWCardLayout>
  ));
};

export default OrderSkeleton;
