import axios, { AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";
import { CardInterface } from "app/cardsSlice";
import { NumericString } from "types/numericString";
import { getCurrentDateString, getDateStringNMonthAgo } from "utils/dateUtils";
import { FormType } from "hooks/useInputs";
import { OptionType } from "pages/card/Sections/DepositWithdraw";

interface CardList {
  cnt: number;
  list: CardInterface[];
}

interface BalanceTransferResponse {
  amount: number;
  response: { code: string; description: string };
}

// interface KTCResponse<D> {
//   data: D | null;
//   result_code: NumericString;
//   result_msg:
//     | string // "success"
//     | {
// en: string;
// es: string;
// ja: string;
// ko: string;
// "zh-hans": string;
// "zh-hant": string;
//       }; // error messages
// }

type KTCResponse<D> =
  | {
      data: D;
      result_code: "200";
      result_msg: string;
    }
  | {
      data: null;
      result_code: "400";
      result_msg: {
        en: string;
        es: string;
        ja: string;
        ko: string;
        "zh-hans": string;
        "zh-hant": string;
      };
    };

export interface TransactionInterface {
  cnctrYn: "Y" | "N";
  jngbrNm: string;
  rndDscd: NumericString; // "03"
  trAfRmd: number; // 거래 후 잔액
  trAmt: number; // 거래 금액
  trBfRmd: number; // 거래 전 잔액
  trDt: NumericString; // 거래일 - 20241212
  trSmrCts: string;
  trTm: NumericString; // 거래 시각 - 143126
}

interface TransactionList {
  cnt: number;
  list: TransactionInterface[];
}

export interface VAccountInfo {
  Amt: string;
  VbankBankName: string;
  VbankExpDate: string;
  VbankExpTime: string;
  VbankNum: string;
  AuthDate: string;
}

const cardApi = axios.create({
  baseURL: apiBaseUrl + "/ktc",
});

cardApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

cardApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

class CardService {
  // 카드 회원인지 확인
  static async checkSignUp(): Promise<boolean> {
    try {
      const response = await cardApi.get("/token");
      return response.data.result_code === "200";
    } catch (error) {
      throw error;
    }
  }

  // 카드 회원가입
  static async signUp(signUpDate: FormType): Promise<boolean> {
    const data = {
      bdt: signUpDate.cardBirthDate,
      name: signUpDate.name,
      email: signUpDate.email,
      pw: signUpDate.cardPassword,
    };

    try {
      const response = await cardApi.post("/signup", data);
      return response.data.result_code === "200";
    } catch (error) {
      throw error;
    }
  }

  // 카드 비밀번호 변경
  static async changePassword(email: string, pw: string): Promise<boolean> {
    const data = {
      email,
      pw,
    };

    try {
      const response = await cardApi.post("/changePw", data);
      return response.data.result_code === "200";
    } catch (error) {
      throw error;
    }
  }

  // 카드 등록
  static async addNewCard(
    cardNo: NumericString,
    expire: string, //YYMM
    cvc: NumericString
  ): Promise<KTCResponse<boolean>> {
    try {
      const response = await cardApi.post("/newCard", {
        cardNo,
        expire,
        cvc,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // 카드 리스트 조회
  static async getCardList(): Promise<KTCResponse<CardList>> {
    try {
      const response = await cardApi.get("/cards");
      return response.data;
    } catch (error) {
      console.error("Error retrieving card list:", error);
      throw error;
    }
  }

  // 거래 내역 조회
  static async getTransactionList(
    cardNo: NumericString,
    startDate = getDateStringNMonthAgo(1), // YYYYMMDD
    endDate = getCurrentDateString() // YYYYMMDD
  ): Promise<KTCResponse<TransactionList>> {
    try {
      const response = await cardApi.get(`/cardTransaction`, {
        params: {
          cardNo,
          startDate,
          endDate,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // 카드 잔액 조회
  static async getCardBalance(
    cardNo: NumericString
  ): Promise<KTCResponse<{ acnRmd: NumericString }>> {
    try {
      const response = await cardApi.get(`/cardBalance`, {
        params: {
          cardNo,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // 대표 카드 설정
  static async setToMainCard(
    cardNo: NumericString
  ): Promise<"success" | "Invalid Request"> {
    try {
      const response = await cardApi.post(`/repCard`, {
        cardNo,
      });
      return response.data.result_msg;
    } catch (error) {
      throw error;
    }
  }

  // 카드 상태 변경
  static async updateCard(
    cardNo: NumericString,
    status: 1 | 2 | 3 | NumericString
  ): Promise<NumericString> {
    try {
      const response = await cardApi.post(`/updateCard`, {
        cardNo,
        status: Number(status),
      });

      return response.data.result_code; // "200" | "400"
    } catch (error) {
      throw error;
    }
  }

  // 카드 잔액 이동
  static async transferBalance(
    fromCardNo: NumericString,
    toCardNo: NumericString,
    amount: number
  ): Promise<KTCResponse<BalanceTransferResponse>> {
    try {
      const response = await cardApi.post(`/transfer`, {
        drwIapCdno: fromCardNo,
        romIapCdno: toCardNo,
        amount,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // 충전
  // 카드 충전 URL 조회
  // 카드 충전 URL 조회
  static async getCardChargeUrl(cardNo: NumericString, pgType: "101" | "104") {
    try {
      const url = await cardApi.get(`/cardChargePgUrl`, {
        params: {
          cardNo,
          pgType, // "101" - 국내카드 | "104" - 해외카드
        },
      });
      return url;
    } catch (error) {
      throw error;
    }
  }

  // 가상계좌 정보 조회
  static async depositVirtualAccount(cardNo: NumericString, amount: number) {
    try {
      const response: AxiosResponse<{ data: VAccountInfo }> = await cardApi.get(
        `/cardChargeUrl`,
        {
          params: {
            cardNo,
            amount,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  // 편의점 바코드
  static async cvsBarcode(
    cardNo: NumericString,
    reqType: OptionType, // "DEPOSIT" | "WITHDRAW"
    amount: number,
    balance: number
  ): Promise<{ barcodeSpec: string }> {
    try {
      const response = await cardApi.post(`/cvsBarcode`, {
        cardNo,
        reqType: reqType === "DEPOSIT" ? "CHARGE" : "REFUND",
        amount,
        balance,
      });

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  // static async deleteCard(
  //   data: FormData
  // ): Promise<AxiosResponse<CardInterface>> {
  //   try {
  //     const response: AxiosResponse<CardInterface> = await cardApi.post(
  //       "/delete",
  //       data
  //     );
  //     return response;
  //   } catch (error) {
  //     console.error("Error deleting card:", error);
  //     throw error;
  //   }
  // }

  // static async reorderCard(
  //   data: FormData
  // ): Promise<AxiosResponse<CardInterface>> {
  //   try {
  //     const response: AxiosResponse<CardInterface> = await cardApi.post(
  //       "/reorder",
  //       data
  //     );
  //     return response;
  //   } catch (error) {
  //     console.error("Error reordering card:", error);
  //     throw error;
  //   }
  // }
}

export default CardService;
