import CustomButton from "components/button/CustomButton";
import { FormattedMessage } from "react-intl";
import { OrderType } from "types/orderType";

interface ShowTrainTicketButtonProps {
  status: OrderType["orderStatus"];
  onClick: () => void;
}

const ShowTrainTicketButton = ({
  status,
  onClick,
}: ShowTrainTicketButtonProps) => {
  return (
    status === "COMPLETE" && (
      <CustomButton size="medium" onClick={onClick}>
        <FormattedMessage id="orders.showTicket" />
      </CustomButton>
    )
  );
};

export default ShowTrainTicketButton;
