import { Chip, Divider, Pagination, Skeleton, TextField } from "@mui/material";
import ActivityDetailLayout from "../components/ActivityDetailLayout";
import CustomButton from "components/button/CustomButton";
import { forwardRef, useState } from "react";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import {
  ActivityInquiry,
  ActivityReview,
  useActivityReviewsQuery,
  useGetActivityQuery,
  useMyInfoQuery,
} from "app/apiSlice";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import StarRates from "components/StarRates";
import { maskUserName } from "utils/maskUtils";
import { formatDateTimeFromString } from "utils/dateUtils";

const REVIEW_PER_PAGE = 2;

interface ProductReviewSectionProps {
  title: string;
}

const ProductReviewSection = forwardRef<
  HTMLDivElement,
  ProductReviewSectionProps
>(({ title }, ref) => {
  const { activityId } = useParams();
  const [reviewText, setReviewText] = useState("");
  const [page, setPage] = useState(1);

  const { data: activity } = useGetActivityQuery(activityId!);

  const { data: reviews, isFetching } = useActivityReviewsQuery({
    goodsId: Number(activityId!),
    page: page - 1,
    size: REVIEW_PER_PAGE,
  });

  const visibleReviews = reviews?.content.filter((review) => review.isVisible);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;

    if (value.length < 500) {
      setReviewText(e.target.value);
    }
  };

  return (
    <ActivityDetailLayout ref={ref} title={title}>
      <section className="space-y-4">
        <TWCardLayout className="p-0">
          <div
            className={`flex flex-col items-center justify-center gap-2 bg-gray-100 p-4`}
          >
            <p className="text-4xl font-bold">
              {(activity?.rating ?? 0).toPrecision(2)}
            </p>
            <StarRates rate={activity?.rating ?? 0} />
          </div>
        </TWCardLayout>
        {/* {user && (
          <>
            <TextField
              multiline
              rows={4}
              placeholder="후기 작성하기"
              value={reviewText}
              onChange={handleChange}
              className="w-full"
            />
            <div className="flex items-center justify-end">
              <CustomButton size="small" className="h-8 !w-32">
                후기 작성하기
              </CustomButton>
            </div>
          </>
        )} */}

        {Boolean(visibleReviews?.length) && (
          <div className="space-y-4">
            <Divider />
            <div className="space-y-1">
              {isFetching
                ? Array.from({ length: REVIEW_PER_PAGE }).map((_, key) => (
                    <Skeleton key={key} variant="rounded" height={48} />
                  ))
                : visibleReviews?.map((review) => (
                    <Review key={review.reviewId} review={review} />
                  ))}
            </div>
            <div className="mt-4 flex w-full justify-center">
              <Pagination
                count={reviews?.totalPages || 1}
                shape="rounded"
                color="primary"
                page={page}
                onChange={(_e, value) => setPage(value)}
              />
            </div>
          </div>
        )}
      </section>
    </ActivityDetailLayout>
  );
});

export default ProductReviewSection;

interface ReviewProps {
  review: ActivityReview;
}
const Review = ({ review }: ReviewProps) => {
  const REVIEW_MAX_LENGTH = 140;

  const { data: user } = useMyInfoQuery();

  const hasToBeTruncated = review.content.length > REVIEW_MAX_LENGTH;
  const [isContentTruncated, setIsContentTruncated] =
    useState(hasToBeTruncated);

  return (
    <TWCardLayout>
      <div className="flex flex-col gap-2">
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex items-center gap-2">
            <p className="font-medium">{maskUserName(review.userName)} </p>
            <StarRates rate={review?.rating ?? 0} size="small" />
          </div>
          {review.userId === user?.userId && (
            <Chip
              label="수정하기"
              color="info"
              variant="outlined"
              size="small"
              className="justify-end !rounded-md px-0 py-3 font-bold"
              onClick={(e) => {
                e.stopPropagation();
                // startModification(inquiry);
              }}
            />
          )}
        </div>
        <p>
          {isContentTruncated
            ? `${review.content.slice(0, REVIEW_MAX_LENGTH)}...`
            : review.content}{" "}
          {isContentTruncated && (
            <span
              className="cursor-pointer text-text-secondary"
              onClick={() => setIsContentTruncated(false)}
            >
              더보기
            </span>
          )}
        </p>
        <p className="text-end text-xs text-text-secondary">
          {formatDateTimeFromString(review.updatedDate ?? review.createdDate)}
        </p>
      </div>
    </TWCardLayout>
  );
};
