import React from "react";
import { FormattedMessage } from "react-intl";
import useTranslateStation from "./useTranslateStation";
import Arrow from "components/Arrow";
import TrainLogo from "components/TrainLogo";
import useLanguages from "./useLanguages";
import clsx from "clsx";

const useGetOrderName = () => {
  const { isKorean } = useLanguages();
  const translateStation = useTranslateStation();

  // 주문 이름 파싱
  const getOrderName = (orderName: string): React.ReactElement => {
    const orderNameWords = orderName?.split(" ");
    const depart = translateStation(orderNameWords?.[1]);
    const arrive = translateStation(orderNameWords?.[3]);

    // let stlbTrnClsfCd;
    // switch (orderNameWords[0]) {
    //   case "KTX":
    //     stlbTrnClsfCd = "00";
    //     break;
    //   case "KTX-산천":
    //     stlbTrnClsfCd = "07";
    //     break;
    //   case "KTX-이음":
    //     stlbTrnClsfCd = "16";
    //     break;
    //   default:
    //     stlbTrnClsfCd = "00";
    // }

    return (
      <span
        className={clsx("flex font-bold", {
          "flex-row items-center justify-between gap-2": isKorean,
          "flex-col justify-center": !isKorean,
        })}
        // direction={isKorean ? "row" : "column"}
        // justifyContent={isKorean ? "space-between" : "center"}
        // alignItems={isKorean ? "center" : "space-between"}
        // gap={isKorean ? 1 : 0}
        // sx={{
        // fontWeight: "bold",
        // }}
      >
        <span className="flex items-center gap-2">
          <TrainLogo size="medium" />
          <span className="flex w-full items-center gap-[0.2] break-words text-center">
            <p>{depart} </p>
            <Arrow />
            <p>{arrive}</p>
          </span>
        </span>
        <p className="self-end text-base font-bold">
          <FormattedMessage id="orders.oneWay" />
        </p>
      </span>
    );
  };

  return getOrderName;
};

export default useGetOrderName;
