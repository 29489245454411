export const calculateCurrentDate = (): string => {
  const date = new Date();
  // return date.toISOString().split("T")[0];
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
};

export const calculateDateBeforeOneMonth = (): string => {
  const date = new Date();
  // date.setMonth(date.getMonth() - 1);
  date.setDate(date.getDate() - 31);
  return date.toISOString().split("T")[0];
};

export const calculateDateBeforeOneYear = (): string => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString().split("T")[0];
};

export const getCurrentDateString = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const getDateStringNMonthAgo = (n: number | string): string => {
  const monthNumber = Number(n) ? Number(n) : 1;
  const monthDiff = monthNumber > 12 || monthNumber < 1 ? 1 : monthNumber;

  const date = new Date();
  date.setMonth(date.getMonth() - monthDiff);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

/**
 * Takes a date string in the format "YYYYMMDDHHMMSS" and returns a new string
 * in the format "YYYY-MM-DD HH:MM:SS"
 *
 * @param {string} date - The date string to parse
 * @returns {string} The formatted date string
 */
export const formatDateTimeFromString = (date: string) => {
  const [year, month, day, hour, minute, second] = date.match(/\d{2}/g) || [];

  const dateString = `20${year}.${month}.${day} ${hour}:${minute}:${second}`;
  return dateString;
};
