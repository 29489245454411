import { Stack, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import useLanguages from "hooks/useLanguages";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderType, PaymentInfo, RefundInfo } from "types/orderType";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PaymentService from "services/paymentService";
import { PaymentMethod } from "const/paymentMethod";

interface PaymentRefundInfoSectionProps {
  order: OrderType;
  type: "payment" | "refund";
  boxShadow?: boolean;
}

const PaymentRefundInfoSection = ({
  order,
  type,
  boxShadow = true,
}: PaymentRefundInfoSectionProps) => {
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  const paymentInfo = order.paymentInfo;
  const refundInfo = order.refundInfo;

  const isPaidByCard =
    paymentInfo?.paymentMethod === PaymentMethod.CREDIT ||
    paymentInfo?.paymentMethod === PaymentMethod.CORPORATE;

  const navigateToReceiptPage = async () => {
    try {
      if (order.paymentInfo?.paymentId) {
        const response = await PaymentService.getReceiptUrl(
          order.paymentInfo?.paymentId
        );
        // window.location.href = response;
        window.open(response, "_blank");
      }
    } catch {
      alert("잠시 후 다시 시도해주세요.");
    }
  };

  const getHeader = () => {
    return (
      <div className="w-full space-y-1">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedMessage
              id={
                type === "payment" ? "orders.paymentTitle" : "orders.cancelInfo"
              }
            />
          </Typography>
          {/* 영수증 출력 */}
          {isPaidByCard && (
            <CustomButton
              onClick={navigateToReceiptPage}
              size="small"
              variant="text"
              fullWidth={false}
              // disabled={isLoading}
              style={{
                padding: 0,
                minHeight: "24px",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {isKorean ? "영수증 출력" : "Print receipt"}
              </Typography>
              <NavigateNextIcon fontSize="small" />
            </CustomButton>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          {/* 총 결제 금액 */}
          <Typography variant="body1">
            <FormattedMessage
              id={
                type === "payment"
                  ? "orders.totalPaymentAmount"
                  : "orders.cancelAmount"
              }
            />
            :
          </Typography>
          <Typography
            variant="body1"
            color={type === "payment" ? "primary" : "error"}
            sx={{ fontWeight: "bold" }}
          >
            {formatPrice(order.totalPrice)}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <CardLayout
      header={getHeader()}
      sx={{ width: "100%", boxShadow: boxShadow ? "" : "none" }}
    >
      <div className="w-full">
        <p className="font-medium">결제 수단</p>
      </div>

      {type === "payment" ? (
        <PaymentRefundDetail paymentRefundInfo={paymentInfo} />
      ) : (
        <PaymentRefundDetail paymentRefundInfo={refundInfo} />
      )}

      {/* 라차 적립금 */}
      {/* {Boolean(order.pointUsed) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {intl.formatMessage({
              id: type === "payment" ? "orders.usedPoint" : "point.refundPoint",
            })}
            :
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.pointUsed)}
          </Typography>
        </Stack>
      )} */}

      {/* 한패스 쿠폰 */}
      {Boolean(order.externalCouponDiscount) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {isKorean ? "한패스 쿠폰" : "Hanpass coupon"}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.externalCouponDiscount)}
          </Typography>
        </Stack>
      )}
    </CardLayout>
  );
};

export default PaymentRefundInfoSection;

interface PaymentRefundDetailProps {
  paymentRefundInfo: PaymentInfo | RefundInfo;
}

const PaymentRefundDetail = ({
  paymentRefundInfo,
}: PaymentRefundDetailProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  const getCategoryTitle = (
    paymentMethod?: PaymentInfo["paymentMethod"] | RefundInfo["paymentMethod"]
  ) => {
    switch (paymentMethod) {
      case "Credit":
      case "Overseas":
        return intl.formatMessage({ id: "payment.creditCard" });
      case "POINT":
        return isKorean ? "라차 포인트" : "Lacha Point";
      case "KeyIn":
        return isKorean ? "법인카드" : "Corporate Card";
      case "Hanpass":
        return isKorean ? "한패스월렛" : "Hanpass Wallet";
      case "GME":
        return isKorean ? "GME 페이" : "GME Pay";
      default:
        return intl.formatMessage({ id: "payment.creditCard" });
      // const _exhaustiveCheck: never = paymentMethod;
      // return _exhaustiveCheck;
    }
  };

  return (
    <div className="w-full">
      {Boolean(paymentRefundInfo?.paymentAmount) && (
        <div className="flex w-full justify-between">
          <Typography variant="body1">
            {getCategoryTitle(paymentRefundInfo?.paymentMethod)}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(paymentRefundInfo?.paymentAmount)}
          </Typography>
        </div>
      )}
      {Boolean(paymentRefundInfo?.mileageAmount) && (
        <div className="flex w-full justify-between">
          <Typography variant="body1">라차 포인트:</Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(paymentRefundInfo?.mileageAmount)}
          </Typography>
        </div>
      )}
    </div>
  );
};
