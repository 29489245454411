import { OrderType } from "types/orderType";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import useGetOrderName from "hooks/useGetOrderName";
import useLanguages from "hooks/useLanguages";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import clsx from "clsx";

interface OrderThumbnailContentProps {
  reservationDetails: OrderType;
  className?: string;
}

const OrderThumbnailContent = ({
  reservationDetails,
  className,
}: OrderThumbnailContentProps) => {
  const { isKorean } = useLanguages();

  const getOrderName = useGetOrderName();
  const formatDateTime = useFormatDateToLocaleString();
  const { isLanguage } = useLanguages();

  const productType = reservationDetails?.productType;

  return (
    <section
      className={clsx("w-full space-y-1 py-2", className)}
      // alignItems="start"
      // justifyContent="center"
      // gap={0.5}
      // sx={{ py: 1, width: "100%" }}
    >
      {/* 기차 아이콘과 주문 이름 */}
      <div className="block w-full text-lg font-bold">
        {productType === "TRAIN"
          ? getOrderName(reservationDetails?.orderName)
          : reservationDetails?.orderName}
      </div>
      <div
        className={clsx(
          "flex gap-1 text-start text-sm text-text-secondary",
          productType === "TRAIN" && "flex-col"
        )}
        // direction="row" alignItems="center" gap={0.8}
      >
        <p className="text-text-secondary">
          {isKorean ? "이용 날짜: " : "Usage Date: "}
        </p>
        <div className="flex items-center gap-2">
          <span className="text-center">
            {formatDateTime(reservationDetails?.startDate)}
          </span>
          {productType === "TRAIN" && (
            <>
              {isLanguage("ur") ? (
                <WestIcon fontSize="inherit" />
              ) : (
                <EastIcon fontSize="inherit" />
              )}

              <span className="text-center">
                {formatDateTime(reservationDetails?.endDate)}
              </span>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default OrderThumbnailContent;
