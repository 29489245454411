import { Collapse } from "@mui/material";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/button/CustomButton";
import LoadingSpinner from "components/LoadingSpinner";
import AuthService from "services/authService";
import TrainService, {
  CostParams,
  CostResponse,
  SeatMapParams,
} from "services/trainService";
import CostDetailsSection from "./CostDetails";
import TrainInfoSection from "./TrainInfos";
import { updateTrainParams } from "app/reservationSlice";
import { useAppDispatch } from "app/hooks";
import { ScheduleType } from "types/scheduleType";
import { useIntl } from "react-intl";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  getAdultsNumber,
  getChildrensNumber,
  getTotalPassengersNumber,
} from "utils/urlSearchParamsUtils";
import TWCardLayout from "pages/tailwind/TWCardLayout";

interface ScheduleItemProps {
  schedule: ScheduleType;
  expanded: boolean;
  onToggleExpand: () => void;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const getRequestData = (schedule: ScheduleType): SeatMapParams => {
  const requestData: SeatMapParams = {
    dptRsStnCd: schedule.dptRsStnCd, // 출발역 코드
    arvRsStnCd: schedule.arvRsStnCd, // 도착역 코드
    trnNo: schedule.trnNo, // 기차 번호
    gdNo: schedule.gdNo, // 상품 번호
    gdConsGpSqno: schedule.gdConsGpSqno, // 추가 정보
    gdConsItmId: schedule.gdConsItmId, // 추가 정보

    trnGpCd: schedule.trnGpCd, // 열차 그룹코드
    psrmClCd: "1", // 승객 클래스 코드
    runDt: schedule.runDt, // 운행 날짜
  };

  return requestData;
};

const getCostParams = (schedule: ScheduleType): CostParams => {
  const costParams: CostParams = {
    dptRsStnCd: schedule.dptRsStnCd, // 출발역 코드
    arvRsStnCd: schedule.arvRsStnCd, // 도착역 코드
    trnNo: schedule.trnNo, // 기차 번호
    gdNo: schedule.gdNo, // 상품 번호 (static value)
    gdConsGpSqno: schedule.gdConsGpSqno, // 상품 구성 그룹 순번
    gdConsItmId: schedule.gdConsItmId, // 상품 구성 품목 ID

    gdItmId: schedule.gdItmId, // 상품 품목 ID
    utlDt: schedule.dptDt, // 이용 날짜 (format: YYYYMMDD)
    medDvCd: "02", // 매체 구분 코드 (static value)

    adultCount: getAdultsNumber(),
    childCount: getChildrensNumber(),
  };
  return costParams;
};

const ScheduleItem = ({
  schedule,
  expanded,
  onToggleExpand,
  setModalVisible,
}: ScheduleItemProps) => {
  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const intl = useIntl();

  // state
  const [costDetails, setCostDetails] = useState<CostResponse[]>([]); // 비용 세부사항 상태
  const [isLoading, setIsLoading] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const detailsRef = useRef<HTMLDivElement>(null); // Collapse 섹션 참조

  // 기차 예약을 위한 기본 요청 데이터 설정
  const requestData = getRequestData(schedule);
  const costParams: CostParams = getCostParams(schedule);

  const isLoggedIn = useCallback(async () => {
    if (!(await AuthService.isLoggedIn())) {
      setModalVisible(true);
      return false;
    }
    return true;
  }, [setModalVisible]);

  // 비용 세부사항 가져오기
  const fetchCostDetails = useCallback(async () => {
    setIsLoading(true); // Start loading

    try {
      const response = await TrainService.getSearchCost(costParams);

      setCostDetails(response);
    } catch (error) {
      console.error("Failed to fetch cost details:", error);
    } finally {
      setIsLoading(false); // Stop loading in case of error
    }
  }, [costParams]);

  // 요금조회 버튼 클릭 핸들러
  const handleToggleDetails = useCallback(async () => {
    if (!expanded) {
      if (!costDetails.length) {
        fetchCostDetails();
      }
      // 세부사항 표시 후 스크롤 이동
      detailsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    onToggleExpand(); // Call the onToggleExpand function passed down from the parent
  }, [costDetails, expanded, fetchCostDetails, onToggleExpand]);

  const handleSeatSelection = useCallback(async () => {
    if (!(await isLoggedIn())) {
      return;
    }

    setIsNextPageLoading(true);

    try {
      const remainingSeats =
        await TrainService.searchRemainingSeats(requestData);
      const availableCars = remainingSeats.filter((car) => car.restSeatNum > 0);

      if (availableCars.length === 0) {
        alert("충분한 좌석이 없습니다.");
        window.location.reload();
      }

      const seatMaps = await Promise.all(
        availableCars.map(async (car) => {
          console.log(
            `Car Number: ${car.scarNo}, Remaining Seats: ${car.restSeatNum}`
          );
          const seatMapRequest = {
            ...requestData,
            scarNo: car.scarNo, // 차량 번호
            seatAttCd: "024", // 코레일 좌석 위탁 판매 속성 - 024로 고정
          } as const;

          return TrainService.getSeatMap(seatMapRequest);
        })
      );

      // 좌석 조회 시 해당 기차편 costDetail을 redux에 저장
      dispatch(
        updateTrainParams({
          trnNo: schedule.trnNo,
          costDetails,
        })
      );

      navigate(`/seat-selection/${schedule.trnNo}?${params.toString()}`, {
        state: { cars: seatMaps, restSeats: availableCars, schedule },
      });
    } catch (error) {
      console.error("Failed to fetch seat map:", error);
      navigate("/");
    } finally {
      setIsNextPageLoading(false);
    }
  }, [
    costDetails,
    dispatch,
    isLoggedIn,
    navigate,
    params,
    requestData,
    schedule,
  ]);

  const handleAutoSeatSelectionAndReservation = useCallback(async () => {
    if (!(await isLoggedIn())) {
      return;
    }

    setIsNextPageLoading(true);

    try {
      // 예약 가능한 좌석 조회
      const remainingSeats =
        await TrainService.searchRemainingSeats(requestData);
      // 예약 가능한 차량만 필터링
      const availableCars = remainingSeats.filter((car) => car.restSeatNum > 0);

      // 선택될 좌석들을 저장할 배열
      let selectedSeats = [];
      // 예약할 총 인원 수 계산
      let neededSeats = getTotalPassengersNumber();

      // 예약 가능한 차량을 순회하면서 좌석을 선택
      for (const car of availableCars) {
        // 필요한 좌석 수를 충족했으면 반복 중단
        if (neededSeats <= 0) break;

        // 차량별 좌석 맵 요청
        const seatMapRequest = {
          ...requestData,
          scarNo: car.scarNo, // 차량 번호
          seatAttCd: "024", // 코레일 좌석 위탁 판매 속성 - 024로 고정
        } as const;

        // 해당 차량의 좌석 맵 조회
        const seatMapResponse = await TrainService.getSeatMap(seatMapRequest);
        // seatMap이 배열인지 확인
        if (!Array.isArray(seatMapResponse.restSeatList)) {
          console.error(
            "seatMap is not an array:",
            seatMapResponse.restSeatList
          );
          alert("좌석 정보를 불러오는 데 실패했습니다.");
          return;
        }
        // 판매 가능한 좌석만 필터링
        const availableSeats = seatMapResponse.restSeatList.filter(
          (seat) => seat.salePsbFlg === "Y"
        );

        // 사용 가능한 좌석을 필요한 만큼 선택
        for (const seat of availableSeats) {
          if (neededSeats <= 0) {
            break;
          }

          selectedSeats.push({
            scarNo: car.scarNo,
            seatNo: seat.seatNo,
            seatSpec: seat.seatSpec,
          });

          neededSeats--;
        }
      }

      // 선택된 좌석이 예약할 인원 수보다 적은 경우 경고
      if (selectedSeats.length < neededSeats) {
        alert("충분한 좌석이 없습니다.");
        window.location.reload();
        return;
      }

      dispatch(
        updateTrainParams({
          selectedSeats,
          trnNo: schedule.trnNo,
          seatAppFlg: "N", // "자동 선택"
          costDetails, // 좌석 선택 시 해당 기차편 costDetail을 redux에 저장
        })
      );

      // 예약 상세 페이지로 이동
      // navigate(`/booking-detail/${schedule.trnNo}`, {
      navigate(`/payment/booking?${params.toString()}`, {
        state: {
          schedule,
          seats: selectedSeats,
        },
      });
    } catch (error) {
      // 오류 처리
      console.error("자동 좌석 선택 실패:", error);
      navigate("/");
    } finally {
      // 로딩 상태 종료
      setIsNextPageLoading(false);
    }
  }, [
    costDetails,
    dispatch,
    isLoggedIn,
    navigate,
    params,
    requestData,
    schedule,
  ]);

  const getAdditionalInfo = () => {
    if (!expanded) {
      return (
        schedule.gnrmRsvPsbFlg === "Y" && (
          <div className="flex w-full justify-end">
            <CustomButton
              onClick={handleToggleDetails}
              variant="text"
              size="small"
              fullWidth={false}
              disabled={isLoading}
              style={{
                padding: 0,
                minHeight: "24px",
              }}
            >
              {/* 요금 조회 */}
              {intl.formatMessage({
                id: "fareInquiry.inquiryPrice",
                defaultMessage: "Inquiry",
              })}
              <NavigateNextIcon fontSize="small" />
            </CustomButton>
          </div>
        )
      );
    }

    return isLoading ? (
      <LoadingSpinner className="h-[88px]" />
    ) : (
      <Collapse in={expanded} ref={detailsRef} sx={{ width: "100%" }}>
        {costDetails && (
          <div className="relative mt-2 flex justify-between gap-8 max-[512px]:flex-col max-[512px]:items-center">
            <CostDetailsSection costDetails={costDetails} />
            <div
              className="flex items-end gap-1"
              //  direction="row" alignItems="flex-end" gap={1}
            >
              <CustomButton
                id="fareInquiry.seatButtonText"
                onClick={handleSeatSelection}
                size="small"
                disabled={isNextPageLoading}
                variant="outlined"
                style={{ backgroundColor: "white" }}
              />
              <CustomButton
                id="fareInquiry.autoButtonText" // 바로예약
                onClick={handleAutoSeatSelectionAndReservation}
                size="small"
                disabled={isNextPageLoading}
              />
            </div>
          </div>
        )}
      </Collapse>
    );
  };

  return (
    <>
      <TWCardLayout additionalInfo={getAdditionalInfo()} className="w-full p-3">
        <TrainInfoSection schedule={schedule} />
      </TWCardLayout>
      {isNextPageLoading && <LoadingSpinner overlap />}
    </>
  );
};

export default ScheduleItem;
