import { Stack, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

interface PeriodFilterProps {
  period: string;
  handlePeriodChange: (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => void;
  withoutCustom?: boolean;
}

const PeriodFilter = ({
  period,
  handlePeriodChange,
  withoutCustom,
}: PeriodFilterProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:321px)");

  return (
    <Stack
      direction="row"
      sx={{
        height: "64px",
        position: "relative",
        overflowX: "hidden",
        width: `calc(100% + ${16 * (matches ? 2 : 1)}px)`,
        right: 8 * (matches ? 2 : 1),
        mb: 1,
        bgcolor: theme.palette.white.main,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      }}
      justifyContent="center"
    >
      <Tabs
        value={period}
        onChange={handlePeriodChange}
        aria-label="조회기간"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          sx={{
            textTransform: "none",
            height: "64px",
            fontWeight: "bold",
          }}
          label={intl.formatMessage({ id: "orders.months" }, { number: 1 })}
          value="1"
        />
        <Tab
          sx={{
            textTransform: "none",
            height: "64px",
            fontWeight: "bold",
          }}
          label={intl.formatMessage({ id: "orders.months" }, { number: 3 })}
          value="3"
        />
        <Tab
          sx={{
            textTransform: "none",
            height: "64px",
            fontWeight: "bold",
          }}
          label={intl.formatMessage({ id: "orders.months" }, { number: 6 })}
          value="6"
        />
        {!withoutCustom && (
          <Tab
            sx={{
              textTransform: "none",
              height: "64px",
              fontWeight: "bold",
            }}
            label={intl.formatMessage({ id: "orders.custom" })}
            value="custom"
          />
        )}
      </Tabs>
    </Stack>
  );
};

export default PeriodFilter;
