import { SocialLoginProvider } from "components/social_login/SocialLoginButton";
import { ProviderUtils } from "./providerUtils";
import { __LOCAL__ } from "const/properties";

/**
 * local 개발 환경과, build된 환경을 구분하여 url에 포함된 string array를 반환하는 함수
 */
export const getURIVariable = (loginProvider: SocialLoginProvider) => {
  const urlSubdomain: string | undefined = ProviderUtils.provider?.provider;
  const envProviderKey = `REACT_APP_${loginProvider}_REDIRECT_URI_PROVIDER`;
  const envDefaultKey = `REACT_APP_${loginProvider}_REDIRECT_URI_DEFAULT`;
  const clientIdKey = `REACT_APP_${loginProvider}_CLIENT_ID`;

  // localhost - npm start
  if (__LOCAL__) {
    return [
      process.env[clientIdKey],
      `http://${
        urlSubdomain !== "localhost"
          ? `${urlSubdomain}.${process.env[envProviderKey]}`
          : process.env[envDefaultKey]
      }`,
    ];
  }

  // build result - npm run build
  return [
    process.env[clientIdKey],
    `https://${
      urlSubdomain
        ? `${urlSubdomain}.${ProviderUtils.isCard ? process.env[envDefaultKey] : process.env[envProviderKey]}`
        : process.env[envDefaultKey]
    }`,
  ];
};
