import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthService from "services/authService";
import { ProviderUtils } from "utils/providerUtils";
import { closeWebview } from "utils/lottecardUtils";
import { __PROD__ } from "const/properties";
import useReduxUtils from "hooks/useReduxUtils";

const PrivateRoute = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  // 서버 오류 발생한 경우에도 token 유지하기 위해 accessToken을 삭제하면 안 됨
  // TokenService.removeAccessToken();
  const { resetStore } = useReduxUtils({ retainToken: true });

  useEffect(() => {
    const checkLoggedIn = async () => {
      const response = await AuthService.isLoggedIn();
      setIsLoggedIn(response);
      setIsChecking(false);
    };
    checkLoggedIn();
  });

  if (!isChecking && !isLoggedIn) {
    // 서버 오류 발생한 경우에도 token 유지하기 위해 accessToken을 삭제하면 안 됨
    // TokenService.removeAccessToken();
    resetStore();
    // resetStore();
    // 로그인하지 않은 경우, 로그인 페이지로 리다이렉트합니다.
    if (ProviderUtils.isHanpass) {
      window.location.href = "http://finish";
      return;
    } else {
      // 앱에서 자동으로 로그인 되는 경우에는 alert으로 알림 보여주고 창 닫기 (운영인 경우만)
      if (__PROD__ && !ProviderUtils.loginAvailable) {
        alert("유저 정보를 찾을 수 없습니다. 다시 접속해주세요.");
        if (ProviderUtils.isLottecard) {
          closeWebview();
          return;
        } else {
          window.close();
          return;
        }
      }

      // `state`를 사용하여 리다이렉트 후 원래 페이지로 돌아올 수 있도록 합니다.
      return (
        <Navigate to="/login" state={{ from: location.pathname }} replace />
      );
    }
  }

  return !isChecking && isLoggedIn && <Outlet />; // 로그인한 경우, 해당 컴포넌트를 렌더링합니다.
};

export default PrivateRoute;
