import { ProviderUtils } from "utils/providerUtils";
import { lazy, Suspense } from "react";
import { HEADER_HEIGHT } from "const/layout";
import DefaultBgImage from "./DefaultBgImage";

// const DefaultBgImage = lazy(() => import("./DefaultBgImage"));
const LotteBgImage = lazy(() => import("./LotteBgImage"));
const HanaBgImage = lazy(() => import("./HanaBgImage"));

const BackgroundImage = () => {
  const FallbackComponent = () => {
    return (
      <div
        className={`relative w-full t-[${HEADER_HEIGHT}px] h-[50vw] bg-gray-300`}
        style={{
          top: `${HEADER_HEIGHT}px`,
        }}
      ></div>
    );
  };

  if (ProviderUtils.isLottecard) {
    return (
      <Suspense fallback={<FallbackComponent />}>
        <LotteBgImage />
      </Suspense>
    );
  }

  if (ProviderUtils.isHanacard) {
    return (
      <Suspense fallback={<FallbackComponent />}>
        <HanaBgImage />
      </Suspense>
    );
  }

  return <DefaultBgImage />;
};

export default BackgroundImage;
