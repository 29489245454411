import { Stack, Chip } from "@mui/material";
import Layout from "components/layout/Layout";
import TravelItem from "components/travel/TravelItem";
import { useState } from "react";
import TravelFilterPage from "./TravelFilterPage";

export type TravelFilterOptions = "City" | "Dates" | "Category" | "tags";

const filterOptionList: TravelFilterOptions[] = [
  "City",
  "Dates",
  "Category",
  "tags",
  "City",
  "Dates",
  "Category",
  "tags",
];

const TravelList = () => {
  const [currentFilterPage, setCurrentFilterPage] =
    useState<TravelFilterOptions>();

  const openFilterPage = (label: TravelFilterOptions) => {
    setCurrentFilterPage(label);
  };

  return (
    <Layout text="Travel">
      {/* Filter */}
      <Stack gap={1} sx={{ my: 2 }}>
        <Stack
          direction="row"
          flexWrap={"wrap"}
          gap={1}
          sx={{ fontWeight: "bold" }}
        >
          {filterOptionList.map((label) => (
            <Chip
              key={label}
              label={label}
              onClick={() => openFilterPage(label)}
            />
          ))}
        </Stack>
        {currentFilterPage && (
          <TravelFilterPage currentFilterOption={currentFilterPage} />
        )}
      </Stack>

      {/* Travel list */}
      <Stack gap={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <TravelItem key={index} />
        ))}
      </Stack>
    </Layout>
  );
};

export default TravelList;
