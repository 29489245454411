import { Grid, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";

interface PriceSummarySectionProps {
  totalPrice: number;
  discount: number;
  commission: number;
  finalPrice: number;
}

const PriceSummarySection = ({
  totalPrice,
  discount,
  commission,
}: // finalPrice,
// selectedCoupon,
PriceSummarySectionProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const formatPrice = useFormatPrice();

  // Adjusting the total and final price if a coupon is applied
  // if (selectedCoupon) {
  //   totalPrice = totalPrice + selectedCoupon.saleAmount;
  //   finalPrice = finalPrice + selectedCoupon.saleAmount;
  // }

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {intl.formatMessage({ id: "payment.paymentAmount" })}
      </Typography>
    );
  };

  const getAdditionalInfo = () => {
    return (
      // 예상 할인액 / 예상 적립금
      discount > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item xs={8}>
            <Typography>
              {intl.formatMessage({ id: "payment.estimatedPoint" })}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Typography>{formatPrice(discount)}</Typography>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      {/* 복합상품 금액 */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={8}>
          <Typography>
            {intl.formatMessage({ id: "payment.combinedAmount" })}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end" }}>
          <Typography
          // sx={{
          //   textDecoration:
          //     ProviderUtils.discountAvailable && discount
          //       ? "line-through"
          //       : "none",
          // }}
          >
            {formatPrice(totalPrice)}
          </Typography>
        </Grid>
      </Grid>

      {/* 예상 할인액 */}
      {/*       {discount > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item xs={8}>
            <Typography>
              {intl.formatMessage({ id: "payment.estimatedDiscount" })}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Typography>
              {formatPrice(discount)}
            </Typography>
          </Grid>
        </Grid>
      )} */}

      {/* 수수료 */}
      {commission > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item xs={8}>
            <Typography>
              {intl.formatMessage({ id: "payment.commission" })}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Typography>{formatPrice(commission)}</Typography>
          </Grid>
        </Grid>
      )}

      {/* 결제 예정금액 */}
      <Grid container justifyContent="space-between" alignItems="end">
        <Grid item xs={7}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "payment.expectedPaymentAmount" })}
          </Typography>
        </Grid>
        <Grid item xs={5} sx={{ textAlign: "end" }}>
          <Typography variant="h6" color="error" sx={{ fontWeight: "bold" }}>
            {formatPrice(totalPrice)}
          </Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default PriceSummarySection;
