import useFormatPrice from "hooks/useFormatPrice";
import TWCardLayout from "pages/tailwind/TWCardLayout";

interface LachaPointSectionProps {
  pointEarned?: number;
}

const LachaPointSection = ({ pointEarned = 0 }: LachaPointSectionProps) => {
  const formatPrice = useFormatPrice();

  return (
    <TWCardLayout>
      <div className="flex justify-between">
        <p className="font-bold">적립 포인트: </p>
        <p className="font-bold">{formatPrice(pointEarned)}</p>
      </div>
    </TWCardLayout>
  );
};

export default LachaPointSection;
