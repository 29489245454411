import { Checkbox, Grid, Stack, Typography, useTheme } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";
import useFormatPrice from "hooks/useFormatPrice";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks";
import { OrderType } from "types/orderType";
import { useNavigate } from "react-router-dom";
import useFetchOrderInfo from "hooks/orderInfos/useFetchOrderInfo";
import OrderSkeleton from "components/skeleton/OrderSkeleton";
import PaymentService from "services/paymentService";
import OrderThumbnail from "components/reservation/OrderThumbnail";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

const ShoppingCartPage = () => {
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  const { recentOrders } = useAppSelector((state) => state.myOrders);
  const carts = recentOrders.filter((order) => order.orderStatus === "PENDING");
  const [selectedItems, setSelectedItems] = useState<OrderType[]>([]);
  const [isFetching, fetchOrderInfos] = useFetchOrderInfo("PENDING");

  useEffect(() => {
    // if (recentOrders.length === 0) {
    fetchOrderInfos();
    // }
  }, [fetchOrderInfos]);

  const moveToPaymentPage = async () => {
    try {
      const paymentId = await PaymentService.createPaymentId(
        selectedItems.map((item) => item.orderId)
      );

      navigate(`/payment/${paymentId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: OrderType
  ) => {
    if (e.target.checked) {
      if (ProviderUtils.isGME || ProviderUtils.isHanpass) {
        setSelectedItems([item]);
        return;
      }
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(selectedItems.filter((i) => i.orderId !== item.orderId));
    }
  };

  return (
    <Layout
      text={isKorean ? "장바구니" : "My Cart"}
      footer={false}
      onBack={() => navigate("/user-settings", { replace: true })}
    >
      <section
        className="h-100 mt-4 flex flex-col items-center justify-center space-y-2"
        style={{
          marginBottom: `${BOTTOM_NAVIGATION_HEIGHT * 3}px`,
        }}
      >
        {isFetching ? (
          <OrderSkeleton number={4} />
        ) : carts.length === 0 ? (
          <TWCardLayout className="mt-20">
            <div className="space-y-4 break-keep font-bold">
              <div className="text-center">
                <p className="text-xl">
                  {isKorean
                    ? "장바구니에 담긴 상품이 없습니다."
                    : "There are no items in your cart."}
                </p>
                <p className="text-text-secondary">
                  {isKorean
                    ? "원하는 상품을 장바구니에 담아보세요!"
                    : "Add items you want to your cart!"}
                </p>
              </div>

              <div
                className="space-y-2"
                // gap={1} sx={{ width: "100%" }}
              >
                <CustomButton
                  variant="outlined"
                  size="large"
                  onClick={() => navigate("/")}
                >
                  {isKorean ? "KTX 예매하기" : "Book KTX"}
                </CustomButton>
                {/* <CustomButton variant="outlined" size="large">
                  {isKorean
                    ? "유니버셜 스튜디오 재팬 예매하기"
                    : "Book Universal Studios Japan"}
                </CustomButton> */}
              </div>
            </div>
          </TWCardLayout>
        ) : (
          recentOrders.map((item) => (
            <TWCardLayout
              key={item.orderId}
              className="w-full p-3"
              // additionalInfo={
              //   <div className="flex w-full justify-end">
              //     <Button
              //       className="!p-0 !font-bold"
              //       onClick={() => moveToReservationDetail(item.orderId)}
              //     >
              //       주문 상세 보기
              //     </Button>
              //   </div>
              // }
            >
              <div className="relative flex w-full gap-2">
                <Checkbox
                  checked={selectedItems.some(
                    (i) => i.orderId === item.orderId
                  )}
                  size="small"
                  onChange={(e) => handleCheck(e, item)}
                  sx={{
                    alignSelf: "start",
                    p: 0,
                  }}
                />
                <div className="grid w-full grid-cols-12">
                  <div className="col-start-1 col-end-13 space-y-2">
                    <OrderThumbnail order={item} withoutBorder />

                    {/* <OrderThumbnailContent
                      key={item.orderId}
                      reservationDetails={item}
                      className="pt-0"
                    /> */}
                  </div>
                </div>
              </div>
            </TWCardLayout>
          ))
        )}
      </section>

      <Grid
        container
        columnSpacing={1}
        alignItems="center"
        sx={{
          position: "fixed",
          width: "100vw",
          maxHeight: `${BOTTOM_NAVIGATION_HEIGHT * 2}px`,
          minHeight: `${BOTTOM_NAVIGATION_HEIGHT}px`,
          // bottom: `${BOTTOM_NAVIGATION_HEIGHT}px`,
          bottom: `calc(env(safe-area-inset-bottom))`,
          left: 8,
          bgcolor: theme.palette.white.main,
          boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",

          p: 1,
          px: 2,
        }}
      >
        <Grid item xs={8}>
          <Stack direction="row" gap={2}>
            <Typography
              color="text.secondary"
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              {isKorean ? "총액" : "Total"}
            </Typography>
            <Typography
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "1.3rem" }}
            >
              {formatPrice(selectedItems.reduce((a, b) => a + b.totalPrice, 0))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <CustomButton
            size="medium"
            onClick={moveToPaymentPage}
            disabled={selectedItems.length === 0}
          >
            {isKorean ? "결제하기" : "Pay"}
          </CustomButton>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ShoppingCartPage;
