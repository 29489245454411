import { Skeleton } from "@mui/material";
import TWCardLayout from "pages/tailwind/TWCardLayout";

interface ActivityOptionSkeletonProps {
  number?: number;
}

const ActivityOptionSkeleton = ({
  number = 1,
}: ActivityOptionSkeletonProps) => {
  return Array.from({ length: number }).map((_, key) => (
    <TWCardLayout key={key} className="max-h-28">
      <div className="space-y-2 opacity-70">
        <div className="flex justify-between gap-2">
          <Skeleton variant="rounded" height={32} className="w-3/5" />
          <Skeleton variant="rounded" height={24} className="w-1/5" />
        </div>
        <div className="flex justify-between gap-2">
          <Skeleton variant="rounded" height={24} className="w-1/4" />
          <Skeleton variant="rounded" height={32} className="w-1/4" />
        </div>
      </div>
    </TWCardLayout>
  ));
};

export default ActivityOptionSkeleton;
