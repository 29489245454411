import Layout from "components/layout/Layout";
import { useParams } from "react-router-dom";
import { memo, useEffect, useRef, useState } from "react";
import { BOTTOM_NAVIGATION_HEIGHT, HEADER_HEIGHT } from "const/layout";
import ScrollToTopButton from "components/ScrollToTopButton";
import ActivityHeroSection from "./components/ActivityHeroSection";
import ActivityDetailTab from "./components/ActivityDetailTab";
import { OrderButton } from "./components/OrderButton";
import ProductTypeSection from "./detail/ProductTypeSection";
import ProductInquirySection from "./detail/ProductInquirySection";
import ProductReviewSection from "./detail/ProductReviewSection";
import ProductCancelRefundInfoSection from "./detail/ProductCancelRefundInfoSection";
import ProductInfoSection from "./detail/ProductInfoSection";
import { TravelReservationProduct } from "services/travelService";
import useScrollSectionObserver from "hooks/useScrollSectionObserver";
import ActivityPageSkeleton from "./components/skeleton/ActivityPageSkeleton";
import {
  ActivityReservationContext,
  JTRPassenger,
} from "context/ActivityContextx";
import { BookerInfo } from "utils/reservationUtils";
import { useGetActivityQuery, useMyInfoQuery } from "app/apiSlice";

const tabLabels = [
  "상품타입",
  "상품정보",
  "취소/환불 규정",
  "상품후기",
  "상품문의",
] as const;

export type TabType = (typeof tabLabels)[number];

const Activity = () => {
  const { activityId } = useParams();

  // State to keep track of the active tab
  const [tabValue, setTabValue] = useState<TabType>(tabLabels[0]);
  // const [activity, setActivity] = useState<TravelGood>();
  const { data: activity, isFetching } = useGetActivityQuery(activityId!);
  const { data: user } = useMyInfoQuery();

  // 예매자 정보 - userInfo없는 경우에만 서버로 전달하기
  const [bookerInfo, setBookerInfo] = useState<
    Pick<BookerInfo, "name" | "phone" | "email">
  >({
    name: user?.name || "",
    phone: user?.phone || "",
    email: user?.email || "",
  });

  useEffect(() => {
    if (user) {
      setBookerInfo({
        name: user.name,
        phone: user.phone,
        email: user.email,
      });
    }
  }, [user]);

  const [travelDate, setTravelDate] = useState("");
  const [number, setNumber] = useState(1);
  const [productList, setProductList] = useState<TravelReservationProduct[]>(
    []
  );

  // 탑승자 정보 section ref
  const passengersDetailRef = useRef<HTMLDivElement>(null);

  const [passengerDetails, setPassengerDetails] = useState<JTRPassenger[]>([
    { name: "", email: "" },
  ]);
  const [isPassengersValid, setIsPassengersValid] = useState(true);
  useEffect(() => {
    if (activity) {
      // 탑승자 정보 필요하면(1) false로 변경
      setIsPassengersValid(!Boolean(activity.isRequireEveryone));
    }
  }, [activity]);

  // useEffect(() => {
  //   const fetchGoodDetail = async () => {
  //     if (activityId != null) {
  //       const { data } = await TravelService.getGoodDetail(activityId);

  //       setActivity(data);
  //     }
  //   };

  //   fetchGoodDetail();
  // }, [activityId]);

  // Refs for sections and observer
  // const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  // const observerRef = useRef<IntersectionObserver | null>(null);

  const productTypeRef = useRef<HTMLDivElement>(null!);
  const productInfoRef = useRef<HTMLDivElement>(null!);
  const productCancelRefundInfoRef = useRef<HTMLDivElement>(null!);
  const productReviewRef = useRef<HTMLDivElement>(null!);
  const productInquiryRef = useRef<HTMLDivElement>(null!);

  const sectionsRefs = [
    productTypeRef,
    productInfoRef,
    productCancelRefundInfoRef,
    productReviewRef,
    productInquiryRef,
  ];

  // console.log(productInfoRef.current);
  // console.log(productCancelRefundInfoRef.current);
  // console.log([...sectionsRefs.map((ref) => ref.current)]);
  // console.log([...sectionsRefs.map((ref) => ref)]);

  // useEffect(() => {
  //   console.log(productInfoRef.current); // div DOM element
  //   console.log(sectionsRefs.map((ref) => ref.current));
  // });

  useScrollSectionObserver(sectionsRefs, setTabValue);

  /* useEffect(() => {
    const sectionRefs = [
      productTypeRef.current,
      productInfoRef.current,
      productCancelRefundInfoRef.current,
      productInquiryRef.current,
    ];

    if (!sectionRefs.every((ref) => ref)) {
      return;
    }

    // Create Intersection Observer with more nuanced options
    observerRef.current = new IntersectionObserver(
      (entries) => {
        // Sort entries by intersection ratio to handle overlapping sections
        // const sortedEntries = entries.sort(
        //   (a, b) => b.intersectionRect.height - a.intersectionRect.height
        // );

        // Find the most visible section
        // const mostVisibleEntry = sortedEntries.find(
        //   (entry) => entry.isIntersecting
        // );

        const intersectingEntries = entries.filter(
          (entry) => entry.isIntersecting
        );

        const mostVisibleEntry =
          intersectingEntries.length > 0
            ? intersectingEntries.reduce((prev, curr) => {
                if (
                  prev.intersectionRect.height > curr.intersectionRect.height
                ) {
                  return prev;
                }
                return curr;
              })
            : undefined;

        if (mostVisibleEntry) {
          setTabValue(mostVisibleEntry.target.id as unknown as TabType);
        }
      },
      {
        root: null,
        rootMargin: `-${HEADER_HEIGHT * 2 + 8}px 0px -${window.innerHeight - HEADER_HEIGHT * 3}px 0px`,
        threshold: 0.0,
        // threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0], // Multiple thresholds
      }
    );

    // Observe all sections
    sectionRefs.forEach((section) => {
      if (section && observerRef.current) {
        observerRef.current?.observe(section);
      }
    });

    // Clean up observer when component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }); */

  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabType) => {
    const targetSection = window.document.getElementById(newValue);
    const boundingRect = targetSection?.getBoundingClientRect();

    window.scrollBy({
      top: (boundingRect?.top ?? 0) - HEADER_HEIGHT * 2,
      behavior: "smooth",
    });
  };

  return (
    <ActivityReservationContext.Provider
      value={{
        bookerInfo,
        setBookerInfo,
        travelDate,
        setTravelDate,
        productList,
        setProductList,
        number,
        setNumber,
        passengerDetails,
        setPassengerDetails,
        isPassengersValid,
        setIsPassengersValid,
        passengersDetailRef,
      }}
    >
      <Layout footer={false}>
        {!activity ? (
          <ActivityPageSkeleton />
        ) : (
          <section
            className="bg-bg absolute left-1/2 w-full max-w-lg -translate-x-1/2 space-y-4 px-0"
            style={{
              top: `${HEADER_HEIGHT}px`,
            }}
          >
            {/* 상품 일반 정보 */}
            <ActivityHeroSection activity={activity!} />

            {/* 상품 세부 정보 */}
            {/* 상품 세부정보 탭 */}
            <ActivityDetailTab
              tabValue={tabValue}
              handleTabChange={handleTabChange}
              // tabLabels={tabLabels as unknown as TabType[]}
              tabLabels={[...tabLabels]}
            />

            <section
              className="space-y-4 pb-60"
              style={{ marginBottom: `${BOTTOM_NAVIGATION_HEIGHT * 2}px` }}
            >
              {/* 상품 타입 */}
              <ProductTypeSection
                ref={productTypeRef}
                title={tabLabels[0]}
                activity={activity!}
              />

              {/* 상품정보 */}
              <ProductInfoSection
                ref={productInfoRef}
                title={tabLabels[1]}
                content={activity?.usage ?? ""}
              />

              {/* 취소/환불 규정 */}
              <ProductCancelRefundInfoSection
                ref={productCancelRefundInfoRef}
                title={tabLabels[2]}
                content={activity?.refundNotice ?? ""}
              />

              {/* 상품 후기 */}
              <ProductReviewSection
                ref={productReviewRef}
                title={tabLabels[3]}
              />

              {/* 상품 문의 */}
              <ProductInquirySection
                ref={productInquiryRef}
                title={tabLabels[4]}
              />
            </section>
          </section>
        )}
        {/* 주문하기/장바구니 버튼 */}
        {activity && <OrderButton />}

        <ScrollToTopButton />
      </Layout>
    </ActivityReservationContext.Provider>
  );
};

export default memo(Activity);
