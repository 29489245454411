import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import useFormatPrice from "hooks/useFormatPrice";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Barcode from "react-barcode";
import CardService, { VAccountInfo } from "services/cardService";
import { useOutletContext } from "react-router-dom";
import { CardContext } from "../CardPageLayout";
import useModalSheet from "hooks/overlay/useModalSheet";
import { formatDateTimeFromString } from "utils/dateUtils";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import { useMyInfoQuery } from "app/apiSlice";

const options = ["DEPOSIT", "WITHDRAWAL"] as const;
export type OptionType = (typeof options)[number]; // "DEPOSIT" | "WITHDRAWAL"

const DepositWithdraw = () => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();
  const [VAccountInfo, setVAccountInfo] = useState<VAccountInfo>();
  const [VAccountModal, setVAccountModalVisibility] = useModalSheet({});

  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber } = currentCard || {};
  const [balance, setBalance] = useState(0);

  const { data: user } = useMyInfoQuery();

  useEffect(() => {
    const getBalance = async () => {
      const { data } = await CardService.getCardBalance(cardNumber);
      const balance = Number(data?.acnRmd || 0);
      setBalance(balance);
    };

    getBalance();
  }, [cardNumber]);

  const [option, setOption] = useState<OptionType>("DEPOSIT");
  const [isFocused, setIsFocused] = useState(false);
  const [amount, setAmount] = useState("0");
  const numericAmount = Number(amount.replaceAll(",", ""));
  const [barcodeSpec, setBarcodeSpec] = useState("");

  // Reset form when card changes
  useEffect(() => {
    setOption("DEPOSIT");
    setAmount("0");
    setBarcodeSpec("");
  }, [currentCard]);

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setAmount(`${Number(value)}`);
  };

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as OptionType;
    setAmount("0");
    setOption(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setAmount(`${numericAmount || "0"}`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setAmount(numericAmount > 0 ? numericAmount.toLocaleString() : "0");
  };

  const handleDepositWithdrawal = async () => {
    if (option === "WITHDRAWAL" && numericAmount > balance) {
      alert("Can't withdraw more than balance");
    }

    try {
      const response = await CardService.cvsBarcode(
        cardNumber,
        option,
        numericAmount,
        balance
      );

      setBarcodeSpec(response.barcodeSpec);
    } catch (error) {
      alert("잠시 후 다시 시도해주세요");
      console.error(error);
    }
  };

  const openCardChargeView = async () => {
    try {
      const data = await CardService.depositVirtualAccount(
        cardNumber,
        numericAmount
      );
      setVAccountInfo(data);
      setVAccountModalVisibility(true);
    } catch (error) {
      alert("잠시 후 다시 시도해주세요");
      console.error(error);
    }
  };

  const navigateToVoucherPage = async (
    cardType: "international" | "domestic"
  ) => {
    const pgType = cardType === "international" ? "104" : "101";

    try {
      const { data } = await CardService.getCardChargeUrl(cardNumber, pgType);
      console.log(data);
      const params = new URLSearchParams({
        // cardNo: cardNumber,
        // isgRqsDscd: "101", // 101 | 104 (default) | 106 | 901 | 902
        isgLanDscd: intl.locale, // "ko" (default) | "en" | "cn" | "jp"
        cardBalance: balance,
      } as any);
      const url = `${data}&${params.toString()}`;
      window.location.href = url;
    } catch (error) {
      alert("잠시 후 다시 시도해주세요");
      console.error(error);
    }
  };

  const getHeader = () => {
    return (
      <FormControl>
        <RadioGroup
          row
          // aria-labelledby="demo-radio-buttons-group-label"
          value={option}
          defaultValue={option}
          name="option-group"
          onChange={handleChangeOption}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              disabled={option === "WITHDRAWAL" && balance === 0}
              label={
                <p className="font-bold">
                  <FormattedMessage id={`card.${option.toLowerCase()}`} />
                </p>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  const isConvenienceButtonDisabled = () => {
    const fee = 1100;
    if (option === "DEPOSIT") {
      return numericAmount < 30000 || numericAmount > 500000;
    } else {
      return (
        numericAmount > 30000 ||
        numericAmount < fee ||
        balance < numericAmount + fee
      );
    }
  };

  return (
    <>
      <TWCardLayout
        header={getHeader()}
        className="border-none p-2 shadow-none"
      >
        <div className="w-full space-y-4">
          <label className="space-y-2">
            <p className="font-bold">
              <FormattedMessage id={`card.${option.toLowerCase()}Amount`} />
            </p>

            <TextField
              fullWidth
              name="amount"
              type={isFocused ? "number" : "text"}
              value={amount}
              onChange={handleChangeAmount}
              InputProps={{
                inputProps: {
                  min: 0,
                  // max: balance,
                },
                sx: {
                  height: "48px",

                  "& .MuiInputBase-input": {
                    textAlign: "end",
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                    margin: 0.3,
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0.3,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0.3,
                  },
                  "& input[type=text]": {
                    "-moz-appearance": "textfield",
                    margin: 1,
                  },
                  "& input[type=text]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 1,
                  },
                  "& input[type=text]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 1,
                  },
                },
                endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="0"
            />
            <p className="text-end text-xs">
              <FormattedMessage
                id={`card.convenienceStore.${option.toLocaleLowerCase()}Limit`}
                values={option === "DEPOSIT" ? { min: 3, max: 50 } : { max: 3 }}
              />
            </p>
          </label>

          {barcodeSpec && (
            <Stack alignItems="center">
              <Barcode
                value={barcodeSpec}
                height={48}
                width={1}
                format="CODE128"
                // displayValue
                fontSize={12}
              />
            </Stack>
          )}

          <div className="flex gap-2">
            {/* TODO: 버튼 하나로 바꾸고 event handler에서 로직 분기 처리하기 */}
            {option === "DEPOSIT" && (
              <CustomButton
                size="medium"
                variant="outlined"
                onClick={openCardChargeView}
                disabled={numericAmount === 0}
                id={`card.virtualAccount.${option.toLowerCase()}`}
              />
            )}
            {/* {option === "WITHDRAW" && ( */}
            <CustomButton
              size="medium"
              onClick={handleDepositWithdrawal}
              disabled={isConvenienceButtonDisabled()}
              id={`card.convenienceStore.${option.toLowerCase()}`}
            ></CustomButton>
            {/* )} */}
          </div>

          {user?.userId === 1 && option === "DEPOSIT" && (
            <>
              <Divider />
              <div className="space-y-2">
                <p className="font-bold">
                  <FormattedMessage id={`card.voucher`} />
                </p>
                <div className="flex gap-2">
                  <CustomButton
                    size="medium"
                    variant="outlined"
                    onClick={() => navigateToVoucherPage("international")}
                    id={`card.internationalCard`}
                  />
                  <CustomButton
                    size="medium"
                    variant="outlined"
                    onClick={() => navigateToVoucherPage("domestic")}
                    id={`card.domesticCard`}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </TWCardLayout>

      {VAccountInfo && (
        <VAccountModal>
          <div className="mb-0 w-full p-4 text-start">
            <p className="mb-6">
              <FormattedMessage id="card.virtualAccountNotice" />
              <br />
              <span className="text-sm text-red-500">
                <FormattedMessage id="card.virtualAccountFeeNotice" />
              </span>
            </p>

            <div className="space-y-2">
              <p>
                <FormattedMessage id="card.virtualAccount.account" />
                <br />
                <span className="font-bold">
                  {VAccountInfo?.VbankBankName} {VAccountInfo?.VbankNum}
                </span>
              </p>
              <p>
                <FormattedMessage id="card.virtualAccount.amount" />
                <br />
                <span className="font-bold">
                  {formatPrice(VAccountInfo?.Amt)}
                </span>
              </p>
              <p>
                <FormattedMessage id="card.virtualAccount.expiry" />
                <br />
                <span className="font-bold">
                  {formatDateTimeFromString(
                    `${VAccountInfo?.VbankExpDate.slice(2)}${VAccountInfo?.VbankExpTime}`
                  )}
                </span>
              </p>
            </div>
          </div>
        </VAccountModal>
      )}
    </>
  );
};

export default DepositWithdraw;
