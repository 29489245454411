import { HEADER_HEIGHT } from "const/layout";
import { RefObject, useEffect, useRef } from "react";

const useScrollSectionObserver = (
  sectionRefs: RefObject<HTMLDivElement>[],
  callback: Function,
  option?: IntersectionObserverInit
) => {
  // Refs for sections and observer
  // const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const observerRef = useRef<IntersectionObserver | null>(null);

  // console.log(sectionRefs);
  // console.log([...sectionRefs.map((ref) => ref.current)]);

  useEffect(() => {
    const sections = sectionRefs.map((ref) => ref.current);
    // console.log(sections);
    
    // Create Intersection Observer with more nuanced options
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const intersectingEntries = entries.filter(
          (entry) => entry.isIntersecting
        );

        const mostVisibleEntry =
          intersectingEntries.length > 0
            ? intersectingEntries.reduce((prev, curr) => {
                if (
                  prev.intersectionRect.height > curr.intersectionRect.height
                ) {
                  return prev;
                }
                return curr;
              })
            : undefined;

        if (mostVisibleEntry) {
          callback(mostVisibleEntry.target.id);
        }
      },
      {
        root: null,
        rootMargin: `-${HEADER_HEIGHT * 2}px 0px -${window.innerHeight - HEADER_HEIGHT * 3}px 0px`,
        threshold: 0.0,
        // threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0], // Multiple thresholds
        ...option,
      }
    );

    // Observe all sections
    sections.forEach((section) => {
      if (section && observerRef.current) {
        observerRef.current?.observe(section);
      }
    });

    // Clean up observer when component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  });
};

export default useScrollSectionObserver;
