import useLanguages from "hooks/useLanguages";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { ProviderUtils } from "utils/providerUtils";

const Footer = () => {
  const { isKorean } = useLanguages();
  const { pathname } = useLocation();

  return (
    <footer className="mt-4 flex flex-col items-center justify-center gap-4 bg-[#030310]/80 py-4 text-xs text-gray-200">
      {!ProviderUtils.provider && pathname === "/" && (
        <div className="px-2 text-start">
          {isKorean ? (
            <>
              <p>
                주식회사 라쿠카라차 | 서울시 강남구 선릉로 86길 26, 4층 (06197)
              </p>
              <p>사업자번호: 657-88-00880</p>
              <p>통신판매업신고번호: 2021-서울강남-01710</p>
              <p>대표이사: 이윤상</p>
              <p>개인정보보호책임자: 유호상</p>
              <p>고객센터: 02-568-1220</p>
            </>
          ) : (
            <>
              <p>
                &copy; Lacucaracha | 26, Seolleung-ro 86-gil, Gangnam-gu, Seoul
                (06197)
              </p>
              <p>Tel: 02-568-1220</p>
              <p>Chief Executive Officer: Yoonsang Lee</p>
              <p>Privacy Management Officer: Hosang Yoo</p>
            </>
          )}
        </div>
      )}

      {/* 하나카드 마이예약 페이지 */}
      {pathname === "/hanacard/reservations" ? (
        <div className="px-2 text-start">
          {isKorean ? (
            <>
              <p>
                주식회사 라쿠카라차 | 서울시 강남구 선릉로 86길 26, 4층 (06197)
              </p>
              <p>사업자번호: 657-88-00880</p>
              <p>통신판매업신고번호: 2021-서울강남-01710</p>
              <p>대표이사: 이윤상</p>
              <p>개인정보보호책임자: 유호상</p>
              <p>고객센터: 02-568-1220</p>
            </>
          ) : (
            <>
              <p>
                &copy; Lacucaracha | 26, Seolleung-ro 86-gil, Gangnam-gu, Seoul
                (06197)
              </p>
              <p>Tel: 02-568-1220</p>
              <p>Chief Executive Officer: Yoonsang Lee</p>
              <p>Privacy Management Officer: Hosang Yoo</p>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <div className="flex gap-1">
          <Link to="/usage-terms">
            <FormattedMessage id="footer.termsOfUse" />
          </Link>
          <p>|</p>
          <Link to="/privacy-policy">
            <FormattedMessage id="footer.privacyPolicy" />
          </Link>
          <p>|</p>
          <Link to="/customer-center">
            <FormattedMessage id="footer.customerCenter" />
          </Link>
          </div>

          <div>Copyright © LACUCARACHA Co., Ltd. All rights reserved.</div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
