import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrainService from "services/trainService";
import UserService, { HanpassCouponInterface } from "services/userService";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "./useLanguages";
import { Typography, Stack } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import CustomButton from "components/button/CustomButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HanpassBottomSheet from "components/bottomSheet/HanpassBottomSheet";

const getSortedValidHanpassCoupons = (array: HanpassCouponInterface[]) => {
  const sortedHanpassCoupons = [...array];
  sortedHanpassCoupons.sort((a, b) => {
    const dateA = new Date(a.expiredDate);
    const dateB = new Date(b.expiredDate);

    return dateA.getTime() - dateB.getTime();
  });
  return sortedHanpassCoupons.filter((coupon) => !coupon.deleted);
};

const useHanpassCoupon = () => {
  const { orderId } = useParams();

  const [hanpassCoupons, setHanpassCoupons] = useState<
    HanpassCouponInterface[]
  >([]);
  const [selectedCoupon, setSelectedCoupon] = useState<
    HanpassCouponInterface | undefined | null
  >();
  const [hanpassCouponDiscountAmount, setHanpassCouponDiscountAmount] =
    useState<number>(0);

  useEffect(() => {
    const getHanpassCoupons = async () => {
      const data = await UserService.getHanpassCoupons();
      const validHanpassCoupons = getSortedValidHanpassCoupons(data);
      const currentCoupon = validHanpassCoupons[0];

      setHanpassCoupons(validHanpassCoupons);
      setSelectedCoupon(currentCoupon);
    };

    if (ProviderUtils.isHanpass) {
      getHanpassCoupons();
    }
  }, []);

  useEffect(() => {
    const getHanpassCouponDiscountAmount = async () => {
      if (!selectedCoupon) {
        setHanpassCouponDiscountAmount(0);
        return;
      }
      const currentDiscountAmount =
        await TrainService.getHanpassCouponDiscountAmount(
          Number(orderId),
          selectedCoupon!.memberCouponSeq
        );
      setHanpassCouponDiscountAmount(currentDiscountAmount);
    };
    if (ProviderUtils.isHanpass) {
      getHanpassCouponDiscountAmount();
    }
  }, [orderId, selectedCoupon]);

  return {
    HanpassCoupon: (
      <HanpassCoupon
        coupons={hanpassCoupons}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
      />
    ),
    selectedCoupon,
    hanpassCouponDiscountAmount,
    // setSelectedCoupon,
  };
};

export default useHanpassCoupon;

interface HanpassCouponProps {
  coupons: HanpassCouponInterface[];
  selectedCoupon: HanpassCouponInterface | undefined | null;
  setSelectedCoupon: React.Dispatch<
    React.SetStateAction<HanpassCouponInterface | null | undefined>
  >;
}

const HanpassCoupon = ({
  coupons,
  selectedCoupon,
  setSelectedCoupon,
}: HanpassCouponProps) => {
  const { isKorean } = useLanguages();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {isKorean ? "한패스 쿠폰" : "Hanpass coupon"}
      </Typography>
    );
  };

  const showHanpassBottomSheet = () => {
    setBottomSheetVisible(true);
  };

  return (
    <>
      <CardLayout header={getHeader()}>
        <CustomButton
          variant="outlined"
          size="medium"
          disabled={!coupons.length}
          onClick={showHanpassBottomSheet}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            sx={{ width: "100%", fontWeight: "normal" }}
          >
            {coupons.length === 0
              ? "적용 가능 쿠폰 없음"
              : (selectedCoupon?.couponDescription ?? "쿠폰 적용안함")}
            <ArrowDropDownIcon sx={{ position: "absolute", right: 6 }} />
          </Stack>
        </CustomButton>
      </CardLayout>
      {bottomSheetVisible && (
        <HanpassBottomSheet
          coupons={coupons}
          setBottomSheetVisible={setBottomSheetVisible}
          setSelectedCoupon={setSelectedCoupon}
        />
      )}
    </>
  );
};
