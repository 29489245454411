import { Divider, Typography } from "@mui/material";
import useGetOrderName from "hooks/useGetOrderName";
import { useNavigate } from "react-router-dom";
import { OrderType } from "types/orderType";
import ShowTrainTicketButton from "components/reservation/ShowTrainTicketButton";
import ReservationDetails from "components/reservation/ReservationDetails";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import CustomButton from "components/button/CustomButton";

interface ProductInfoProps {
  order: OrderType;
}

const ProductInfoSection = ({ order }: ProductInfoProps) => {
  const formatPrice = useFormatPrice();
  const formatDateTime = useFormatDateToLocaleString();

  const getOrderName = useGetOrderName();
  const navigate = useNavigate();

  const showTicket = () => {
    navigate(`/order-details/${order.orderId}/ticket`, {
      state: order,
    });
    return;
  };

  const isCompleted = order.orderStatus === "COMPLETE";
  const productType = order?.productType;

  const getHeader = () => {
    return <h2 className="w-full">{getOrderName(order.orderName)}</h2>;
  };

  // 금액 표시
  const getAdditionalInfo = () => {
    return (
      <div className="flex w-full items-center justify-between">
        <p className="font-bold">
          <FormattedMessage id="orders.price" />:
        </p>

        <Typography
          variant="h6"
          component="div"
          color="secondary"
          sx={{ fontWeight: "bold" }}
        >
          {formatPrice(order.originalPrice)}
        </Typography>
        {/* {!isPending && ProviderUtils.isHanacard && (
          <PriceDetailSection order={order} />
        )} */}
      </div>
    );
  };

  return (
    <>
      {productType === "TRAIN" && (
        <TWCardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
          <div className="space-y-2">
            <ReservationDetails reservationDetails={order} />
            <ShowTrainTicketButton
              status={order.orderStatus}
              onClick={showTicket}
            />
          </div>
        </TWCardLayout>
      )}
      {productType === "TRAVEL" && (
        <TWCardLayout
          header={
            <h3 className="w-full break-keep text-xl font-bold">
              {order.orderName.trim()}
            </h3>
          }
          additionalInfo={
            <div className="flex w-full items-center justify-between">
              <p className="font-bold">
                <FormattedMessage id="orders.price" />:
              </p>

              <Typography
                variant="h6"
                component="div"
                color="secondary"
                sx={{ fontWeight: "bold" }}
              >
                {formatPrice(order.originalPrice)}
              </Typography>
            </div>
          }
        >
          <div className="space-y-2">
            <div className="flex justify-between">
              <p className="font-bold">이용날짜</p>
              <p className="text-start font-bold">
                {formatDateTime(order.travelGoods.startDate, {
                  dateStyle: "long",
                })}
              </p>
            </div>
            <Divider />
            <div className="flex justify-between">
              <p className="font-bold">
                <PeopleAltIcon />
                인원 수
              </p>
              <p className="font-bold">
                {order.travelGoods.peopleCount ?? 0}명
              </p>
            </div>
            {isCompleted && (
              <CustomButton variant="contained" onClick={showTicket}>
                티켓 보기
              </CustomButton>
            )}
          </div>
        </TWCardLayout>
      )}
      {productType === "AIR" && (
        <TWCardLayout>
          <h3 className="w-full break-keep text-xl font-bold">
            {order.orderName.trim()}
          </h3>
        </TWCardLayout>
      )}
    </>
  );
};

export default ProductInfoSection;
