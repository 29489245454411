import axios, { AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";
import { PaymentMethod } from "const/paymentMethod";
import { OrderType, PaymentInfo } from "types/orderType";
import { KTXReservationData } from "pages/tickets/AlimTalkTicket";
import { getUserAgent } from "utils/osUtils";

interface CancelResponse {
  orderId: number;
  result: string;
  cancelAmount: number;
  errorMessage: string;
}

export interface CancelFeeCheckResponse {
  errorMessage: string;
  mileageCancelAmount: number; // 수수료 반영된 후 환불되는 라차 포인트 e.g. 5000 (실제 사용 포인트 10000 - 수수료 5000) (수수료는 포인트에서 먼저 차감)
  realPaymentCancelAmount: number; // 수수료 반영된 후 환불되는 실결제 금액 e.g. 40000
  totalCancelAmount: number; // 수수료 반영된 후 환불되는 총 결제 금액 e.g. 45000 (원 구매 가격 50000 - 수수료 5000)
  cancelFee: number; // 취소 수수료 e.g. 5000
}

interface PaymentRequestResponse {
  returnCode: string;
  startParams: string;
  startUrl: string;
  inappPayUrl: string;
  hanpassTransactionNumber: string;
  orderId: number;
}

// axios 인스턴스 기본 설정
const paymentApi = axios.create({
  // baseURL: apiBaseUrl + "/train",
  baseURL: apiBaseUrl,
});

// 요청 인터셉터 추가
paymentApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
paymentApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

export interface GMEPaymentRequestResponse {
  memberSeq: number;
  availablePay: boolean;
  key: string;
}

class PaymentService {
  // paymentId 생성
  static async createPaymentId(orderIds: number[] | number): Promise<number> {
    const orderIdList = Array.isArray(orderIds) ? orderIds : [orderIds];

    try {
      const response = await paymentApi.post("/user/order", {
        orderIdList,
      });

      return response.data;
    } catch (error) {
      console.error("PaymentId cancelReservation error:", error);
      throw error;
    }
  }

  // 결제 절차 시작 요청
  static async paymentRequest(
    // orderIds: number[] | number,
    paymentId: number | string,
    paymentMethod: PaymentMethod,
    point: number = 0,
    externalCouponId?: number
  ): Promise<PaymentRequestResponse & GMEPaymentRequestResponse> {
    const device = getUserAgent();

    try {
      const response: AxiosResponse<
        PaymentRequestResponse & GMEPaymentRequestResponse
      > = await paymentApi.post("/user/paymentRequest", {
        paymentId,
        paymentMethod,
        point,
        externalCouponId,
        device,
      });

      // console.log("Payment initiation request successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Payment initiation request error:", error);
      throw error;
    }
  }

  // Redirect to Danal web server with STARTPARAMS
  static async redirectToDanal(
    startUrl: string,
    startParams: string,
    formTarget = ""
  ): Promise<void> {
    // Create a form element
    const form = document.createElement("form");
    form.method = "POST";
    form.action = startUrl;
    form.acceptCharset = "EUC-KR";
    form.target = formTarget;

    // Create a hidden input to hold the STARTPARAMS
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "STARTPARAMS";
    hiddenField.value = startParams;
    form.appendChild(hiddenField);

    // Append the form to the body and submit it to redirect
    document.body.appendChild(form);
    form.submit();
  }

  static async cancelFeeCheck(
    orderId: number
  ): Promise<CancelFeeCheckResponse> {
    try {
      const response: AxiosResponse<CancelFeeCheckResponse> =
        await paymentApi.post(`/user/cancelCheck`, {
          orderIdList: [orderId],
        });

      // console.log("Reservation cancel fee check successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Reservation cancel fee check error:", error);
      throw error;
    }
  }

  // 기차 승차권 예약 취소 요청
  static async cancelReservation(orderId: number): Promise<CancelResponse> {
    try {
      const response: AxiosResponse<CancelResponse> = await paymentApi.post(
        `/user/cancelRequest`,
        { orderIdList: [orderId] }
      );

      console.log("Reservation cancellation successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Reservation cancellation error:", error);
      throw error;
    }
  }

  // paymentId 관련 order 조회
  static async getOrderListOfPayment(paymentId: number): Promise<OrderType[]> {
    try {
      const response = await paymentApi.get<{ orderInfoList: OrderType[] }>(
        `/user/payment`,
        {
          params: { paymentId },
        }
      );

      return response.data.orderInfoList;
    } catch (error) {
      console.error("Get order lists error:", error);
      throw error;
    }
  }

  // /train/..

  // Retrieve reservation details based on orderId
  static async reserveDetail(orderId: number | string): Promise<OrderType> {
    try {
      const response: AxiosResponse<OrderType> = await paymentApi.get(
        "/train/reserveDetail",
        {
          params: { orderId },
        }
      );

      // console.log("Reservation details retrieval successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error retrieving reservation details:", error);
      throw error;
    }
  }

  // Retrieve reservation details based on KTX reservation number for AlimTalk
  static async getAlimTalkReservationDetail(
    ktxNumber: string
  ): Promise<KTXReservationData> {
    try {
      const response: AxiosResponse<KTXReservationData> = await paymentApi.get(
        "/train/reserveDetailTest",
        {
          params: { reservationNumber: ktxNumber },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error retrieving reservation details:", error);
      throw error;
    }
  }

  // 결제 진행 함수
  static async initializePayment(
    // orderIds: number | number[],
    paymentId: number,
    paymentMethod: PaymentMethod,
    point: number = 0,
    externalCouponId?: number
  ): Promise<void> {
    try {
      const paymentResult = await this.paymentRequest(
        paymentId,
        paymentMethod,
        point,
        externalCouponId
      );

      // Check if the payment initiation was successful
      if (paymentResult) {
        // point로만 결제한 경우
        if (!paymentResult.returnCode) {
          const params = new URLSearchParams({
            orderId: `${paymentResult.orderId}`,
          });
          window.location.href = `/train/creditPayment?${params.toString()}`;
        }

        // 라차 KTC 카드
        if (paymentMethod === PaymentMethod.KTC) {
          window.location.href = paymentResult.startUrl;
          return;
        }

        // If CP authentication is successful, redirect to the Danal web server with STARTPARAMS
        if (
          (paymentResult.returnCode === "0000" &&
            [
              PaymentMethod.CREDIT,
              PaymentMethod.CORPORATE,
              PaymentMethod.TRANSFER,
            ].includes(paymentMethod as PaymentMethod)) ||
          (paymentResult.returnCode === "00000" &&
            paymentMethod === PaymentMethod.OVERSEAS)
        ) {
          PaymentService.redirectToDanal(
            paymentResult.startUrl,
            paymentResult.startParams
          );
          return;
        }

        if (
          paymentResult.hanpassTransactionNumber &&
          paymentMethod === PaymentMethod.HANPASS
        ) {
          window.location.href = paymentResult.inappPayUrl;
          return;
        }

        console.error(
          "Payment initiation failed with returnCode:",
          paymentResult.returnCode
        );
        return;

        // Process the actual payment using the provided startUrl and startParams
        // const processResult = await PaymentService.processPayment(
        //   reservationResponse.orderId,
        //   method
        // );
        //
        // Here you would typically handle redirection or further actions required by your payment process
      } else {
        // Handle the failed payment initiation here
        console.error("Payment initiation failed");
      }
    } catch (error) {
      console.error("결제 처리 중 오류 발생:", error);
      throw error;
    }
  }

  // GME 결제 진행 함수
  static async initializeGMEPayment(key: string): Promise<PaymentInfo> {
    try {
      const response = await paymentApi.post(
        `/train/gmePayment?key=${key}`,
        {}
      );
      return response.data;
    } catch (error) {
      console.error("결제 처리 중 오류 발생:", error);
      throw error;
    }
  }

  // 영수증 출력 페이지 url 요청 함수
  static async getReceiptUrl(paymentId: number | string): Promise<string> {
    try {
      const response = await paymentApi.get(
        `/train/receipt?paymentId=${paymentId}`
      );
      return response.data;
    } catch (error) {
      console.error("영수증 처리 중 오류 발생:", error);
      throw error;
    }
  }

  // 기차 승차권 카드 결제 요청
  /*   static async creditPayment() {
    try {
      const response = await paymentApi.post("/creditPayment", null, {});

      console.log("카드 결제 처리 성공:", response.data);
      return response.data;
    } catch (error) {
      console.error("카드 결제 처리 오류:", error);
      throw error;
    }
  } */

  // Process the payment after initiating it
  // static async processPayment(
  //   orderId: number,
  //   paymentMethod: PaymentMethodValue
  // ) {
  //   try {
  //     // Call paymentRequest to initiate the payment process
  //     const paymentResponse = await this.paymentRequest(orderId, paymentMethod);

  //     // Check if the return code is "0000", which indicates success
  //     if (
  //       paymentResponse.returnCode === "0000" ||
  //       paymentResponse.returnCode === "00000"
  //     ) {
  //       console.log("Payment initiation successful, proceeding to startUrl");

  //       // Extract startParams and startUrl from the response
  //       const { startParams, startUrl } = paymentResponse;

  //       // Make a POST request to the startUrl with startParams
  //       const startResponse = await axios.post(startUrl, startParams, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       console.log("Redirected to payment gateway:", startResponse);
  //       return startResponse.data;
  //     } else {
  //       console.error(
  //         "Payment initiation failed with code:",
  //         paymentResponse.returnCode
  //       );
  //       throw new Error(
  //         `Payment initiation failed with code: ${paymentResponse.returnCode}`
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error processing payment:", error);
  //     throw error;
  //   }
  // }

  // 기차 승차권 결제 처리 테스트
  /*   static async paymentTest(reservationNumber) {
    try {
      const response = await paymentApi.post("/paymentTest", null, {
        params: { reservationNumber },
      });

      console.log("결제 테스트 성공:", response.data);
      return response.data;
    } catch (error) {
      console.error("결제 테스트 오류:", error);
      throw error;
    }
  } */

  // 기차 승차권 취소 처리 테스트
  /*   static async cancelTest(reservationNumber, isCancel) {
    try {
      const response = await paymentApi.post("/cancelTest", null, {
        params: { reservationNumber, isCancel },
      });

      console.log("취소 테스트 성공:", response.data);
      return response.data;
    } catch (error) {
      console.error("취소 테스트 오류:", error);
      throw error;
    }
  } */
}

export default PaymentService;

// "https://apiv1-dev.redtable.global/kcard/card/pay?price=59800&pay_no=lacha1546&partner_id=lacucaracha&token=0dl8ib2vPZIN7NzeT46EJWxWR0duIObDBIvhrMumUGfi2m0MvVimcRC1yuAZON3m"
