import { IconButton } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import useFormatPrice from "hooks/useFormatPrice";
import { useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import CardService from "services/cardService";
import { useOutletContext } from "react-router-dom";
import { CardContext } from "../CardPageLayout";

// interface CardBalanceProps {
//   balance: number;
// }

const CardBalance = () => {
  const formatPrice = useFormatPrice();
  const [showBalance, setShowBalance] = useState(
    JSON.parse(localStorage.getItem("showBalance") || "false")
  );
  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber } = currentCard || {};
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const getBalance = async () => {
      const { data } = await CardService.getCardBalance(cardNumber);
      const balance = Number(data?.acnRmd || 0);
      setBalance(balance);
    };

    getBalance();
  }, [cardNumber]);

  const toggleBalance = () => {
    setShowBalance(!showBalance);
    localStorage.setItem("showBalance", JSON.stringify(!showBalance));
  };

  return (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2">
        <AccountBalanceWalletIcon fontSize="large" color="primary" />
        <p className="font-bold">
          <FormattedMessage id="card.balance" />
        </p>
      </div>
      <div className="flex items-center">
        <p className="text-xl font-bold">
          {showBalance ? formatPrice(balance) : `••••••••••`}
        </p>
        <IconButton size="small" className="self-start" onClick={toggleBalance}>
          {showBalance ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
      </div>
    </div>
  );
};

export default CardBalance;
