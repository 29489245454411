import { useEffect, useState } from "react";
import Layout from "components/layout/Layout"; // 일관된 페이지 구조를 위한 레이아웃 컴포넌트
import { useNavigate, useSearchParams } from "react-router-dom";
import Stepper from "./sections/Stepper"; // 프로세스의 단계를 표시하는 컴포넌트
import { Step } from "./sections/Step"; // 각 단계를 나타내는 컴포넌트
import HanpassConsentForm from "./sections/ConsentForm"; // 동의 체크박스를 관리하는 컴포넌트
import { Box } from "@mui/material"; // 레이아웃을 위한 Material-UI Box 컴포넌트
import SubmitButton from "./sections/SubmitButton"; // 폼 제출을 위한 버튼 컴포넌트
import { css } from "styled-components";
import AuthService from "services/authService";
import { useIntl } from "react-intl";
import { changeLanguage } from "app/userSlice";
import { useAppDispatch } from "app/hooks";
import useLanguages from "hooks/useLanguages";

const HanpassConsentPage = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const providerName = searchParams.get("provider"); // "Hanpass"
  const providerText = isKorean ? "한패스" : providerName;

  const formData = {
    name:
      searchParams.get("firstName") +
      searchParams.get("middleName") +
      searchParams.get("lastName"),
    username: providerName?.toLowerCase() + "_" + searchParams.get("memberSeq"),
    email: searchParams.get("email"),
    phoneNumber: searchParams.get("phoneNumber"),
    language: searchParams.get("language"),
    provider: providerName,
    nationality: searchParams.get("nationality"),
  };

  useEffect(() => {
    const language = searchParams.get("language");

    if (language) {
      dispatch(changeLanguage(language));
    }
  }, [dispatch, searchParams]);

  // 사용자 동의를 관리하기 위한 상태
  const [agreements, setAgreements] = useState({
    termsOfService: false,
    privacyPolicy: false,
    promotions: false,
  });

  // 동의 토글 변경을 처리하는 함수
  const handleAgreementChange = (name, value) => {
    setAgreements((prevAgreements) => ({
      ...prevAgreements,
      [name]: value,
    }));
  };

  // 현재 회원가입 프로세스에서 활성화된 단계
  const activeStep = 0;

  const isFormValid = () => {
    if (formData.name.trim() === "") {
      formData.name = "noname";
    }

    // 모든 필드가 채워져 있는지 확인
    for (const [key, value] of Object.entries(formData)) {
      if (key !== "email" && value.trim() === "") {
        alert(`${key} 필드를 채워주세요.`);
        return false;
      }
    }

    // 이메일과 도메인이 선택되었는지 확인
    // if (email.trim() === "") {
    //   alert("이메일을 올바르게 입력해주세요.");
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    // 회원가입에 필요한 데이터 구성
    const revFormData = new FormData();

    // 폼 데이터에 필드 추가
    revFormData.append("name", formData.name);
    revFormData.append("loginId", formData.username);
    revFormData.append("phone", formData.phoneNumber);
    revFormData.append("email", formData.email);
    revFormData.append("language", formData.language);
    revFormData.append("nationality", formData.nationality);
    revFormData.append("isReceivingEmail", true);
    revFormData.append("isReceivingSms", true);
    revFormData.append("isReceivingTalk", true);
    revFormData.append("provider", formData.provider);

    try {
      // AuthService를 이용한 회원가입 요청
      setIsLoading(true);
      const response = await AuthService.registerWithToken(revFormData);
      // const response = await AuthService.register(signupData);
      if (response.status === 201) {
        // 회원가입 성공 처리
        navigate("/completion-hanpass"); // 회원가입 완료 페이지로 이동
      } else {
        // 회원가입 실패 처리
        alert("회원가입에 실패했습니다.");
      }
    } catch (error) {
      console.error("회원가입 오류:", error);
      alert("회원가입 과정 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  // 필수 동의 사항이 확인되지 않았다면 제출 버튼을 비활성화
  const isSubmitDisabled =
    !agreements.termsOfService || !agreements.privacyPolicy;

  return (
    <div className="terms-agreement-container">
      <Layout
        text={intl.formatMessage({ id: "signup.title" })}
        onBack={() => navigate(-1)}
      >
        <Box height={20} /> {/* 여백 추가 */}
        {/* 현재 단계를 표시하는 스테퍼 컴포넌트 */}
        <Stepper activeStep={activeStep}>
          {[1, 2, 3].map(({ label, Icon }, index) => (
            <Step key={label} id={index}>
              <Icon className={css({ marginInlineEnd: "10px" })} />
              <span className={css({ textStyle: "h3" })}>{label}</span>
            </Step>
          ))}
        </Stepper>
        <Box height={10} /> {/* 추가 여백 */}
        {/* 사용자 동의를 위한 컨센트 폼 */}
        <HanpassConsentForm
          agreements={agreements}
          onAgreementChange={handleAgreementChange}
          formData={formData}
          providerName={providerText}
        />
        {/* 제출 버튼 */}
        <SubmitButton
          handleSubmit={handleSubmit}
          isSubmitDisabled={isLoading || isSubmitDisabled}
        />
      </Layout>
    </div>
  );
};

export default HanpassConsentPage;
