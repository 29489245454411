import axios, { AxiosResponse, isAxiosError } from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";
import { UserState } from "./authService";
import { LanguageCodeType } from "const/languageCodes";
import { OrderStatus, OrderType } from "types/orderType";
import {
  calculateCurrentDate,
  calculateDateBeforeOneMonth,
} from "utils/dateUtils";
import { withPaging } from "types/utilityTypes";

const userApi = axios.create({
  baseURL: apiBaseUrl + "/user",
});

userApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

userApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

export type PointEventType = "SAVING" | "USING" | "EXPIRY" | "RETURN";

export interface PointHistoryType {
  amount: number;
  createdDate: string;
  eventType: PointEventType;
  expireDate: string | null;
  orderId: number | null;
  pointId: number;
  savingDesc: string | null;
  userId: number;
}

type PointHistories = withPaging<PointHistoryType>;

// interface PointHistories {
//   content: PointHistoryType[];
//   totalPages: number;
//   first: boolean;
//   last: boolean;
//   number: number;
// }

interface OrderParam {
  page: number;
  size: number;
  startDate: string;
  endDate: string;
  status?: OrderStatus;
}

type UpdatedUserInfo = Partial<{
  userId: number;
  name: string;
  phone: string;
  email: string;
  userStatus: string; // "ACTIVE" | "INACTIVE" => TODO: replace with Union type
  isReceivingEmail: boolean;
  isReceivingSms: boolean;
  isReceivingTalk: boolean;
  oldPw: string;
  newPw: string;
  language: LanguageCodeType;
  updateDate: string;
}>;

export type MyOrder = withPaging<OrderType>;
// export interface MyOrder {
//   content: OrderType[];
//   totalPages: number;
//   first: boolean;
//   last: boolean;
//   number: number;
// }

export interface HanpassCouponInterface {
  memberCouponSeq: number; // 한패스 쿠폰 번호
  designType: string;
  memberSeq: number;
  couponType: "AMOUNT" | "RATIO" | "FREE";
  couponValue: string;
  couponDescription: string;
  deleted: true;
  useYn: string;
  regDate: string;
  modDate: string;
  expiredDate: string;
  memberRemittanceSeq: number;
  feeCurrency: string;
  feeAmount: number;
  eventCouponListSeq: number;
  serviceType: "KTX";
  referenceNumber: number;
  serviceUrl: string;
  useCountryCode: string;
}

class UserService {
  // 사용자 정보, orders(?) 관련 메서드s
  // Fetch user information
  static async getMyInfo(): Promise<UserState> {
    try {
      const response: AxiosResponse<UserState> = await userApi.get("/myInfo");
      return response.data;
    } catch (error) {
      // console.error("Error fetching user information:", error);
      throw error;
    }
  }

  // Update user information
  static async update(updatedUserInfo: UpdatedUserInfo) {
    try {
      const response: AxiosResponse<string> = await userApi.post(
        "/update",
        updatedUserInfo
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        console.error("Error updating user information:", error);
        // alert(error.response?.data.errorMessage);
      }
      throw error;
    }
  }

  static async getMyOrders(
    startDate: string,
    endDate?: string,
    page = 0,
    status?: OrderStatus | "ALL",
    size = 10
  ): Promise<MyOrder> {
    const params = {
      page,
      size,
      startDate: startDate || calculateDateBeforeOneMonth(),
      endDate: endDate || calculateCurrentDate(),
      status: "NOT_PENDING",
    };

    // if (status && status !== "ALL") {
    //   params.status = status.toLowerCase() as Lowercase<OrderStatus>;
    // }

    if (status && status !== "ALL") {
      params.status = status;
    }

    // const params = `?page=${page}&size=${size}${
    //   startDate ? `&startDate=${startDate}` : ""
    // }${endDate ? `&endDate=${endDate}` : ""}${
    //   status && status !== "all" ? `&status=${status}` : ""
    // }`;

    try {
      const response: AxiosResponse<MyOrder> = await userApi.get("/myOrders", {
        params,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching user orders:", error);
      throw error;
    }
  }

  // 포인트 조회 함수
  static async getPoint(): Promise<number> {
    try {
      const response = await userApi.get("/myPoint");
      return response.data;
    } catch (error) {
      console.error("Error getting point:", error);
      throw error;
    }
  }

  // 포인트 내역 함수
  // 무한 스크롤 적용되는 부분으로 axios 사용
  static async getPointHistory(page = 0, size = 10): Promise<PointHistories> {
    const params = {
      page,
      size,
    };

    try {
      const response = await userApi.get("/myPointEvent", {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error getting point history:", error);
      throw error;
    }
  }

  // 포인트 등록 함수
  static async registerPoint(code: string) {
    const data = new FormData();
    data.append("code", code);

    try {
      await userApi.post("/serialCode", data);
    } catch (error) {
      console.error("Error registering coupon");
      throw error;
    }
  }

  // 한패스 쿠폰 조회 함수
  static async getHanpassCoupons(): Promise<HanpassCouponInterface[]> {
    try {
      const response = await userApi.get("/hanpassCoupons?filter=KTX");
      return response.data;
    } catch (error) {
      console.error("Error getting hanpass coupons:", error);
      throw error;
    }
  }
}

export default UserService;
