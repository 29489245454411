import { Container, SelectChangeEvent } from "@mui/material";
import Card from "components/card/Card";
import Layout from "components/layout/Layout";
import useValidate from "hooks/useValidate";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import CardBack from "components/card/CardBack";
import { NumericString } from "types/numericString";
import CardService from "services/cardService";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useIntl } from "react-intl";
import { resetCard } from "app/cardsSlice";
import { useAppDispatch } from "app/hooks";
import CardSignUp from "./CardSignUp";
import CardInfo from "./CardInfo";
import { useMyInfoQuery } from "app/apiSlice";
import useInputs from "hooks/useInputs";
import CustomButton from "components/button/CustomButton";
import { isAxiosError } from "axios";
import useModalSheet from "hooks/overlay/useModalSheet";

const getCardNumberForUI = (cardNumbers: [string, string, string, string]) => {
  const cn1 = cardNumbers[0].padEnd(4, "_");
  const cn2 = cardNumbers[1].replaceAll(/\d/g, "*").padEnd(4, "_");
  const cn3 = cardNumbers[2].replaceAll(/\d/g, "*").padEnd(4, "_");
  const cn4 = cardNumbers[3].padEnd(4, "_");

  return `${cn1}${cn2}${cn3}${cn4}`;
};

const getCardExpirationDateForUI = (expirationDate: string) => {
  const month = expirationDate.slice(0, 2).padStart(2, "__");
  const year = expirationDate.slice(2, 4).padStart(2, "__");

  return `${month} / ${year}`;
};

const isNumericString = (str: string) => {
  return /^\d*$/.test(str);
};

const initialValidState = {
  cardName: true,
  cardNumber1: true,
  cardNumber2: true,
  cardNumber3: true,
  cardNumber4: true,
  cvc: true,
};

/**
 * A component that pass initialUserForm to CardRegisterContent as a prop.
 * It only renders CardRegisterContent if the user data is successfully fetched and not undefined.
 * This component enables CardRegisterContent to initialize userForm with the legitimate user data.
 *
 * @returns {JSX.Element} The rendered component.
 */
const CardRegister = () => {
  const { data: user } = useMyInfoQuery();
  const { name, email, birthDate } = user || {};
  const initialUserForm = {
    name: name ?? "",
    email: email ?? "",
    cardBirthDate: birthDate ?? "",
    cardPassword: "",
  };

  return Boolean(user) ? (
    <CardRegisterContent initialUserForm={initialUserForm} />
  ) : null;
};

const CardRegisterContent = ({
  initialUserForm,
}: {
  initialUserForm: {
    name: string;
    email: string;
    cardBirthDate: string;
    cardPassword: string;
  };
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, validateCard] = useValidate(initialValidState);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Modal, setModalVisible] = useModalSheet();
  const [errorMessage, setErrorMessage] = useState("");

  // 카드 회원 가입 여부
  const [cardSignedUp, setCardSignedUp] = useState(false);

  useEffect(() => {
    CardService.checkSignUp()
      .then(() => {
        setCardSignedUp(true);
      })
      .catch(() => {
        setCardSignedUp(false);
      });
  });

  const [cardNumber1, setCardNumber1] = useState("");
  const [cardNumber2, setCardNumber2] = useState("");
  const [cardNumber3, setCardNumber3] = useState("");
  const [cardNumber4, setCardNumber4] = useState("");
  const cardNumbers = [cardNumber1, cardNumber2, cardNumber3, cardNumber4] as [
    string,
    string,
    string,
    string,
  ];
  const cardNumber = cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4;

  const cardNameSetterFunctions = {
    cardNumber1: setCardNumber1,
    cardNumber2: setCardNumber2,
    cardNumber3: setCardNumber3,
    cardNumber4: setCardNumber4,
  };

  const [expirationDateMonth, setExpirationDateMonth] = useState("");
  const [expirationDateYear, setExpirationDateYear] = useState("");
  const expirationDates = [expirationDateMonth, expirationDateYear] as [
    string,
    string,
  ];
  const expirationDate = `${
    expirationDateMonth ? expirationDateMonth : "__"
  }${expirationDateYear}`;

  const [CVC, setCVC] = useState("");

  const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "cardNumber1":
      case "cardNumber2":
      case "cardNumber3":
      case "cardNumber4":
        validateCard(e);
        if (isNumericString(value)) {
          cardNameSetterFunctions[name](value);
        }
        break;
      case "cvc":
        validateCard(e);
        if (isNumericString(value)) {
          setCVC(value);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeExpirationDate = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    switch (name) {
      case "expirationDateMonth":
        setExpirationDateMonth(value);
        break;
      case "expirationDateYear":
        setExpirationDateYear(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setIsFlipped(false);
    });

    return () => {
      document.body.removeEventListener("click", () => {
        setIsFlipped(false);
      });
    };
  }, []);

  // USER INFO
  // const { data: user } = useMyInfoQuery();
  // const { name, email, birthDate } = user || {};
  // const initialUserForm = useMemo(
  //   () => ({
  //     name,
  //     email,
  //     cardBirthDate: birthDate ?? "",
  //     cardPassword: "",
  //   }),
  //   [name, email, birthDate]
  // );

  const { form: userForm, onChange } = useInputs(initialUserForm);

  const [userValidState, validateUser] = useValidate({
    name: true,
    email: true,
    cardBirthDate: true,
    cardPassword: true,
  });

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateUser(e);
    onChange(e);
  };

  const validateUserForm = () => {
    // check if all fields are filled
    if (!userForm.name) {
      // alert(intl.formatMessage({ id: "signup.enterName" }));
      setErrorMessage(intl.formatMessage({ id: "signup.enterName" }));
      setModalVisible(true);
      return false;
    }
    if (!userForm.email) {
      // alert(intl.formatMessage({ id: "signup.enterEmail" }));
      setErrorMessage(intl.formatMessage({ id: "signup.enterEmail" }));
      setModalVisible(true);
      return false;
    }
    if (!userForm.cardBirthDate) {
      // alert(intl.formatMessage({ id: "booking.birthDate" }));
      setErrorMessage(intl.formatMessage({ id: "booking.birthDate" }));
      setModalVisible(true);
      return false;
    }
    if (!userForm.cardPassword) {
      // alert(intl.formatMessage({ id: "signup.enterPassword" }));
      setErrorMessage(intl.formatMessage({ id: "signup.enterPassword" }));
      setModalVisible(true);
      return false;
    }
    if (
      !(
        userValidState.name &&
        userValidState.email &&
        userValidState.cardBirthDate &&
        userValidState.cardPassword
      )
    ) {
      // alert("유효한 사용자 정보를 입력하세요.");
      setErrorMessage(intl.formatMessage({ id: "card.register.userInfo" }));
      setModalVisible(true);
      return false;
    }

    return true;
  };

  const validateCardForm = () => {
    if (
      cardNumber.length !== 16 ||
      expirationDateMonth === "" ||
      expirationDateYear === "" ||
      CVC === ""
    ) {
      // alert("카드 정보를 입력하세요");
      setErrorMessage(intl.formatMessage({ id: "card.register.cardInfo" }));
      setModalVisible(true);
      return false;
    }

    return true;
  };

  const registerCard = async () => {
    if (!cardSignedUp && !validateUserForm()) {
      return;
    }

    if (!validateCardForm()) {
      return;
    }

    setIsLoading(true);
    try {
      if (!cardSignedUp) {
        await CardService.signUp(userForm);
      }

      const response = await CardService.addNewCard(
        cardNumber as NumericString,
        `${expirationDateYear}${expirationDateMonth}`,
        CVC as NumericString
      );
      if (response.data !== null) {
        navigate("/card");
        // TODO: Update card update logic to RTK or apply redux-saga/redux-thunk
        dispatch(resetCard());
        return;
      } else {
        const errorMessages = response.result_msg;

        alert(
          errorMessages[intl.locale as keyof typeof errorMessages] ??
            errorMessages.ko ??
            "Card registration failed"
        );
      }
    } catch (error) {
      // alert("Card registration failed");
      console.error(error);
      if (isAxiosError(error)) {
        setModalVisible(true);
        setErrorMessage(
          error.response?.data.errorMessage || error.response?.data.result_code
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout text="Register Card">
      <Container maxWidth="sm" sx={{ width: "100%", mt: 2, px: 0 }}>
        <div className="space-y-4" style={{ perspective: "5000px" }}>
          <AnimationWrapper
            $isFlipped={isFlipped}
            onClick={(e) => {
              e.stopPropagation();
              setIsFlipped(!isFlipped);
            }}
          >
            <CardFrontWrapper $isFlipped={isFlipped}>
              <Card
                cardNumber={getCardNumberForUI(cardNumbers)}
                expirationDate={getCardExpirationDateForUI(expirationDate)}
                isRegister
              />
            </CardFrontWrapper>

            <CardBackWrapper $isFlipped={isFlipped}>
              <CardBack cvc={CVC || "___"} />
            </CardBackWrapper>
          </AnimationWrapper>

          {/* 카드 회원 가입 정보 */}
          {!cardSignedUp && (
            <CardSignUp
              form={userForm}
              handleChange={handleUserChange}
              validState={userValidState}
            />
          )}

          {/* 카드 정보 */}
          <CardInfo
            cardNumbers={cardNumbers}
            expirationDate={expirationDates}
            CVC={CVC}
            handleChange={handleCardChange}
            handleChangeExpirationDate={handleChangeExpirationDate}
            setIsFlipped={setIsFlipped}
          />
          <CustomButton
            size="medium"
            onClick={registerCard}
            id="card.register"
          />
        </div>
      </Container>
      {isLoading && <LoadingSpinner overlap />}
      {<Modal modal>{errorMessage}</Modal>}
    </Layout>
  );
};
export default CardRegister;

const AnimationWrapper = styled("div")<{ $isFlipped: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  transform-style: "preserve-3d";
  transition: transform 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: ${(props) =>
    props.$isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
`;

const CardWrapper = styled("div")`
  width: 100%;
  position: absolute;
  transition: opacity 0.6s cubic-bezier(1, 0, 0, 1);
`;

const CardFrontWrapper = styled(CardWrapper)<{ $isFlipped: boolean }>`
  z-index: ${(props) => (props.$isFlipped ? 0 : 2)};
  opacity: ${(props) => (props.$isFlipped ? 0 : 1)};
`;

const CardBackWrapper = styled(CardWrapper)<{ $isFlipped: boolean }>`
  position: relative;
  z-index: ${(props) => (props.$isFlipped ? 2 : 0)};
  opacity: ${(props) => (props.$isFlipped ? 1 : 0)};
  transform: rotateY(180deg);
`;
