import { Box } from "@mui/material";
import Card from "components/card/Card";
import NewCard from "components/card/NewCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ForwardedRef, forwardRef, useState } from "react";
import { CardInterface } from "app/cardsSlice";
// import SettingsIcon from "@mui/icons-material/Settings";

const CAROUSEL_TRANSFORM = 12;

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
    partialVisibilityGutter: CAROUSEL_TRANSFORM * 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: CAROUSEL_TRANSFORM * 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: CAROUSEL_TRANSFORM * 2,
  },
};

interface CardsCarouselProps {
  cards: CardInterface[];
  setCurrentCardIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CardsCarousel = forwardRef(
  (
    { cards, setCurrentCardIndex }: CardsCarouselProps,
    ref: ForwardedRef<Carousel>
  ) => {
    const [additionalTransform, setAdditionalTransform] =
      useState(CAROUSEL_TRANSFORM);

    // const navigate = useNavigate();
    // const navigateToCardsList = () => {
    //   navigate("/card/list");
    // };

    return (
      <Box
        className="[&_.custom-dot-list-style] relative"
        sx={{
          "& .custom-dot-list-style": {
            display: cards.length ? "normal" : "none",
            position: "static",

            button: {
              marginX: "2px",
            },
          },
        }}
      >
        <Carousel
          ref={ref}
          draggable={false}
          additionalTransfrom={
            cards.length ? additionalTransform : -CAROUSEL_TRANSFORM
          }
          responsive={responsive}
          // centerMode
          partialVisible
          swipeable
          showDots
          ssr
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item"
          renderDotsOutside={true}
          afterChange={(_previousSlide, { currentSlide }) => {
            setCurrentCardIndex(currentSlide);
          }}
          beforeChange={(nextSlide, { totalItems }) => {
            if (nextSlide === totalItems - 1) {
              setAdditionalTransform(-CAROUSEL_TRANSFORM);
            } else {
              setAdditionalTransform(CAROUSEL_TRANSFORM);
            }
          }}
        >
          {cards.map((card) => (
            <Card
              key={card.iapCdno}
              cardNumber={card.iapCdno}
              isDisabled={card.iapCrdStcd === "02"}
              isMainCard={card.rpstCrdYn}
            />
          ))}
          <NewCard />
        </Carousel>

        {/* <Stack
        alignItems="end"
        sx={{ position: "absolute", bottom: -2, right: 16 }}
        onClick={navigateToCardsList}
      >
        <SettingsIcon fontSize="medium" sx={{ color: "grey" }} />
      </Stack> */}
      </Box>
    );
  }
);

export default CardsCarousel;
