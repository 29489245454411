import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import useInputs from "hooks/useInputs";
import useLanguages from "hooks/useLanguages";
import useValidate from "hooks/useValidate";
import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CardService from "services/cardService";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useMyInfoQuery } from "app/apiSlice";
import useModalSheet from "hooks/overlay/useModalSheet";
import { isAxiosError } from "axios";

const CardSignUp = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const [Modal, setModalVisible] = useModalSheet();
  const [errorMessage, setErrorMessage] = useState("");

  // const { name, email, birthDate } = useAppSelector((state) => state.user);
  const { data: user } = useMyInfoQuery();
  const { name, email, birthDate } = user || {};

  const initialForm = useMemo(
    () => ({
      name,
      email,
      cardBirthDate: birthDate ?? "",
      cardPassword: "",
    }),
    [name, email, birthDate]
  );

  const { form, onChange } = useInputs(initialForm);

  const [validState, validate] = useValidate({
    name: true,
    email: true,
    cardBirthDate: true,
    cardPassword: true,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validate(e);
    onChange(e);
  };

  const validateForm = () => {
    // check if all fields are filled
    if (!form.name) {
      alert(intl.formatMessage({ id: "signup.enterName" }));
      return false;
    }
    if (!form.email) {
      alert(intl.formatMessage({ id: "signup.enterEmail" }));
      return false;
    }
    if (!form.cardBirthDate) {
      alert(intl.formatMessage({ id: "booking.birthDate" }));
      return false;
    }
    if (!form.cardPassword) {
      alert(intl.formatMessage({ id: "signup.enterPassword" }));
      return false;
    }
    if (
      !(
        validState.name &&
        validState.email &&
        validState.cardBirthDate &&
        validState.cardPassword
      )
    ) {
      return false;
    }

    return true;
  };

  const handleSignUp = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await CardService.signUp(form);

      navigate("/card");
    } catch (error) {
      console.error(error);
      if (isAxiosError(error)) {
        setModalVisible(true);
        setErrorMessage(
          error.response?.data.errorMessage || error.response?.data.result_code
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout text="라차 카드 회원가입">
      <CardLayout sx={{ mt: 2 }}>
        <Stack sx={{ width: "100%" }} gap={1}>
          <TextField
            fullWidth
            name="name"
            label={intl.formatMessage({ id: "signup.name" })}
            value={form.name}
            onChange={handleChange}
            autoComplete="off"
            error={!validState.name}
            helperText={
              !validState.name
                ? intl.formatMessage({ id: "signup.enterName" })
                : undefined
            }
          />
          {/* 이메일 */}
          <TextField
            fullWidth
            name="email"
            label={intl.formatMessage({ id: "signup.email" })}
            value={form.email}
            onChange={handleChange}
            autoComplete="off"
            error={!validState.email}
            helperText={
              !validState.email
                ? intl.formatMessage({ id: "signup.emailInvalid" })
                : undefined
            }
          />
          {/* 생년월일 */}
          <TextField
            fullWidth
            name="cardBirthDate"
            label={
              intl.formatMessage({ id: "booking.dateOfBirth" }) + " (YYMMDD)"
            }
            value={form.cardBirthDate}
            onChange={handleChange}
            autoComplete="off"
            error={!validState.cardBirthDate}
            helperText={
              !validState.cardBirthDate
                ? intl.formatMessage({ id: "booking.invalidBirthDate" })
                : undefined
            }
          />
          {/* 카드 비밀번호 */}
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label={
              isKorean
                ? "카드 비밀번호 (6자리 숫자)"
                : "Card Password (6 digits number)"
            }
            name="cardPassword"
            value={form.cardPassword}
            onChange={handleChange}
            autoComplete="off"
            error={!validState.cardPassword}
            helperText={
              !validState.cardPassword
                ? intl.formatMessage({ id: "signup.passwordInvalid1" })
                : undefined
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <CustomButton id="signup.title" onClick={handleSignUp} />
      </CardLayout>
      {isLoading && <LoadingSpinner overlap />}
      {<Modal modal>{errorMessage}</Modal>}
    </Layout>
  );
};

export default CardSignUp;
