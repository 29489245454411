import { Typography, Stack, useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useLanguages from "hooks/useLanguages";
import { useEffect } from "react";
import { ProviderUtils } from "utils/providerUtils";
import useSaveAsImage from "hooks/useSaveAsImage";
import OrderMainContent from "../order-history-panel/OrderMainContent";
import { useAppDispatch } from "app/hooks";
import { deleteReservation } from "app/reservationSlice";
import UserService from "services/userService";
import { updatePoint } from "app/userSlice";

const CreditPaymentPage = () => {
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  // const orderId = searchParams.get("orderId")!;
  // const saveAsImage = useSaveAsImage(orderId);

  // 주문 상세 버튼 관련 data
  // const order = {
  //   orderId,
  //   orderStatus: "COMPLETE",
  // };
  // const moveToOrderDetail = () => {
  //   if (orderId) {
  //     navigate(`/order-details/${orderId}`, {
  //       state: { order },
  //       replace: true,
  //     });
  //     return;
  //   }
  //   moveToOrderList();
  // };

  const moveToOrderList = () => {
    navigate("/order-history");
  };

  const color = ProviderUtils.isGME ? "error" : "primary";

  useEffect(() => {
    // lotte card iframe이 있는 경우 닫기
    window.top?.closeLotteCompleteIframe?.();

    // reservationSlice delete
    dispatch(deleteReservation());

    // 결제 완료된 이후에 lacha point 최신화
    UserService.getPoint().then((point) => {
      dispatch(updatePoint(point));
    });
  }, [dispatch]);

  // [2024-02-15] 뒤로가기 버튼 대응 시도
  // const [locationKeys, setLocationKeys] = useState([]);
  // useEffect(() => {
  // const listenBackEvent = () => {
  // if (window.confirm("페이지를 벗어나면 사진이 사라집니다. 정말 페이지를 나가시겠습니까?")) {
  //     photoHandler.reshootingHandler();
  //     photoHandler.countResetHandler();
  // } else {
  //     navigate("/shoot");
  // }
  // alert("뒤로 가기 감지!!!");
  // navigate("/order-history");
  // };
  // const historyEvent = history.listen(({ action }) => {
  //   alert(`CreditPaymentPage: ${action}`);

  //   if (action === "POP") {
  //     // listenBackEvent();
  //     moveToOrderList();
  //   }
  // });
  // return historyEvent;
  // }, []);

  return (
    <Layout onBack={moveToOrderList}>
      <div className="flex min-h-[60dvh] w-full flex-col items-center justify-center">
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ width: "100%", my: 6, textAlign: "center" }}
        >
          <CheckCircleIcon color={color} sx={{ fontSize: 120 }} />
          <Typography variant="h5" color={color} sx={{ fontWeight: "bold" }}>
            {/* {intl.formatMessage({ id: "payment.complete" })} */}
            {isKorean ? "결제완료" : "Done"}
          </Typography>
          <Typography
            variant="h6"
            sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
          >
            {isKorean
              ? "티겟 구매가 정상적으로 완료되었습니다."
              : "Your ticket purchase successfully completed"}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          gap={1}
          sx={{ maxWidth: "320px", width: "100%" }}
        >
          {/* 주문 상세 버튼 */}
          {/* <CustomButton
            id="payment.orderDetailButton"
            color={color}
            variant="outlined"
            onClick={moveToOrderDetail}
            style={{
              backgroundColor: theme.palette.white.main,
              textTransform: "capitalize",
            }}
          /> */}

          {/* 주문 내역 목록 버튼 */}
          <CustomButton
            id="payment.orderListButton"
            color={color}
            variant="outlined"
            onClick={moveToOrderList}
            style={{
              backgroundColor: theme.palette.white.main,
              textTransform: "capitalize",
            }}
          />
          {/* {ProviderUtils.isHanacard && (
            <>
              <CustomButton
                color={color}
                variant="outlined"
                onClick={saveAsImage}
                style={{
                  backgroundColor: theme.palette.white.main,
                  textTransform: "capitalize",
                }}
              >
                {isKorean ? "내역 저장하기" : "Save as Image"}
              </CustomButton>
              <div style={{ position: "absolute", zIndex: -10000, top: 0 }}>
                <OrderMainContent />
              </div>
            </>
          )} */}
        </Stack>
      </div>
    </Layout>
  );
};

export default CreditPaymentPage;
