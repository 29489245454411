import { useEffect } from "react";
import { Card, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import LoginLinks from "./LoginLinks";
import LoginForm from "./LoginForm";
import useCheckIsLoginPage from "hooks/useCheckIsLoginPage";
import SocialLoginButton from "components/social_login/SocialLoginButton";
import { ProviderUtils } from "utils/providerUtils";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import LachaCardLogo from "assets/images/logo/lacha_card_logo.png";
import LachaCardImage from "assets/images/lacha_card_image.png";
import CardLogo from "components/card/CardLogo";

interface LoginContentProps {
  isModal: boolean;
  closeModal?: () => void;
}

const LoginContent = ({ isModal, closeModal }: LoginContentProps) => {
  const isLoginPage = useCheckIsLoginPage();

  useEffect(() => {
    // reset location.state when rendering
    window.history.replaceState({}, "");
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        mt: isLoginPage ? (ProviderUtils.isCard ? 2 : 9) : 0,
        width: "100%",
      }}
    >
      {!isLoginPage && (
        <h3 style={{ paddingInlineStart: 16, alignSelf: "start" }}>
          <FormattedMessage id="user.signIn" />
        </h3>
      )}

      <LoginContainer isLoginPage={isLoginPage}>
        {ProviderUtils.isCard && (
          // <TWCardLayout
          //   className={"mx-1 w-full select-none bg-[#4A494A] text-white"}
          // >
          <div className="relative mb-4 grid h-[calc((100vw_-_32px)/2.1)] max-h-44 min-h-32 w-full grid-rows-3">
            <CardLogo src={LachaCardLogo} alt="LACHA Logo" />
            <img
              src={LachaCardImage}
              alt="LACHA Card"
              className="absolute -bottom-5 -right-5 h-[120%]"
            />
          </div>
          // </TWCardLayout>
        )}
        {/* {ProviderUtils.isCard && (
          <div className="absolute bottom-40 left-1/2 z-0 grid h-[calc((100vw_-_32px)/2.1)] max-h-60 min-h-32 w-full -translate-x-1/3 grid-rows-3">
            <img src={LachaCardImage} alt="LACHA Card" />
          </div>
        )} */}
        <LoginForm closeModal={closeModal} />
        <Divider />
        {!ProviderUtils.isDollarProvider && (
          <div className="items-center justify-center space-y-2">
            {ProviderUtils.isMame && (
              <SocialLoginButton loginProvider="LINE" closeModal={closeModal} />
            )}
            <SocialLoginButton loginProvider="NAVER" closeModal={closeModal} />
            <SocialLoginButton loginProvider="KAKAO" closeModal={closeModal} />
          </div>
        )}
        <LoginLinks isModal={isModal} />
      </LoginContainer>
    </Stack>
  );
};

export default LoginContent;

const LoginContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== "isLoginPage",
})<{ isLoginPage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;

  a,
  label,
  input,
  input::placeholder {
    color: ${ProviderUtils.isCard ? "white !important" : "normal"};
  }

  .MuiInputBase-root {
    border: 1px solid white !important;
  }

  color: ${ProviderUtils.isCard ? "white !important" : "normal"};

  background-color: ${ProviderUtils.isCard ? "#4a494a" : "normal"};

  width: 100%;
  max-width: 400px;
  margin: ${(props) => (props.isLoginPage ? "16px auto" : "8px 0")};
  padding: 16px;
`;
