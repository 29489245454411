import { Skeleton } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import TransactionList from "./components/TransactionList";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CardContext } from "./CardPageLayout";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import CardService from "services/cardService";
import { fetchTransactions } from "app/cardsSlice";
import { FormattedMessage } from "react-intl";
import TWCardLayout from "pages/tailwind/TWCardLayout";

const RecentTransaction = () => {
  const navigate = useNavigate();
  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber } = currentCard || {};

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const transactionList = useAppSelector(
    (state) =>
      state.cards.find((card) => card.iapCdno === cardNumber)?.transactionList
  );

  const navigateToTransactions = () => {
    navigate(`/card/${cardNumber}/transactions`);
  };

  useEffect(() => {
    const getTransactionList = async () => {
      setIsLoading(true);
      try {
        const { data } = await CardService.getTransactionList(cardNumber);
        if (data) {
          dispatch(
            fetchTransactions({
              cardNumber,
              transactions: data.list,
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    // if (!transactionList?.length) {
    getTransactionList();
    // }
  }, [cardNumber, dispatch]);

  return (
    <TWCardLayout>
      <p className="mb-2 text-xl font-bold">
        <FormattedMessage id="card.recentTransactions" />
      </p>

      {isLoading ? (
        // <LoadingSpinner className="h-[80px]" />
        <div className="space-y-2">
          {Array.from(Array(4)).map((_, key) => (
            <Skeleton variant="rounded" width="100%" height={64} key={key} />
          ))}
        </div>
      ) : (
        <TransactionList transactions={transactionList?.slice(0, 5)} />
      )}

      <div className="h-2" />
      <CustomButton
        variant="outlined"
        size="medium"
        onClick={navigateToTransactions}
        id="card.more"
      />
    </TWCardLayout>
  );
};

export default RecentTransaction;
