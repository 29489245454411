import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PaymentService from "services/paymentService";
import { OrderType } from "types/orderType";
import { getSeatDetailsFromOrder } from "utils/formatUtils";
// import Arrow from "components/Arrow";

interface ReservationDetailsProps {
  reservationDetails: OrderType;
}
const ReservationDetails = ({
  reservationDetails: { orderId },
}: ReservationDetailsProps) => {
  const intl = useIntl();
  const formatDateTime = useFormatDateToLocaleString();
  const formatSeatDetails = useFormatSeatDetails();
  const [isLoading, setIsLoading] = useState(true);

  const [reservationDetails, setReservationDetails] =
    useState<OrderType | null>(null);

  useEffect(() => {
    const getReservationDetails = async () => {
      if (orderId) {
        const response = await PaymentService.reserveDetail(Number(orderId));
        setReservationDetails(response);
      }
    };

    getReservationDetails().then(() => setIsLoading(false));
  }, [orderId]);

  // const ticketDetails = useGetTicketDetails(reservationDetails);
  // const stationDetail = {
  //   departure: ticketDetails.departure,
  //   arrival: ticketDetails.arrival,
  //   startDate: ticketDetails.departureTime,
  //   endDate: ticketDetails.arrivalTime,
  // };

  return !isLoading && reservationDetails ? (
    <Stack gap={0.5} sx={{ width: "100%" }}>
      {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={2.5}
          sx={{ width: "100%", mt: 1 }}
        >
          <StationInfo status="DEPARTURE" detail={stationDetail} />
          <Arrow />
          <StationInfo status="ARRIVAL" detail={stationDetail} />
        </Stack>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "center" }}>
          {ticketDetails.duration}
        </Typography> */}
      <Grid container>
        <DetailGridItem
          id="orders.reserveNumber"
          text={reservationDetails.apiReserveNumber}
        />
        <DetailGridItem
          id="trainMain.dateOfDeparture"
          text={formatDateTime(reservationDetails.startDate, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        />
        <DetailGridItem
          id="orders.trainNumber"
          text={`${reservationDetails.trainList[0].trainType} ${parseInt(
            reservationDetails.trainList[0].trainCode
          )}`}
        />
        <DetailGridItem
          id="booking.passengers"
          text={intl.formatMessage(
            { id: "booking.passengersDetail" },
            {
              total:
                reservationDetails.adultCount +
                reservationDetails.childrenCount,
              adults: reservationDetails.adultCount,
              children: reservationDetails.childrenCount,
            }
          )}
        />
        <DetailGridItem
          id="booking.seats"
          text={formatSeatDetails(getSeatDetailsFromOrder(reservationDetails))}
        />
      </Grid>
    </Stack>
  ) : (
    <Skeleton variant="rounded" className="w-full" height={100} />
  );
};

export default ReservationDetails;

const DetailGridItem = ({ id, text }: { id: string; text?: string }) => {
  return (
    <Grid
      item
      xs={12}
      gap={2}
      display="flex"
      justifyContent="space-between"
      sx={{
        "& *": {
          wordBreak: "keep-all",
        },
      }}
    >
      <Typography variant="caption" color="text.secondary">
        <FormattedMessage id={id} />:
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ textAlign: "end" }}
      >
        {text}
      </Typography>
    </Grid>
  );
};
