import { useCallback, useEffect } from "react";
import MenuOptions from "./sections/MenuOptions";
import Layout from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import LogoutButton from "components/button/LogoutButton";
import history from "history/browser";
import TrainTicket from "components/reservation/OrderThumbnail";
import PersonalInfoSettingSection from "./sections/PersonalInfoSettingSection";
import { useAppSelector } from "app/hooks";
import MyPointsSection from "./sections/MyPointsSection";
import { ProviderUtils } from "utils/providerUtils";
import OrderSkeleton from "components/skeleton/OrderSkeleton";
import { Divider } from "@mui/material";
import SectionHeaders from "./components/SectionHeaders";
import useFetchOrderInfo from "hooks/orderInfos/useFetchOrderInfo";

const NUMBER_OF_RECENT_ORDERS = 4;

const PersonalInfoPage = () => {
  const { recentOrders } = useAppSelector((state) => state.myOrders);

  const intl = useIntl();
  const navigate = useNavigate();
  const [isFetching, fetchOrderInfos] = useFetchOrderInfo();

  // actions
  // const fetchUserInfo = useCallback(async () => {
  //   try {
  //     const userInfo = await UserService.getMyInfo();
  //     dispatch(fetchUser(userInfo));
  //   } catch (error) {
  //     console.error("Failed to fetch user info:", error);
  //   }
  // }, [dispatch]);

  const backToMain = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  const moveToOrderHistory = () => {
    navigate("/order-history");
  };
  useEffect(() => {
    fetchOrderInfos();

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        backToMain();
      }
    });
    return historyEvent;
  }, [backToMain, fetchOrderInfos]);

  return (
    <Layout
      onBack={backToMain}
      text={intl.formatMessage({ id: "user.myInfo" })}
    >
      <section className="space-y-4">
        <PersonalInfoSettingSection />
        <MyPointsSection />
        {/* {ProviderUtils.provider?.provider === "lottetest" && (
          <TravelPackCard text={"트래블팩 구독하고 1천원 적립금 받아요"} />
        )} */}

        <Divider />

        {/* 최근 주문내역 */}
        <div className="space-y-2">
          <SectionHeaders
            header={<FormattedMessage id="user.recentOrders" />}
            handleClick={moveToOrderHistory}
            buttonText={<FormattedMessage id="user.more" />}
          />
          <div className="space-y-2">
            {isFetching ? (
              <OrderSkeleton number={NUMBER_OF_RECENT_ORDERS} />
            ) : (
              recentOrders
                .filter((order) => order.orderStatus !== "PENDING")
                .splice(0, NUMBER_OF_RECENT_ORDERS)
                .map((order, index) => (
                  <TrainTicket key={index} order={order} />
                ))
            )}
          </div>
        </div>

        <MenuOptions />
        {ProviderUtils.loginAvailable && <LogoutButton />}
      </section>
    </Layout>
  );
};

export default PersonalInfoPage;
