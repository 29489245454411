import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import { CancelFeeCheckResponse } from "services/paymentService";
import CardLayout from "components/layout/CardLayout";

const CancelInfoSummarySection = ({
  cancelFee,
}: {
  cancelFee: CancelFeeCheckResponse;
}) => {
  const formatPrice = useFormatPrice();

  const getHeader = () => {
    return (
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        <FormattedMessage id="orders.cancelEstimation" />
      </Typography>
    );
  };

  const refundFee = cancelFee.cancelFee; // 취소 수수료
  const realPaymentCancelAmount = cancelFee.realPaymentCancelAmount; // 총 환불 가격(수수료 제외)
  const mileageCancelAmount = cancelFee.mileageCancelAmount; // 라차 포인트 사용액

  // 수수료 발생하는 경우 적립금에서 먼저 차감
  // const refundedLachaPoint =
  // usedLachaPoint > refundFee ? usedLachaPoint - refundFee : 0; // 적립금 환불 금액
  // const refundFeeLeft =
  // usedLachaPoint > refundFee ? 0 : refundFee - usedLachaPoint; // 수수료가 사용 적립금보다 많은 경우 현금 환불 금액에서 차감할 금액
  // const refundedPrice = originalPrice - usedLachaPoint - refundFeeLeft; // 현금 환불

  return (
    <CardLayout
      header={getHeader()}
      sx={{ border: "none", boxShadow: "none", p: 0, width: "100%" }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body2">
            <FormattedMessage id="orders.cancelAmount" />:
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {/* 현금 환불 */}
            {formatPrice(realPaymentCancelAmount)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            <FormattedMessage id="point.refundPoint" />:
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {/* 라차 포인트 환불 */}
            {formatPrice(mileageCancelAmount)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">
            <FormattedMessage id="orders.cancelFee" />:
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Typography variant="body2">{formatPrice(refundFee)}</Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default CancelInfoSummarySection;
