import { Tab, Tabs, useTheme } from "@mui/material";
import { HEADER_HEIGHT } from "const/layout";
import { TabType } from "../Activity";

interface ActivityDetailTabProps {
  tabValue: TabType;
  handleTabChange: (event: React.SyntheticEvent, newValue: TabType) => void;
  tabLabels: TabType[];
}

const ActivityDetailTab = ({
  tabValue,
  handleTabChange,
  tabLabels,
}: ActivityDetailTabProps) => {
  const theme = useTheme();

  return (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      sx={{
        position: "sticky",
        top: `${HEADER_HEIGHT}px`,
        left: 0,
        bgcolor: theme.palette.white.main,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
      }}
      className="max-w-lg rounded-lg"
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          value={label}
          label={label}
          sx={{ fontWeight: "bold" }}
        />
      ))}
    </Tabs>
  );
};

export default ActivityDetailTab;
