import Layout from "components/layout/Layout";
import React, { memo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActivityFilter, Criteria, TravelGood } from "services/travelService";
import ActivityThumbnail from "./components/ActivityThumbnail";
import ThumbnailSkeleton from "./components/skeleton/ThumbnailSkeleton";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { getURLSearchParams } from "utils/urlSearchParamsUtils";
import { SelectChangeEvent } from "@material-ui/core";
import { useGetActivityListQuery } from "app/apiSlice";

const categoryTitleMap: Record<string, string> = {
  jr: "JR 서일본 열차패스",
  usj: "유니버셜 스튜디오 재팬",
  bus: "공항 리무진 버스",

  // 지역별
  tokyo: "도쿄",
  // osaka: "오사카",
  kansai: "오사카/교토/나라(관서)",
  hokkaido: "홋카이도",
  okinawa: "오키나와",
  kyushu: "큐슈",
};

const CategoryPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const debounce = useDebounce(700);
  const [page, setPage] = useState(1);

  const params = getURLSearchParams();
  const keyword = params.get("keyword");
  const filter = params.get("filter") as ActivityFilter;
  const criteria: Criteria = {
    category: category === "kansai" ? "osaka" : category,
    page: page - 1,
    // sortType: "reviewCount",
    // sort: "desc",
  };
  // RTK에서 debounce 적용하기 위해 debouncedKeyword 사용
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);

  if (filter) {
    criteria.sortType = filter;
  }

  if (debouncedKeyword) {
    criteria.keyword = debouncedKeyword;
  }

  const {
    data: travelGoods,
    // isLoading,
    isFetching,
  } = useGetActivityListQuery(criteria, {
    refetchOnMountOrArgChange: 3600, // 캐시 유지 시간을 1시간으로 설정
  });

  const handleFilter = (e: SelectChangeEvent<ActivityFilter>) => {
    const filter = e.target.value;

    if (filter) {
      params.set("filter", filter);
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    if (!keyword) {
      params.delete("keyword");
      navigate(`?${params.toString()}`);
      return;
    }

    params.set("keyword", keyword);
    navigate(`?${params.toString()}`, { replace: true });

    debounce(() => setDebouncedKeyword(keyword));
  };

  return (
    typeof category === "string" && (
      <Layout
        text={categoryTitleMap[category]}
        onBack={() => navigate("/activity", { replace: true })}
      >
        <section className="grid grid-cols-12 gap-2 py-4">
          {/* 검색창 */}
          {/* <div className="flex w-full items-center justify-between gap-2"> */}
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Search"
            className="col-span-8 rounded-lg bg-white"
            value={params.get("keyword") || ""}
            onChange={handleSearch}
          />
          {/* <CustomButton
              id="stationSelectionPopup.searchButtonText"
              size="medium"
              // disabled={!searchTerm}
              className="!w-40 font-medium"
            /> */}
          {/* </div> */}

          {/* 필터 */}
          <FormControl size="small" className="col-span-4 self-end">
            {/* <InputLabel id="demo-select-small-label">필터</InputLabel> */}
            <Select
              id="demo-select-small"
              value={filter || "default"}
              defaultValue={"default"}
              onChange={handleFilter}
              className="bg-white"
            >
              <MenuItem value="default" disabled>
                필터
              </MenuItem>
              <MenuItem value="rating">평점순</MenuItem>
              <MenuItem value="reviewCount">리뷰순</MenuItem>
              <MenuItem value="sellCount">판매량순</MenuItem>
              {/* <MenuItem value="price-low">낮은가격순</MenuItem> */}
            </Select>
          </FormControl>
        </section>

        <div className="flex min-h-[80vh] flex-col justify-between">
          <section className="grid grid-cols-1 gap-3">
            {isFetching ? (
              <ThumbnailSkeleton direction="row" />
            ) : travelGoods?.content.length === 0 ? (
              <div className="m-auto flex flex-col items-center space-y-4 text-text-secondary">
                <ProductionQuantityLimitsIcon className="mt-20 !text-[80px]" />
                <h2>등록된 상품이 없습니다.</h2>
              </div>
            ) : (
              travelGoods?.content.map((good, key) => (
                <ActivityThumbnail
                  activity={good}
                  direction="row"
                  key={`${good.goodsId}-${key}`}
                />
              ))
            )}
          </section>

          <div className="mt-4 flex w-full justify-center">
            <Pagination
              count={travelGoods?.totalPages || 1}
              shape="rounded"
              color="primary"
              page={page}
              onChange={(_e, value) => setPage(value)}
            />
          </div>
        </div>
      </Layout>
    )
  );
};

export default memo(CategoryPage);
