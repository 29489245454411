import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useContext } from "react";
import { ActivityReservationContext } from "context/ActivityContextx";
import clsx from "clsx";

interface PassengerCountProps {
  max: number;
}

const PassengerCount = ({ max }: PassengerCountProps) => {
  const { number, setNumber, setPassengerDetails } = useContext(
    ActivityReservationContext
  );

  const incrementNumber = () => {
    const newNumber = max > 0 ? Math.min(number + 1, max) : number + 1;

    setNumber(newNumber);
    // setProductList([]);
    // setOptionShow(false); // 총 인원 수 바뀌는 경우 옵션 가리기

    setPassengerDetails((prev) => [
      ...prev,
      ...Array(newNumber - number).fill({ name: "", email: "" }),
    ]);
  };

  const decrementNumber = () => {
    const newNumber = Math.max(1, number - 1);

    setNumber(newNumber); // 최소 인원 1
    // setProductList([]);
    // setOptionShow(false); // 총 인원 수 바뀌는 경우 옵션 가리기
    setPassengerDetails((prev) => prev.slice(0, newNumber));
  };

  return (
    <div className={clsx("flex justify-between px-4")}>
      <p className="text-lg font-bold">인원 수</p>
      <div className="flex items-center gap-2">
        <IndeterminateCheckBoxIcon
          color="primary"
          fontSize="large"
          onClick={decrementNumber}
        />
        <p className="text-lg font-bold">{number}</p>
        <AddBoxIcon
          color="primary"
          fontSize="large"
          onClick={incrementNumber}
        />
      </div>
    </div>
  );
};

export default PassengerCount;
