import axios, { AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";
import { NumericString } from "types/numericString";
import { OrderType } from "types/orderType";
import { withPaging } from "types/utilityTypes";

export interface TravelGood {
  goodsId: number;
  apiProvider: string;
  apiProductId: string;
  goodsName: string;
  description: string;
  including: string;
  notIncluding: string;
  usage: string;
  refundNotice: string;
  treeId: number;
  thumbnailPath: string;
  imagePathList: string[];
  isOnlinePay: number;
  isPackage: number;
  siteName: string;
  siteAddress: string;
  settlementPrice: number;
  salePrice: number;
  marketPrice: number;
  startMinute: number;
  payMinute: number;
  rating: number;
  maxTicketAmount: number; // 최대 인원 수, 0이면 무제한
  content: string;
  requiredFields: string;
  requiredFields2: string;
  chargeInclude: string;
  chargeNoInclude: string;
  refundNote: string;
  userNote: string;
  importantNote: string;
  verifyDetailedType: number;
  isRequireEveryone: 0 | 1; // 1인 경우에 예약 시 탑승객 정보(이름, 이메일) 필요
  //
  isAutoConfirm: boolean; // 즉시 확정 여부
  isRefundable: boolean; // 결제 후 취소 가능 여부
  verifyType: 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0: SMS, 1: e-티켓, 3: 신분증, 4: 실물 티켓, 5: 사본, 6: 학생증
  lang: "ko-KR" | "en-US" | "zh-HK" | "zh-CN" | "ja-JP";
}

// interface GoodSeat {
//   marketPrice: number;
//   productNo: number; // 정상가
//   salePrice: number; // 할인 판매가
//   seats: null;
//   settlementPrice: number; // 매입가
//   travelDate: string; // YYYY-MM-DD
// }

export interface GoodCalendarDetail {
  [date: string]: {
    [optionCode: string]: number;
  };
}

export interface GoodOptionDetail {
  startTime: string;
  endTime: string;
  name: string;
  optionCode: NumericString;
  seats: number;
}

export interface GoodOption {
  tgiId: number;
  apiProductId: string;
  goodsId: number;
  itemName: string;
  date: string; // YYYY-MM-DD
  price: number;
  stock: number;
  priceList: null;
  optionList: GoodOptionDetail[];
}

export interface TravelReservationProduct {
  details: [
    {
      cond: string; // YYYY-MM-DD - 일단은 travelDate랑 동일 값
      num: number; // 인원 수
    },
  ];
  tgiId: number; // jtr 상품 항목 번호
  num: number; // 인원 수
  price: number; // 가격
  seat?: string; // 옵션 번호
  passengers?: {
    linkAddress: string; // 탑승자 이메일
    linkMan: string; // 탑승자 이름
  }[];
}

export interface TravelReservationData {
  userName: string;
  userEmail: string;
  userPhone: string;
  travelDate: string; // YYYY-MM-DD
  lang: string; // ko-KR, en-US, zh-CN, zh-HK, ja-JP
  productList: TravelReservationProduct[];
}

const travelApi = axios.create({
  baseURL: apiBaseUrl + "/goods",
});

// 요청 인터셉터 추가
travelApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
travelApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

export type ActivityFilter = "rating" | "reviewCount" | "sellCount" | "default";

export interface Criteria {
  category?: string;
  size?: number;
  page?: number;
  // sort?: "asc" | "desc";
  sortType?: ActivityFilter;
  keyword?: string;
}

class TravelService {
  // 여행상품 리스트
  static async getGoods(criteria?: Criteria) {
    const response = await travelApi.get<withPaging<TravelGood>>(
      `/list/${criteria?.category}`,
      {
        params: { size: 10, ...criteria },
      }
    );

    // const response: AxiosResponse<TravelGood[]> = await travelApi.get(`/list/${category}`);
    return response;
  }

  // 여행 상품 디테일
  static async getGoodDetail(goodsId: number | string) {
    const response: AxiosResponse<TravelGood> = await travelApi.get(
      "/moreDetail",
      {
        params: {
          goodsId: Number(goodsId),
        },
      }
    );

    return response;
  }

  // 특정 날짜의 상품 옵션
  static async getGoodOption(goodsId: number | string, startDate: string) {
    const response: AxiosResponse<GoodOption[]> = await travelApi.get(
      "/dailyOptions",
      {
        params: {
          goodsId,
          startDate,
        },
      }
    );

    return response;
  }

  // 월별 선택 가능 일정 및 가격 정보
  static async getCalendar(goodsId: number | string, startDate: string) {
    const response: AxiosResponse<GoodCalendarDetail> = await travelApi.get(
      "/dailyPrices",
      {
        params: {
          goodsId,
          startDate,
        },
      }
    );

    return response;
  }

  // 여행 상품 주문 생성 (결제 대기 상태)
  static async makeTravelReservation(data: TravelReservationData) {
    const response = await travelApi.post<{
      orderIdList: number[];
      errorMessage: string;
    }>("/order/make", data, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
    });

    return response.data;
  }

  // 여행 상품 주문 상세
  static async getTravelReservation(orderId: string | number) {
    try {
      const response = await travelApi.get<OrderType>("/order/info", {
        params: { orderId },
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
      });

      return response.data;
    } catch {
      const response = await travelApi.get<OrderType>("/order/airInfo", {
        params: { orderId },
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
      });

      return response.data;
    }
  }
}

export default TravelService;
