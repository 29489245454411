import CategorySelection from "./CategorySelection";
import CitySelection from "./CitySelection";
import DateSelection from "./DateSelection";
import TagsSelction from "./TagsSelction";
import { TravelFilterOptions } from "./TravelList";

interface TravelFilterPageProps {
  currentFilterOption: TravelFilterOptions;
}

const TravelFilterPage = ({ currentFilterOption }: TravelFilterPageProps) => {
  switch (currentFilterOption) {
    case "City":
      return <CitySelection />;
    case "Dates":
      return <DateSelection />;
    case "Category":
      return <CategorySelection />;
    case "tags":
      return <TagsSelction />;
    default:
      const _exhaustiveCheck: never = currentFilterOption;
      return _exhaustiveCheck;
  }
};

export default TravelFilterPage;
