import { Chip, Skeleton } from "@mui/material";
import clsx from "clsx";
import useFormatPrice from "hooks/useFormatPrice";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateTWClasses } from "utils/tailwindUtils";
import BookmarkIcon from "@mui/icons-material/Bookmark";

export interface ActivityThumbnailType {
  goodsId: number;
  goodsName: string;
  thumbnailPath: string;
  salePrice: number;
}

interface ActivityThumbnailProps {
  activity: ActivityThumbnailType;
  direction: "row" | "column";
}

const ActivityThumbnail = ({ activity, direction }: ActivityThumbnailProps) => {
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  return (
    <TWCardLayout className="p-0 active:scale-[1.02]">
      <section
        className={clsx("w-full", direction === "row" && "grid grid-cols-12")}
        onClick={() => navigate(`/activity/${activity.goodsId}`)}
      >
        <section
          className={clsx(
            "relative col-span-5 flex h-[120px] items-center justify-center overflow-hidden",
            {
              "max-h-32": direction === "column",
              "max-h-[120px] min-h-28": direction === "row",
            }
          )}
        >
          <ThumbnailImage
            src={activity.thumbnailPath}
            alt={activity.goodsName}
            direction={direction}
          />
          {/* {direction === "column" && <Mark direction={direction} />} */}
          <LowestPriceBadge direction={direction} />
        </section>

        <section
          className={clsx(
            "col-span-7 flex h-full w-full flex-col justify-between gap-2 p-2 pl-3 text-start",
            direction === "row" && "pr-4"
          )}
        >
          <h6
            className="overflow-hidden text-ellipsis break-normal text-[14px] font-bold tracking-tight"
            style={{
              display: "-webkit-box" /* Required for line-clamp */,
              WebkitBoxOrient: "vertical" /* Required for line-clamp */,
              WebkitLineClamp: 3 /* Set the maximum number of lines to 2 */,

              /* Both of the following are required for text-overflow */
              // textOverflow: "ellipsis",
              // overflow: "hidden",
            }}
          >
            {activity.goodsName}
          </h6>

          {activity.salePrice && (
            <p
              className={clsx(
                "self-end break-keep pr-1 text-sm font-bold text-text-secondary",
                generateTWClasses("text", "primary")
              )}
            >
              {formatPrice(activity.salePrice)} ~
            </p>
          )}
        </section>
      </section>
    </TWCardLayout>
  );
};

export default ActivityThumbnail;

interface ThumbnailImageProps {
  src: string;
  alt: string;
  direction: "row" | "column";
}

const ThumbnailImage = ({ src, alt, direction }: ThumbnailImageProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={clsx(`absolute !h-full w-full`, !isLoading && "opacity-0")}
      />
      <img
        src={src}
        alt={alt}
        // style={{
        //   backgroundSize: "fill",
        //   backgroundPosition: "center",
        // }}
        className={clsx("absolute h-full w-full opacity-0", {
          "rounded-l-lg": direction === "row",
          "rounded-t-lg": direction === "column",
          "opacity-100": !isLoading,
        })}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

interface MarkProps {
  direction: "row" | "column";
}

const LowestPriceBadge = ({ direction }: MarkProps) => {
  return (
    <div
      className="absolute -right-1 bottom-1 z-50"
      //  className="absolute -left-3 -top-[14px] z-50"
    >
      {/* <BookmarkIcon className="!text-[80px] text-orange-300" /> */}
      <Chip
        label="국내 최저가"
        size="small"
        className="mr-2 !rounded-md !bg-[#FFDBC3] px-0 py-3 font-bold !text-[#FF4C18]"
      />
      {/* <p className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-60%] break-keep rounded-lg bg-[#FFDBC3] p-1 text-center text-xs font-bold leading-4 text-[#FF4C18]">
        국내 최저가
      </p> */}
    </div>
  );
};
