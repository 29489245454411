import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { Stack } from "@mui/material";

interface StarRatesProps {
  rate?: number;
  size?: "small" | "medium" | "large";
}

/**
 * A component that displays a given rating in a 5-star rating system.
 * The rating is displayed as a combination of full and half stars,
 * with empty stars filling the remaining space.
 *
 * @param {number} rate - The rating to display (0.0 - 5.0).
 * @returns A JSX element representing the rating.
 */
const StarRates = ({ rate = 0, size = "medium" }: StarRatesProps) => {
  // Calculate the number of full and half stars needed to represent the rating
  const fullStars = Math.floor(rate);
  const halfStars = Math.round(rate - fullStars);

  // Create an array of the needed stars
  const stars = [
    ...Array.from({ length: fullStars }, (_, index) => (
      <StarIcon key={`full-${index}`} fontSize={size} color="warning" />
    )),
    ...Array.from({ length: halfStars }, (_, index) => (
      <StarHalfIcon key={`half-${index}`} fontSize={size} color="warning" />
    )),
    ...Array.from({ length: 5 - fullStars - halfStars }, (_, index) => (
      <StarBorderIcon key={`empty-${index}`} fontSize={size} color="warning" />
    )),
  ];

  return <Stack direction="row">{stars}</Stack>;
};

export default StarRates;
