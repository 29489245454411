export const maskUserName = (userName: string) => {
  const len = userName.length;

  if (len < 3) {
    const masked = userName.slice(0, len - 1) + "*";
    return masked;
  }
  if (len < 5) {
    const masked = Array(len - 2)
      .fill("*")
      .join("");
    return `${userName[0]}${masked}${userName[len - 1]}`;
  }

  const masked = Array(3).fill("*").join("");
  return `${userName.slice(0, len - 3)}${masked}`;
};
