import { Divider } from "@mui/material";
import clsx from "clsx";
import CustomButton from "components/button/CustomButton";
import { ActivityReservationContext } from "context/ActivityContextx";
import useFormatPrice from "hooks/useFormatPrice";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import { memo, useContext } from "react";
import {
  GoodOption,
  GoodOptionDetail,
  TravelReservationProduct,
} from "services/travelService";
import { generateTWClasses } from "utils/tailwindUtils";

// 상품 옵션 e.g. 1일권, 익스프렉스 패스, ...
interface ActivityOptionProps {
  productOption: GoodOption;
}

const ActivityOption = ({ productOption }: ActivityOptionProps) => {
  const formatPrice = useFormatPrice();
  const { number, productList, setProductList } = useContext(
    ActivityReservationContext
  );

  const optionShow = productOption.tgiId === productList[0]?.tgiId;
  const hasOptionList = productOption.optionList.length > 0;

  const handleSelectOption = () => {
    if (optionShow) {
      setProductList([]);
    } else {
      const newProductElement: TravelReservationProduct = {
        details: [{ cond: productOption.date, num: number }],
        tgiId: productOption.tgiId,
        num: number,
        price: number * productOption.price,
      };

      setProductList(() => [newProductElement]);
    }
    // }
  };

  return (
    <TWCardLayout
      className={clsx(
        "px-3 py-2",
        productOption.stock === 0 && "hidden !text-gray-500",
        productOption.stock < number && "hidden !text-gray-500",

        optionShow && generateTWClasses("bg", "primary-light")
        // "bg-primary-light opacity-90 hana:bg-hana-primary-light lotte:bg-lotte-primary-light bc:bg-bc-primary-light gme:bg-gme-primary-light redtable:bg-redtable-primary-light":
      )}
    >
      <div className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-8">
          <p className="text-md col-span-5 break-keep font-bold">
            {productOption.itemName}
          </p>
          <p className="text-md col-span-3 text-end font-bold">
            {formatPrice(productOption.price)}
          </p>
        </div>
        <div className="flex items-end justify-end gap-2">
          <CustomButton
            variant={optionShow ? "contained" : "outlined"}
            size="small"
            className="max-w-24 p-0"
            onClick={handleSelectOption}
            disabled={productOption.stock === 0}
          >
            {optionShow
              ? "선텍 해제"
              : hasOptionList
                ? "시간 선택"
                : "옵션 선택"}
          </CustomButton>
        </div>

        {/* Product options */}
        <div
          className={clsx("space-y-2", {
            hidden: !hasOptionList || !optionShow,
          })}
        >
          <Divider />

          {productOption.optionList
            .filter((option) => option.seats >= number)
            .map((optionDetail) => (
              <ActivityOptionDetail
                key={optionDetail.optionCode}
                optionDetail={optionDetail}
                option={productOption}
              />
            ))}
        </div>
      </div>
    </TWCardLayout>
  );
};

export default memo(ActivityOption);

interface ActivityOptionDetailProps {
  option: GoodOption;
  optionDetail: GoodOptionDetail;
}

const ActivityOptionDetail = ({
  option,
  optionDetail,
}: ActivityOptionDetailProps) => {
  const { number, productList, setProductList } = useContext(
    ActivityReservationContext
  );

  const isSelected = productList[0]?.seat === optionDetail.optionCode;

  const selectOption = () => {
    const newProductElement: TravelReservationProduct = {
      details: [{ cond: option.date, num: number }],
      tgiId: option.tgiId,
      num: number,
      price: number * option.price,
      seat: optionDetail.optionCode,
    };

    setProductList(() => [newProductElement]);
  };

  return (
    <div onClick={selectOption}>
      <TWCardLayout
        className={clsx("flex items-center gap-2", {
          "bg-primary-light opacity-90 hana:bg-hana-primary-light": isSelected,
        })}
      >
        <p className="text-sm font-bold">{optionDetail.name}</p>
        <Divider />
        <div className="flex items-center justify-between gap-2">
          <p className="w-full text-end font-bold text-text-secondary">
            재고: {optionDetail.seats}
          </p>
        </div>
      </TWCardLayout>
    </div>
  );
};
