import { useState } from "react";
import CustomButton from "../button/CustomButton";
import { LanguageCodeType } from "const/languageCodes";
import NaverLogo from "assets/images/social_login/naver_logo.png";
import KakaoLogo from "assets/images/social_login/kakao_logo.jpeg";
import LineLogo from "assets/images/social_login/line_logo.png";
import { Grid, Stack, Box } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import { StorageUtils } from "utils/storageUtils";
import { useIntl } from "react-intl";
import RecentLoginIcon from "components/login/RecentLoginIcon";
import { getURIVariable } from "utils/socialLoginUtils";

/* 
  naver: #03C75A   #FFFFFF
  kakao: #FEE500 	#000000 85%
*/

// Webview에서 google login 동작 X
// Google (using URL and URLSearchParams)
// const googleQuery = {
//   client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
//   redirect_uri: `https://${urlSubdomain}${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`,
//   response_type: "code",
//   scope:
//     "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
// };
// const uri = new URL("https://accounts.google.com/o/oauth2/v2/auth");
// uri.search = new URLSearchParams(googleQuery).toString();
// const GOOGLE_REDIRECT_URI = uri.toString();

export type SocialLoginProvider =
  | "NAVER"
  | "KAKAO"
  //  | "Google"
  | "LINE";

const getBackgroundColor = (loginProvider: SocialLoginProvider) => {
  switch (loginProvider) {
    case "NAVER":
      return "#03C75A";
    case "KAKAO":
      return "#FEE500";
    case "LINE":
      return "#06C755";
    // case "Google":
    //   return "#F2F2F2";
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }
};

const getTextColor = (loginProvider: SocialLoginProvider) => {
  switch (loginProvider) {
    case "NAVER":
    case "LINE":
      return "#FFFFFF";
    case "KAKAO":
      // case "Google":
      return "#000000D9";
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }
};

const getButtonText = (
  loginProvider: SocialLoginProvider,
  language: LanguageCodeType = "ko"
) => {
  switch (loginProvider) {
    case "NAVER":
      return language === "ko" ? "네이버 로그인" : "Login with Naver";
    case "KAKAO":
      return language === "ko" ? "카카오 로그인" : "Login with Kakao";
    case "LINE":
      return language === "ja"
        ? "LINEでログイン"
        : language === "ko"
          ? "LINE으로 로그인"
          : "Login with Line";
    // case "Google":
    //   return language === "ko" ? "구글 로그인" : "Login with Google";
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }
};

const getLogo = (loginProvider: SocialLoginProvider) => {
  switch (loginProvider) {
    case "NAVER":
      return NaverLogo;
    case "KAKAO":
      return KakaoLogo;
    case "LINE":
      return LineLogo;
    // case "Google":
    //   return GoogleLogo;
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }
};

const getLogoHeight = (loginProvider: SocialLoginProvider) => {
  switch (loginProvider) {
    case "NAVER":
      return 40;
    case "KAKAO":
      return 34;
    case "LINE":
      return 30;
    // case "Google":
    //   return 36;
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }
};

// TODO: provider, loginProvider별로 test code 작성하기 - ProviderUtils refactoring 했을 때 처럼 에러 생기지 않게
const getLoginUri = (loginProvider: SocialLoginProvider, state?: string) => {
  // urlSubdomain에 명시적으로 type annotation이 없어서 ProviderUtils을 refactoring 한 이후에 ProviderUtils.provider가 Domain type입에도 불구하고 compile error가 발생하지 않음
  // 기존 코드: const urlSubdomain = ProviderUtils.provider;
  // 이후 url string을 만들 때 'https://[object Object]...'으로 문제없이 compile 되지만 social login 기능엔 문제 발생
  // ->
  // 해결 방안:
  // urlSubdomain에 string | undefined type을 명시적으로 선언하여 ProviderUtils에서 subdomain string을 받아오지 않은 경우 compile error 발생시키기
  // getLoginUri 함수에 대한 test code 작성하여 통과한 후에만 배포 가능하도록 설정하기

  const [CLIENT_ID, REDIRECT_URI] = getURIVariable(loginProvider);
  switch (loginProvider) {
    case "NAVER":
      return `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${state}`;
    case "KAKAO":
      return `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
    case "LINE":
      return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=profile%20openid%20email&state=${state}`;
    default:
      const _exhaustiveCheck: never = loginProvider;
      return _exhaustiveCheck;
  }

  /*   // Naver
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const NAVER_REDIRECT_URI = `https://${
    urlSubdomain
      ? `${urlSubdomain}.${process.env.REACT_APP_NAVER_REDIRECT_URI_PROVIDER}`
      : process.env.REACT_APP_NAVER_REDIRECT_URI_DEFAULT
  }`;
  const NAVER_LOGIN_URI = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&redirect_uri=${NAVER_REDIRECT_URI}`;

  // Kakao
  const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
  const KAKAO_REDIRECT_URI = `https://${
    urlSubdomain
      ? `${urlSubdomain}.${process.env.REACT_APP_KAKAO_REDIRECT_URI_PROVIDER}`
      : process.env.REACT_APP_KAKAO_REDIRECT_URI_DEFAULT
  }`;
  const KAKAO_LOGIN_URI = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;

  // Line
  const LINE_CLIENT_ID = process.env.REACT_APP_LINE_CHANNEL_ID;
  const LINE_REDIRECT_URI = `https://${
    urlSubdomain
      ? `${urlSubdomain}.${process.env.REACT_APP_LINE_REDIRECT_URI_PROVIDER}`
      : process.env.REACT_APP_LINE_REDIRECT_URI_DEFAULT
  }`;
  const LINE_LOGIN_URI = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CLIENT_ID}&redirect_uri=${LINE_REDIRECT_URI}&scope=profile%20openid%20email`; */
};

interface SocialLoginButtonProps {
  loginProvider: SocialLoginProvider;
  closeModal?: () => void;
}

const SocialLoginButton = ({
  loginProvider,
  closeModal,
}: SocialLoginButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useIntl() as { locale: LanguageCodeType };

  const isRecentLoginMethod =
    loginProvider === StorageUtils.RecentLoginMethod.get();

  const handleClick = () => {
    setIsLoading(true);
    StorageUtils.IsLoginModal.set(Boolean(closeModal));
    const state = crypto.getRandomValues(new Uint16Array(1)).join("");
    // console.log(getLoginUri(loginProvider, state));
    window.location.href = getLoginUri(loginProvider, state);
    setIsLoading(false);
  };

  return (
    <>
      <Box sx={{ width: "100%", position: "relative" }}>
        <CustomButton
          style={{
            backgroundColor: getBackgroundColor(loginProvider),
            color: getTextColor(loginProvider),
            textTransform: "none",
          }}
          onClick={handleClick}
        >
          <Grid container alignItems="center" sx={{ width: "90%" }}>
            <Grid item xs={2.5} sx={{ textAlign: "end" }}>
              <Stack>
                <img
                  src={getLogo(loginProvider)}
                  alt={`${loginProvider} Icon`}
                  style={{
                    height: `${getLogoHeight(loginProvider)}px`,
                    objectFit: "contain",
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={7.5}
              sx={{
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {getButtonText(loginProvider, locale)}
            </Grid>
            {isRecentLoginMethod && <RecentLoginIcon />}
          </Grid>
        </CustomButton>
      </Box>
      {isLoading && <LoadingSpinner overlap />}
    </>
  );
};

export default SocialLoginButton;
