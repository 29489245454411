import useLanguages from "hooks/useLanguages";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import { ProviderUtils } from "utils/providerUtils";

const LachaRewardBanner = () => {
  const { isKorean } = useLanguages();

  return (
    <TWCardLayout className="hana:bg-hana-banner-bg lotte:bg-lotte-banner-bg bc:bg-bc-banner-bg gme:bg-gme-banner-bg redtable:bg-redtable-banner-bg bg-banner-bg border-none px-6">
      <div className="space-y-2">
        <p className="text-xl font-bold">라차 적립금 안내</p>
        <p className="font-bold">
          {ProviderUtils.isHanacard
            ? '트래블버킷에서 KTX 예매시 최대 10% "라차 적립금"을 받으실 수 있습니다'
            : `라차에서 KTX 예매하면, 최대 10%의 적립금을 받으실 수 있습니다! 적립된 적립금은 다음 기차 예매 시 사용 가능합니다.`}
        </p>
        {isKorean && (
          <p className="text-sm font-bold text-text-secondary">
            {ProviderUtils.isHanacard && (
              <>
                * 라차 적립금 : 트래블버킷에서 KTX 예매시 적립 및 사용 가능한
                적립금입니다
                <br />
                ※ 코레일 포인트와는 다른 적립금으로, 트래블버킷에서만
                사용가능합니다
                <br />
                <br />
              </>
            )}
            * 적립시기 : 기차 출발 다음날 적립됩니다
            <br />* 유효기간 : 적립금은 1년간 유효하며, 적립 후 1년이 지나면
            자동으로 소멸됩니다.
          </p>
        )}
      </div>
    </TWCardLayout>
  );
};

export default LachaRewardBanner;
