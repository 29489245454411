import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Pagination,
  Skeleton,
  TextField,
} from "@mui/material";
import ActivityDetailLayout from "../components/ActivityDetailLayout";
import CustomButton from "components/button/CustomButton";
import { forwardRef, useEffect, useRef, useState } from "react";
import TWCardLayout from "pages/tailwind/TWCardLayout";
import {
  ActivityInquiry,
  useActivityInquiriesQuery,
  useMakeActivityInquiryMutation,
  useMyInfoQuery,
  useUpdateActivityInquiryMutation,
} from "app/apiSlice";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import LoadingSpinner from "components/LoadingSpinner";
import { formatDateTimeFromString } from "utils/dateUtils";
import useModalSheet from "hooks/overlay/useModalSheet";

const INQUIRY_PER_PAGE = 8;

interface ProductInquirySectionProps {
  title: string;
}

const ProductInquirySection = forwardRef<
  HTMLDivElement,
  ProductInquirySectionProps
>(({ title }, ref) => {
  const { activityId } = useParams();
  const [inquiryTitle, setInquiryTitle] = useState("");
  const [inquiryContent, setInquiryContent] = useState("");
  const [page, setPage] = useState(1);
  const [modifyingInquiryId, setModifyingInquiryId] = useState<number>();

  const inquiryField = useRef<HTMLDivElement>(null);
  const [Modal, setModalVisible] = useModalSheet({
    callbackOk: () => {
      inquiryField.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  });

  const { data: user } = useMyInfoQuery();
  const { data: inquiries, isLoading } = useActivityInquiriesQuery({
    goodsId: Number(activityId!),
    page: page - 1,
    size: INQUIRY_PER_PAGE,
  });

  const [inquire, { isLoading: isInquiring, isSuccess }] =
    useMakeActivityInquiryMutation();
  const [
    updateInquire,
    { isLoading: isUpdating, isSuccess: isUpdateSuccess, error },
  ] = useUpdateActivityInquiryMutation();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setInquiryTitle("");
      setInquiryContent("");
      setModifyingInquiryId(undefined);
    }
  }, [isSuccess, isUpdateSuccess]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "title":
        if (value.length < 40) {
          setInquiryTitle(value);
        }
        break;
      case "content":
        if (value.length < 500) {
          setInquiryContent(value);
        }
        break;
      default:
        break;
    }
  };

  const handleInquire = () => {
    if (!inquiryTitle || !inquiryContent) {
      setModalVisible(true);
      return;
    }

    if (activityId) {
      inquire({
        goodsId: Number(activityId),
        title: inquiryTitle,
        content: inquiryContent,
      });
    }
  };

  const updateInquiry = () => {
    if (!inquiryTitle || !inquiryContent) {
      setModalVisible(true);
      return;
    }

    if (activityId && modifyingInquiryId) {
      updateInquire({
        questionId: Number(modifyingInquiryId),
        title: inquiryTitle,
        content: inquiryContent,
      });
    }
  };

  const startModification = (inquiry: ActivityInquiry) => {
    setModifyingInquiryId(inquiry.questionId);
    inquiryField.current?.scrollIntoView({
      // behavior: "smooth",
      block: "center",
    });

    setInquiryTitle(inquiry.title);
    setInquiryContent(inquiry.content);
  };

  const reset = () => {
    setModifyingInquiryId(undefined);
    setInquiryTitle("");
    setInquiryContent("");
  };

  return (
    <ActivityDetailLayout ref={ref} title={title}>
      <section className="space-y-2">
        <TWCardLayout>
          <p className="text-sm">
            • 상품과 무관한 글, 양도, 광고성 게시물, 욕설, 비방, 도배 글 등은
            사전 안내 없이 삭제될 수 있습니다.
          </p>
          <p className="text-sm">
            • 주민등록번호, 연락처, 주소, 계좌번호, 카드번호와 같은 개인정보가
            노출될 경우 도용 위험이 있으니 각별히 유의해 주시기 바랍니다.
          </p>
        </TWCardLayout>
        {user && (
          <div ref={inquiryField} className="relative space-y-2">
            <TextField
              name="title"
              placeholder="제목"
              value={inquiryTitle}
              onChange={handleChange}
              className="w-full"
            />
            <TextField
              multiline
              rows={4}
              name="content"
              placeholder="문의 내용"
              value={inquiryContent}
              onChange={handleChange}
              className="w-full"
            />
            <div className="flex items-center justify-end gap-2">
              {modifyingInquiryId && (
                <CustomButton
                  size="small"
                  variant="outlined"
                  color="error"
                  className="h-8 !w-8"
                  onClick={reset}
                >
                  취소
                </CustomButton>
              )}
              <CustomButton
                size="small"
                className="h-8 !w-8"
                onClick={!modifyingInquiryId ? handleInquire : updateInquiry}
              >
                {!modifyingInquiryId ? "문의하기" : "수정하기"}
              </CustomButton>
            </div>
            {(isUpdating || isInquiring) && (
              <LoadingSpinner className="absolute left-1/2 top-0 h-full -translate-x-1/2" />
            )}

            <Modal modal>
              <p className="font-medium">문의 제목/내용을 입력하세요.</p>
            </Modal>
          </div>
        )}
      </section>

      {Boolean(inquiries?.content?.length) && (
        <div className="space-y-4">
          <Divider />
          <div className="space-y-1">
            {isLoading
              ? Array.from({ length: INQUIRY_PER_PAGE }).map((_, key) => (
                  <Skeleton key={key} variant="rounded" height={48} />
                ))
              : inquiries?.content.map((inquiry) => (
                  <Inquiry
                    key={inquiry.questionId}
                    inquiry={inquiry}
                    startModification={startModification}
                  />
                ))}
          </div>
          <div className="mt-4 flex w-full justify-center">
            <Pagination
              count={inquiries?.totalPages || 1}
              shape="rounded"
              color="primary"
              page={page}
              onChange={(_e, value) => setPage(value)}
            />
          </div>
        </div>
      )}
    </ActivityDetailLayout>
  );
});

export default ProductInquirySection;

interface InquiryProps {
  inquiry: ActivityInquiry;
  startModification: (inquiry: ActivityInquiry) => void;
}
const Inquiry = ({ inquiry, startModification }: InquiryProps) => {
  const { data: user } = useMyInfoQuery();

  return (
    <Accordion
      disableGutters
      slotProps={{ transition: { unmountOnExit: true } }}
      className="w-full !rounded-md"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="inquiry-title"
        className="gap-2 !pr-2"
      >
        <div className="grid w-full grid-cols-12">
          <div className="col-span-8 flex flex-col justify-between">
            <div className="flex items-center gap-2">
              <p className="font-medium">
                <span className="font-bold">Q{inquiry.questionId}.</span>{" "}
                {inquiry.title}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs">작성자: {inquiry.userName}</p>
              <p className="text-xs text-text-secondary">
                {formatDateTimeFromString(
                  inquiry.updatedDate ?? inquiry.createdDate
                )}
              </p>
            </div>
          </div>
          {/* <div className="flex w-full items-end gap-2">
            </div> */}
          <div className="col-span-4 self-center justify-self-end text-end">
            {inquiry.replyContent && (
              <Chip
                label="답변완료"
                color="primary"
                variant="outlined"
                size="small"
                className="!rounded-md px-0 py-3 font-bold"
              />
            )}

            {inquiry.userId === user?.userId && (
              <Chip
                label="수정하기"
                color="info"
                variant="outlined"
                size="small"
                className="justify-end !rounded-md px-0 py-3 font-bold"
                onClick={(e) => {
                  e.stopPropagation();
                  startModification(inquiry);
                }}
              />
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="space-y-2">
        <p>
          <span className="font-bold">문의:</span> {inquiry.content}
        </p>

        {inquiry.replyContent && (
          <>
            <Divider />
            <div className="flex gap-2">
              <TurnRightIcon className="scale-y-[-1]" />
              <div className="w-full">
                <p>{inquiry.replyContent}</p>
                {inquiry.repliedDate && (
                  <p className="w-full text-end text-xs text-text-secondary">
                    {formatDateTimeFromString(inquiry.repliedDate)}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
