import { Box, Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import React from "react";

const TravelItem = () => {
  const formatPrice = useFormatPrice();
  const discountRate = 40;

  const getAdditionalInfo = () => {
    return (
      <Stack sx={{ width: "100%" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textDecoration: "line-through" }}
        >
          {formatPrice(100000)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%", "& > p": { fontWeight: "bold" } }}
        >
          <Typography variant="body1">
            {formatPrice((100000 * (100 - discountRate)) / 100)}
          </Typography>
          <Typography variant="body1" color="error">
            {discountRate}% off
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <CardLayout sx={{ pt: 0 }} additionalInfo={getAdditionalInfo()}>
      <Box
        sx={{
          width: "calc(100% + 64px)",
          height: "160px",
          backgroundImage: "url(https://picsum.photos/300/200)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          position: "relative",
        }}
      />
      <Stack sx={{ width: "100%" }}>
        <Typography variant="body2">Location</Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Travel Title
        </Typography>
        <Typography variant="caption">Stars and Interested</Typography>
        <Typography variant="body2">Instant reponse available</Typography>
      </Stack>
    </CardLayout>
  );
};

export default TravelItem;
