import { useAppDispatch } from "app/hooks";
import { fetchRecentOrders } from "app/myOrdersSlice";
import { useCallback, useState } from "react";
import UserService from "services/userService";
import { OrderStatus } from "types/orderType";
import { calculateDateBeforeOneYear } from "utils/dateUtils";

// 장바구니 및 최근 주문 내역 조회를 위한 custom hook
const useFetchOrderInfo = (status?: OrderStatus | "ALL") => {
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const fetchOrderInfos = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await UserService.getMyOrders(
        calculateDateBeforeOneYear(),
        undefined,
        0,
        status ?? "ALL"
      );
      dispatch(fetchRecentOrders(response.content));
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsFetching(false);
    }
  }, [dispatch, status]);

  return [isFetching, fetchOrderInfos] as const;
};

export default useFetchOrderInfo;
