import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import DateSelector from "./sections/DateSelector";
import { Tabs, Tab, Card, Typography, Stack, useTheme } from "@mui/material";
import TrainService, {
  TrainResult,
  SearchResultParams,
} from "services/trainService";
import useTranslateStation from "hooks/useTranslateStation";
import ScheduleItem from "./sections/ScheduleItem";
import Arrow from "components/Arrow";
import LoginContent from "components/login/LoginContent";
import CustomButton from "components/button/CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import useDebounce from "hooks/useDebounce";
import { StorageUtils } from "utils/storageUtils";
import useLanguages from "hooks/useLanguages";
import useModalSheet from "hooks/overlay/useModalSheet";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";
import { NumericString } from "types/numericString";
import { getTotalPassengersNumber } from "utils/urlSearchParamsUtils";
import useIntersect from "hooks/useIntersect";
import ScheduleItemSkeleton from "./skeleton/ScheduleItemSkeleton";

type TabType = 0 | 1 | 2 | 3;

// 스케줄 필터링 함수
// 역무열차종별코드
const filterSchedules = (type: TabType, trainSchedules: TrainResult[]) => {
  return trainSchedules?.filter((result) => {
    switch (type) {
      case 0:
        return true; // '전체' 탭에서는 모든 스케줄 표시
      case 1:
        return result.stlbTrnClsfCd === "00"; // 'KTX' 탭에서는 KTX 스케줄만 표시
      case 2:
        return ["07", "10"].includes(result.stlbTrnClsfCd); // 'KTX-산천' 탭에서는 KTX-산천, 호남 스케줄 표시
      case 3:
        return result.stlbTrnClsfCd === "16"; // 'KTX-이음' 탭에서는 KTX-이음 스케줄만 표시
      default:
        return true;
    }
  });
};

const reload = (index: TabType) => {
  StorageUtils.TrainTab.set(index);
  StorageUtils.ScrollY.set(window.scrollY);
  window.location.reload();
};

const createSearchParams = (
  params: URLSearchParams,
  pageNo: NumericString
): SearchResultParams => {
  return {
    abrdDt: params.get("date")?.replaceAll("-", "") ?? "",
    dptRsStnCd: params.get("departureCode") ?? "", // departureCode,
    arvRsStnCd: params.get("arrivalCode") ?? "", // arrivalCode,
    dptTm: (params.get("departureTime") ?? "000000") as NumericString, //departureTime,
    totPsgNum: getTotalPassengersNumber(params), // 총 승객 수

    medDvCd: "02", // 인터넷은 "02"로 고정
    nowPgNo: pageNo, // 현재 페이지 번호 - 1부터 시작
    pgPrCnt: "20", // 페이지당 결과 수 - default "10" // TODO: infinite scrolling 적용하기
  };
};

const TrainListPage = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { isKorean, isLanguage } = useLanguages();
  const navigate = useNavigate();
  const debounce = useDebounce();
  const translateStationName = useTranslateStation();

  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const date = params.get("date");

  const [Modal, setModalVisible] = useModalSheet();
  const [expandedItem, setExpandedItem] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState<TabType>(
    parseInt(StorageUtils.TrainTab.get() ?? "0") as TabType
  );

  const [loading, setLoading] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState<NumericString>("1");
  const [trainSchedules, setTrainSchedules] = useState<TrainResult[]>([]);
  const filteredTrainSchedules = filterSchedules(selectedTab, trainSchedules);

  const infiniteScrollingTarget = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (lastPage || loading) {
      return;
    }

    if (!loading && filteredTrainSchedules.length === 0) {
      return;
    }

    debounce(fetchTrainSchedules);
  });

  // data
  const tabLabels = [
    intl.formatMessage({ id: "trainType.all" }),
    intl.formatMessage({ id: "trainType.ktx" }),
    intl.formatMessage({ id: "trainType.ktxSancheon" }),
    intl.formatMessage({ id: "trainType.ktxEum" }),
  ];


  // TODO: 날짜를 바꿔가면서 요청할 때 error 발생하면, 발생한 error 수 만큼 alert 창이 켜진다. 
  // axios 요청 취소하는 방법 등을 사용해서 날짜 바꾸면서 요청하더라도 alert 한 번만 보여주도록 수정하기
  // API 호출 함수
  const fetchTrainSchedules = useCallback(async () => {
    const searchParams = createSearchParams(params, currentPage);
    setLoading(true);
    try {
      const searchResult = await TrainService.getSearchResult(searchParams);
      setTrainSchedules((prev) => [...prev, ...searchResult?.trainList]);
      setCurrentPage(`${Number(searchResult?.nowPgNo) + 1}` as NumericString);
      setLastPage(searchResult?.pgCnt === searchResult?.nowPgNo);
    } catch (error) {
      console.error("Error fetching schedule:", error);
      alert("검색 중 오류가 발생했습니다.");
      navigate("/");
    } finally {
      setLoading(false);
    }
  }, [navigate, params, currentPage]);

  useEffect(() => {
    // API 호출 함수
    debounce(fetchTrainSchedules);
    // date가 바뀔 때 fetch 하도록 dependency에 추가
    // currentPage가 바뀔때 fetchTrainSchedules이 바뀌는데 이 때는 다시 호출하지 않도록 dependency에 추가하지 않음
  }, [debounce, navigate, params, date]);

  useEffect(() => {
    if (!params.get("departure")) {
      navigate("/");
    }

    // 모달로 로그인한 경우 localStorage 삭제
    StorageUtils.IsLoginModal.remove();

    // 새로고침 시에 선택한 탭 유지
    window.addEventListener("beforeunload", () => {
      reload(selectedTab);
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        reload(selectedTab);
      });
    };
  }, [navigate, params, selectedTab]);

  // Scroll to previous position before browser (re)painting
  // useLayoutEffect(() => {
  //   if (!loading) {
  //     window.scroll(0, sessionStorage.yScroll);
  //   }
  // }, [loading]);

  const resetScheduleState = () => {
    setLoading(true);
    setLastPage(false);
    setCurrentPage("1");
    setTrainSchedules([]);
    setExpandedItem([]); // Collapse all expanded ScheduleItems

    window.scrollTo({ top: 0 });
  };

  // 날짜 이동
  const handleDateChange = (direction: -1 | 1) => {
    const prevDate = new Date(params.get("date")!);
    prevDate.setDate(prevDate.getDate() + direction);
    params.set("date", prevDate.toISOString().split("T")[0]);
    navigate(`?${params.toString()}`);

    resetScheduleState();
  };

  const handleToggleExpand = (index: number) => {
    if (expandedItem.includes(index)) {
      // If index is already in the list, remove it
      setExpandedItem((prev) => prev.filter((item) => item !== index));
      return;
    }
    // Otherwise, add it to the list
    setExpandedItem((prev) => [...prev, index]);
  };

  const getPageTitle = useCallback(() => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        {isLanguage("ur") ? (
          <>
            {translateStationName(params.get("arrival")!)}
            <Arrow />
            {translateStationName(params.get("departure")!)}
          </>
        ) : (
          <>
            {translateStationName(params.get("departure")!)}
            <Arrow />
            {translateStationName(params.get("arrival")!)}
          </>
        )}
      </Stack>
    );
  }, [isLanguage, params, translateStationName]);

  return (
    <Layout
      text={getPageTitle()}
      onBack={() => navigate(`/?${params.toString()}`)}
    >
      <Card
        sx={{
          border: "none",
          boxShadow: "none",
          bgcolor: theme.background,
          position: "fixed",
          zIndex: 10,
          width: "100%",
          left: 0,
          px: 2,
          pb: 1,
          borderRadius: 0,
          borderBottom: `1px solid ${theme.border.color.light}`,
        }}
      >
        {/* 날짜 선택 컴포넌트 */}
        <DateSelector
          date={date!}
          onPrev={() => handleDateChange(-1)}
          onNext={() => handleDateChange(1)}
        />
        <Card
          sx={{
            boxShadow: "rgba(0, 0, 0, 0.08) 0 2px 8px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(_e, newValue) => {
              setExpandedItem([]);
              StorageUtils.TrainTab.set(newValue);
              setSelectedTab(newValue);
            }}
            variant={"scrollable"}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  fontWeight: "bold",
                  height: "40px",
                  wordBreak: "keep-all",
                }}
              />
            ))}
          </Tabs>
        </Card>
      </Card>

      <Stack justifyContent="start" alignItems="center" gap={1} sx={{ mt: 20 }}>
        {!loading && filteredTrainSchedules?.length === 0 ? (
          <Stack justifyContent="center" sx={{ minHeight: "50vh" }}>
            <Typography variant="h5">
              {intl.formatMessage({ id: "fareInquiry.noTrains" })}
            </Typography>
          </Stack>
        ) : (
          filteredTrainSchedules?.map((result, index) => (
            <ScheduleItem
              key={result.gdConsItmId}
              schedule={result}
              expanded={expandedItem.includes(index)} // Check if index is in the expandedItem list
              // expanded={expandedItem === index}
              onToggleExpand={() => handleToggleExpand(index)}
              setModalVisible={setModalVisible}
            />
          ))
        )}
        {loading && <ScheduleItemSkeleton />}
        <div ref={infiniteScrollingTarget}></div>

        {/* 로그인 모달 */}
        <Modal modal variant="icon">
          <LoginContent
            isModal={true}
            closeModal={() => setModalVisible(false)}
          />
        </Modal>

        {/* 새로고침 버튼 */}
        <CustomButton
          style={{
            position: "fixed",
            bottom: `${12 + BOTTOM_NAVIGATION_HEIGHT}px`,
            left: "16px",
            padding: "6px 8px",
          }}
          size="small"
          fullWidth={false}
          onClick={() => reload(selectedTab)}
        >
          {isKorean ? "새로고침" : "Reload"}
          <RefreshIcon fontSize="small" />
        </CustomButton>
      </Stack>
    </Layout>
  );
};

export default TrainListPage;
