import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { feature } from "topojson-client";
import { Topology } from "topojson-specification";
import okinawa from "assets/maps/okinawa.json";

import { useMediaQuery } from "@mui/system";
import { useNavigate } from "react-router-dom";

const iconsInfo = [
  {
    name: "오키나와\nOKINAWA",
    lat: "26.7344",
    lon: "128.5056",
    // color: "#9763A4",
    color: "#E82300",
    xOffset: -0.4,
    yOffset: -0.1,
    href: "okinawa",
  },
];

const OkinawaMap = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:512px)");

  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    // Load the TopoJSON data
    const drawMap = async () => {
      // Clear any existing content
      d3.select(svgRef.current).selectAll("*").remove();

      // Convert TopoJSON to GeoJSON
      // topojson-client’s feature function converts TopoJSON objects into GeoJSON
      const geojson = feature(
        okinawa as unknown as Topology,
        okinawa.objects["okinawa"] as any
      );

      const getSize = () => {
        if (window.screen.width < 512) {
          return [window.screen.width / 3, window.screen.width / 3.5] as [
            number,
            number,
          ];
        } else {
          return [480 / 3, 480 / 3.5] as [number, number];
        }
      };

      // Create projection (지도 투영 설정 - Mercator)
      const projection = d3
        .geoMercator()
        .center([+iconsInfo[0].lon, +iconsInfo[0].lat]) // 오키나와 중심 좌표
        // .scale(5000) // 확대/축소 정도
        .scale(matches ? 4500 : 3000) // 확대/축소 정도
        .translate(getSize().map((d) => d / 2) as [number, number]);

      // Create path generator
      const path = d3.geoPath().projection(projection);

      // Create SVG group
      // d3.select(svgRef.current) binds D3 to the React SVG element
      const svg = d3
        .select(svgRef.current)
        .attr("width", getSize()[0])
        .attr("height", getSize()[1]);

      // Draw map
      svg
        .selectAll("path")
        .data((geojson as any).features)
        .enter()
        .append("path")
        .attr("d", path as any)
        // .attr("vector-effect", "non-scaling-stroke")
        .attr("fill", "#FFF")
        .attr("stroke", "#FFF")
        .attr("stroke-width", 0.5);

      // 마커 추가 - 마커를 나중에 추가해야 지도 위에 표시됨
      svg
        .selectAll("g") //   .selectAll("circle")
        .data(iconsInfo)
        .join("g") //   .join("circle")
        .attr("transform", (d) => {
          const [x, y] = projection([
            +d.lon + d.xOffset,
            +d.lat + d.yOffset,
          ]) || [0, 0];
          return `translate(${x}, ${y})`;
        })
        .attr("cursor", "pointer")
        .each(function (d) {
          const group = d3.select(this);
          const href = d.href;

          // 마커 핀
          group
            .append("path")
            .attr(
              "d",
              `M0,-30  
              A15,15 0 1,1 0,-29.85
              L24,-20
              L15,-2
              L6,-20
              Z`
            ) // 핀 모양 (위쪽 원과 꼬리)
            .attr("fill", d.color)
            // .attr("stroke", "black")
            .attr("stroke", d.color)
            .attr("stroke-width", 1)
            .attr("transform", " translate(-10, 0)");

          // 원형 내부
          group
            .append("circle")
            .attr("cx", 5)
            .attr("cy", -30)
            .attr("r", 7.5)
            .attr("fill", "white");

          // group
          //   .append("rect")
          //   .attr("x", 12 + d.xOffset) // text의 x값보다 약간 작게
          //   .attr("y", -14 + d.yOffset) // text의 y값보다 약간 작게
          //   .attr(
          //     "width",
          //     10 *
          //       Math.max(
          //         ...d.name
          //           .split("\n")
          //           .map((str) => str.replaceAll("/", "").length)
          //       )
          //   ) // 텍스트 길이에 따라 조정 필요
          //   .attr("height", 36) // 두 줄의 텍스트를 감싸도록 설정
          //   .attr("fill", "#FFF9")
          //   .attr("stroke", "#ccc") // 테두리 색상
          //   .attr("stroke-width", 1) // 테두리 두께
          //   .attr("rx", 4) // 모서리 둥글게
          //   .attr("ry", 4);

          // 텍스트 레이블 (한글)
          group
            .append("text")
            .attr("x", 20)
            // .attr("y", d.lon)
            .attr("font-size", "11px")
            .attr("font-weight", "bold")
            .attr("fill", "#000") // 텍스트 색상
            .text(d.name.split("\n")[0]); // 한글 이름

          // 텍스트 레이블 (영문)
          group
            .append("text")
            .attr("x", 14)
            .attr("y", 15)
            //   .attr("y", (d) => {
            //     const [, y] = projection([+d.lon, +d.lat]) || [0, 0];
            //     return y + 4; // 마커 위치와 높이를 맞춤
            //   })
            .attr("font-size", "11px")
            .attr("font-weight", "bold")

            .attr("fill", "#000") // 텍스트 색상
            .text(d.name.split("\n")[1]); // 영어 이름

          group.on("click", () => {
            navigate(`category/${href}`);
          });

          group
            .on("touchstart mousedown", function () {
              d3.select(this).select("rect").attr("fill", "#FFF5");
              d3.select(this)
                .select("text") // 그룹 내부의 레이블 선택
                .attr("fill", "red"); // 색상 변경
            })
            .on("touchend mouseup", function () {
              d3.select(this).select("rect").attr("fill", "#FFF9");
              d3.select(this)
                .select("text") // 그룹 내부의 레이블 선택
                .attr("fill", "#333"); // 원래 색상으로 복원
            });
        });
    };

    drawMap();
    window.addEventListener("resize", drawMap);
    return () => {
      window.removeEventListener("resize", drawMap);
    };
  }, [matches, navigate]);

  return (
    <svg
      ref={svgRef}
      className="h-full rounded-xl border-[1px] border-none bg-gray-50 bg-opacity-50"
    ></svg>
  );
};

export default OkinawaMap;
