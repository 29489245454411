import axios, { AxiosResponse } from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";
import { ScheduleType } from "types/scheduleType";
import { TrainServiceType } from "const/codeMap";
import { ResultSeatList } from "types/carType";
import { Coupon } from "utils/reservationUtils";
import { OrderType } from "types/orderType";
import type { NumericString, OneDigitNumericString } from "types/numericString";

export type CurrencyType = "USD" | "JPY";

export type TrnPsgTpCd = "1" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

export interface SearchResultParams {
  abrdDt: string;
  dptRsStnCd?: string;
  arvRsStnCd?: string;
  dptTm: NumericString; //사용자가 선택한 출발 시간 hhmmss
  medDvCd: "02"; // 인터넷은 "02"로 고정
  nowPgNo: NumericString; //1, // 현재 페이지 번호
  pgPrCnt: NumericString; //"100", // 페이지당 결과 수 - default "10"
  totPsgNum: NumericString | number; // 총 승객 수
}

export interface CostParams {
  dptRsStnCd: ScheduleType["dptRsStnCd"]; // 출발역 코드
  arvRsStnCd: ScheduleType["arvRsStnCd"]; // 도착역 코드
  trnNo: ScheduleType["trnNo"]; // 기차 번호
  gdNo: ScheduleType["gdNo"]; // 상품 번호 (static value)
  gdConsGpSqno: ScheduleType["gdConsGpSqno"]; // 상품 구성 그룹 순번
  gdConsItmId: ScheduleType["gdConsItmId"]; // 상품 구성 품목 ID

  gdItmId: ScheduleType["gdItmId"]; // 상품 품목 ID
  utlDt: ScheduleType["dptDt"]; // 이용 날짜 (format: YYYYMMDD)
  medDvCd: "02"; // 매체 구분 코드 (static value)

  adultCount: number;
  childCount: number;
}

export interface SeatMapParams {
  [key: string]: SeatMapParams[keyof SeatMapParams];

  dptRsStnCd: ScheduleType["dptRsStnCd"]; // 출발역 코드
  arvRsStnCd: ScheduleType["arvRsStnCd"]; // 도착역 코드
  trnNo: ScheduleType["trnNo"]; // 기차 번호
  gdNo: ScheduleType["gdNo"]; // 상품 번호
  gdConsGpSqno: ScheduleType["gdConsGpSqno"]; // 추가 정보
  gdConsItmId: ScheduleType["gdConsItmId"]; // 추가 정보

  scarNo?: RemainingSeat["scarNo"]; // 차량 번호
  seatAttCd?: "024"; // 코레일 좌석 위탁 판매 속성 - 024로 고정

  trnGpCd: ScheduleType["trnGpCd"]; // 열차 그룹코드
  psrmClCd: "1"; // 승객 클래스 코드
  runDt: ScheduleType["runDt"]; // 운행 날짜
}

interface RemainingSeat {
  scarNo: string;
  seatNum: number;
  restSeatNum: number;
}

export interface TrainResult {
  pageYn: string;
  sidx: string;
  sord: string;
  page: number;
  rows: number;
  totalCount: number;
  totalPageCount: number;
  limit: number;
  offset: number;
  startIndex: number;
  endIndex: number;
  mmbrshpMlaRt: number;
  sbscrbMlaRt: number;
  stayCpnCd: string;
  cuCpnYn: string;
  starbucksCpnYn: string;
  ticketCpnYn: string;
  trnCpnRt: string;
  mmbrshpDscntRt: number;
  sbscrbDscntRt: number;
  stayCpnCd2: string;
  stayCpnA: string;
  stayCpnB: string;
  stayCpnA2: string;
  stayCpnB2: string;
  trnOrdrNo: string;
  chtnTrnOrdrNo: string;
  dirtChtnDvCd: string;
  runDt: string;
  trnNo: string;
  trnGpCd: string;
  stlbTrnClsfCd: TrainServiceType;
  ymsAplFlg: string;
  saleRgulFlg: string;
  trnChgFlg: string;
  seatAlcFlg: string;
  dptRsStnCd: string;
  dptRsStnNm: string;
  arvRsStnCd: string;
  arvRsStnNm: string;
  dptDt: string;
  dptTm: string;
  arvDt: string;
  arvTm: string;
  ocurDlayTnum: string;
  stlbPscRoomKndCd1: string;
  stlbPscRoomKndCd2: string;
  stlbPscRoomKndCd3: string;
  stlbPscRoomKndCd4: string;
  stlbPscRoomKndCd5: string;
  gnrmRsvPsbFlg: string;
  sprmRsvPsbFlg: string;
  gdNo: string;
  gdRunStDt: string;
  gdRunClsDt: string;
  gdConsGpSqno: string;
  gdConsItmId: string;
  gdItmId: string;
  runHm: string;
  trnGpNm: string;
  scarNo: string;
  seatNum: number;
  restSeatNum: number;
}

export interface SearchResult {
  whlQryCnt: string; // 전체조회건수
  nowPgNo: string; // 현재페이지번호 - 1부터
  pgCnt: string; // 현재페이지번호
  trainList: TrainResult[];
}

interface SeatMapResult {
  strResult: string;
  msgCd: string;
  msgTxt: string;
  cnvSeatNum: number;
  opSeatNum: number;
  carTpCd: string;
  seatAryCd: string;
  fxSeatFlg: string;
  seatDirDvCd: string;
  grpGurdFlg: string;
  offDvCd: string;
  stlbTrnClsfCd: string;
  scarNo: string;
  saleRgulFlg: string;
  restSeatList: ResultSeatList[];
}

export interface CostResponse {
  buyDscntRt: number;
  sellDscntRt: number;
  sellCmsnRt: number;
  pointSavingRt: number;
  trnPsrmClCd: OneDigitNumericString;
  trnPsgTpCd: TrnPsgTpCd;
  mrkFare: number;
  csmrFare: number;
  couponAmount: number;
}

export interface TrainResponse {
  stationCode: string;
  stationName: string;
}

// 기본 axios 인스턴스 설정
const trainApi = axios.create({
  baseURL: apiBaseUrl + "/train",
});

// 요청 인터셉터 추가
trainApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
trainApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

class TrainService {
  // 역 및 기차 정보 관련 함수들
  // static async getStations(): Promise<TrainResponse[]> {
  //   // 역 목록 조회 함수
  //   try {
  //     const response: AxiosResponse<TrainResponse[]> =
  //       await trainApi.get("/stations");
  //     return response.data;
  //   } catch (error) {
  //     console.error("역 목록 조회 오류:", error);

  //     throw error;
  //   }
  // }

  // 요금 정보 조회 함수
  static async getSearchCost(costParams: CostParams): Promise<CostResponse[]> {
    try {
      const response: AxiosResponse<CostResponse[]> = await trainApi.get(
        "/searchCost",
        {
          params: costParams, // Pass parameters correctly
        }
      );
      return response.data;
    } catch (error) {
      console.error("요금 조회 오류:", error);
      throw error;
    }
  }

  static async getSeatMap(params: SeatMapParams): Promise<SeatMapResult> {
    // 좌석 배치도 조회 함수
    try {
      // const queryString = new URLSearchParams(params).toString();
      // const response = await trainApi.get(`/seatMap?${queryString}`);
      const response: AxiosResponse<SeatMapResult> = await trainApi.get(
        `/seatMap`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("좌석 배치도 조회 오류:", error);
      throw error;
    }
  }

  static async searchRemainingSeats(
    params: SeatMapParams
  ): Promise<RemainingSeat[]> {
    // 기차 남은 좌석 수 조회 함수
    try {
      const response: AxiosResponse<RemainingSeat[]> = await trainApi.get(
        `/searchRestSeat`,
        {
          params: params,
          // const response = await trainApi.get(`/searchRestSeat?${queryString}`, {
        }
      );
      return response.data;
    } catch (error) {
      console.error("기차 남은 좌석 수 조회 오류:", error);
      throw error;
    }
  }

  static async getSearchResult(
    params: SearchResultParams
  ): Promise<SearchResult> {
    // 기차 검색 결과 조회 함수
    try {
      // const queryString = new URLSearchParams(params).toString();
      // const response = await trainApi.get(`/searchResult?${queryString}`);
      const response: AxiosResponse<SearchResult> = await trainApi.get(
        `/searchResult`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("검색 결과 조회 오류:", error);
      throw error;
    }
  }

  static async reserveTicket(
    data: FormData
  ): Promise<AxiosResponse<OrderType>> {
    // 기차표 예약 함수
    try {
      const response: AxiosResponse<OrderType> = await trainApi.post(
        "/ticketReserve",
        data
      );
      return response;
    } catch (error) {
      console.error("기차표 예약 오류:", error);
      throw error;
    }
  }

  // static async checkPoint(
  //   orderId: string,
  //   // path: "payment/confirmation" | "order-details"
  //   path: string
  // ) {
  //   // 포인트 파크 포인트 조회 함수
  //   try {
  //     const response = await trainApi.get(`/initPointPark/${orderId}`, {
  //       params: { path },
  //     });
  //     return response;
  //   } catch (error) {
  //     console.error("포인트파크 조회 오류:", error);
  //     throw error;
  //   }
  // }

  // static async cancelPoint(orderId: string) {
  //   // 포인트 파크 포인트 조회 함수
  //   try {
  //     const response = await trainApi.post(`/cancelPoint/${orderId}`, {});
  //     return response;
  //   } catch (error) {
  //     console.error("포인트파크 취소 오류:", error);
  //     throw error;
  //   }
  // }

  // 기차 결합상품 조회 함수
  static async getRelatedGoods(
    stationCode: ScheduleType["arvRsStnCd"],
    couponAmount: number
  ): Promise<Coupon[]> {
    // console.log(stationCode);

    try {
      const response: AxiosResponse<Coupon[]> = await trainApi.get(
        `/relatedGoods`,
        {
          params: { stationCode: stationCode, couponAmount }, // 도착역 코드를 쿼리 파라미터로 전달
        }
      );
      return response.data;
    } catch (error) {
      console.error("기차 결합상품 조회 오류:", error);
      throw error;
    }
  }

  // 한패스 쿠폰 할일액 조회 함수
  static async getHanpassCouponDiscountAmount(
    orderId: number,
    externalCouponId: number
  ): Promise<number> {
    try {
      const response: AxiosResponse<number> = await trainApi.get(
        `/hanpassCouponCheck`,
        {
          params: { orderId, externalCouponId },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // 환율 조회 함수
  static async getCurrency(code: CurrencyType): Promise<number> {
    try {
      const response = await trainApi.get(`/currency`, {
        params: { code },
      });
      return response.data.rate;
    } catch (error) {
      throw error;
    }
  }

  /*   static async getSearchRestSeat(params: SeatMapParams) {
    // 남은 좌석 조회 함수
    try {
      // const queryString = new URLSearchParams(params).toString();
      // const response = await trainApi.get(
      //   `/train/searchRestSeat?${queryString}`,
      //   {}
      // );
      const response = await trainApi.get(`/train/searchRestSeat`, { params });
      return response.data;
    } catch (error) {
      console.error("남은 좌석 조회 오류:", error);
      throw error;
    }
  } */

  // static async saveForeignSelection(selectionData) {
  //   // 외국인 선택 저장 함수
  //   try {
  //     const response = await trainApi.post(
  //       "/test/foreign/saveSelection",
  //       selectionData
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("외국인 선택 저장 오류:", error);
  //     throw error;
  //   }
  // }

  // static async requestForeignReservation(reservationData) {
  //   // 외국인 예약 요청 함수
  //   try {
  //     const response = await trainApi.post(
  //       "/test/foreign/requestReservation",
  //       reservationData
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("외국인 예약 요청 오류:", error);
  //     throw error;
  //   }
  // }
}

export default TrainService;
