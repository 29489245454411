import { Chip, Divider } from "@mui/material";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import StarRates from "components/StarRates";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import LanguageIcon from "@mui/icons-material/Language";
import { TravelGood } from "services/travelService";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 40,
  },
  // mobile: {
  //   breakpoint: {
  //     max: 464,
  //     min: 0,
  //   },
  //   items: 1,
  //   partialVisibilityGutter: 30,
  // },
  // tablet: {
  //   breakpoint: {
  //     max: 1024,
  //     min: 464,
  //   },
  //   items: 1,
  //   partialVisibilityGutter: 30,
  // },
};

const formatLanguages = (langString: string) => {
  const languagesMap: { [key: string]: string } = {
    "ko-KR": "한국어",
    "en-US": "영어",
    "ja-JP": "일본어",
    "zh-CN": "중국어",
    "zh-HK": "중국어(홍콩)",
  };

  return langString
    .split(",") // ','를 기준으로 문자열 분리
    .reduce((acc, lang) => {
      const trimmedLang = lang.trim();
      if (languagesMap[trimmedLang]) {
        acc.push(languagesMap[trimmedLang]);
      }
      return acc;
    }, [] as string[]) // languagesMap에  있는 값만 변환
    .join("/"); // '/'로 다시 합치기
};

const formatVerifyType = (verifyType: number) => {
  const verifyTypesMap: { [key: number]: string } = {
    0: "SMS",
    1: "e-티켓",
    3: "신분증",
    4: "실물 티켓",
    5: "사본",
    6: "학생증",
  };

  return verifyTypesMap[verifyType];
};

interface ActivityHeroSectionProps {
  activity: TravelGood;
}

const ActivityHeroSection = ({ activity }: ActivityHeroSectionProps) => {
  const {
    isAutoConfirm,
    isRefundable,
    verifyType, // 0: SMS, 1: e-티켓, 3: 신분증, 4: 실물 티켓, 5: 사본, 6: 학생증
    lang,
    imagePathList,
    goodsName,
    thumbnailPath,
  } = activity;

  const imageList = !!imagePathList[0] ? imagePathList : [thumbnailPath];
  const isMultiImages = imageList.length > 1;

  return (
    <section className="w-full max-w-lg space-y-4 bg-white pt-0">
      <section className="relative h-[65vw] max-h-72 overflow-hidden border-gray-300">
        <Carousel
          responsive={responsive}
          arrows={isMultiImages}
          autoPlay
          autoPlaySpeed={10000}
          swipeable={isMultiImages}
          draggable={isMultiImages}
          infinite={isMultiImages}
          // showDots
          rewindWithAnimation={false}
          className="flex h-full rounded-b-xl"
        >
          {imageList.map((imagePath, index) => (
            <img
              key={imagePath}
              src={imagePath}
              alt={`${goodsName}${index}`}
              width="100%"
              height="auto"
              style={{ objectFit: "cover" }}
              className="relative max-w-lg self-start"
            />
          ))}
        </Carousel>
      </section>

      {/* <Box
        sx={{
          backgroundImage: `url(${activity.thumbnailPath})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "calc(100%)",
          minHeight: "280px",
          minWidth: "280px",
          maxHeight: "400px",

          position: "relative",
          left: -16,

          overflow: "hidden",
        }}
      /> */}

      {/* 상품 정보 */}
      <section className="space-y-4 px-4 pb-4">
        <section className="flex w-full flex-col gap-4">
          <h1 className="text-xl font-bold leading-6">{goodsName}</h1>

          {/* <section> */}
          {/* <p
              className="truncate break-keep text-sm leading-5 text-text-secondary"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {activity.description} 
              Activity overview description
            </p> */}

          {/* 별점 */}
          {/* <div className="flex gap-2">
              <StarRates rate={activity.rate} />
              <p className="self-end font-bold leading-5">
                {activity.rate}점
              </p>
            </div> */}
          {/* </section> */}
        </section>

        <Divider />

        {/* icons */}
        <section className="flex flex-col justify-start gap-2">
          {isAutoConfirm && (
            <div>
              <Chip
                label="즉시확정"
                color="primary"
                variant="outlined"
                size="small"
                className="mr-2 !rounded-md px-0 py-3 font-bold"
              />
              <p className="inline text-sm">
                예약 후 즉시 구매 (예약을 하시면 교환, 환불이 불가능합니다.)
              </p>
            </div>
          )}

          {!isRefundable && (
            <div className="flex items-center gap-1 text-red-600">
              <DoDisturbIcon fontSize="small" />
              <p className="text-sm">취소불가</p>
            </div>
          )}

          <div className="flex items-center gap-1">
            <BookOnlineIcon fontSize="small" />
            <p className="text-sm">{formatVerifyType(verifyType)}</p>
          </div>
          <div className="flex items-center gap-1">
            <LanguageIcon fontSize="small" />
            <p className="text-sm">{formatLanguages(lang)}</p>
          </div>
        </section>

        {/* <Divider /> */}

        {/* Notice */}
        {/*  <Stack gap={0.5}>
          <Stack direction="row" gap={0.5}>
            <ErrorOutlineIcon />
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Notice
            </Typography>
          </Stack>
          <Typography variant="caption">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
            doloremque officia voluptatum, nobis rem, iusto quis et neque sit
            minus eius eos laborum numquam inventore sint atque tempora
            blanditiis nemo.
          </Typography>
        </Stack> */}
      </section>
    </section>
  );
};

export default ActivityHeroSection;
