import { useAppDispatch, useAppSelector } from "app/hooks";
import { updatePoint } from "app/userSlice";
import { useEffect, useState } from "react";
import UserService from "services/userService";

const useFetchPoint = () => {
  const user = useAppSelector((state) => state.user);
  const [point, setPoint] = useState(user.point);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getPoint = async () => {
      const myPoint = await UserService.getPoint();
      dispatch(updatePoint(myPoint));
      setPoint(myPoint);
    };

    // login 되어있으면 포인트 최신화
    if (user.userId) {
      getPoint();
    }
  }, [dispatch, user]);

  return point;
};

export default useFetchPoint;
