import { PaymentMethod } from "const/paymentMethod";
import useModalSheet from "hooks/overlay/useModalSheet";
import useFormatPrice from "hooks/useFormatPrice";
// import useHanpassCoupon from "hooks/useHanpassCoupon";
import useLanguages from "hooks/useLanguages";
import useProcessPayment from "hooks/useProcessPayment";
import { useCallback, useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentService, {
  GMEPaymentRequestResponse,
} from "services/paymentService";
import TimeService from "services/timeService";
import { OrderType } from "types/orderType";
import { getMobileOperatingSystem } from "utils/osUtils";
import { getDefaultPaymentMethod } from "utils/paymentUtils";
import { ProviderUtils } from "utils/providerUtils";
import history from "history/browser";
import Layout from "components/layout/Layout";
import { FormattedMessage } from "react-intl";
import LoadingSpinner from "components/LoadingSpinner";
import { Stack } from "@mui/system";
import OrderStatusSection from "components/order/OrderStatusSection";
import ProductInfoSection from "pages/ktx/order-history-panel/history-detail-panel/sections/ProductInfoSection";
import RelatedProduct from "pages/ktx/order-history-panel/history-detail-panel/sections/RelatedProduct";
import PointSection from "components/payment/PointSection";
import PaymentMethodSection from "components/payment/PaymentMethodSection";
import CustomButton from "components/button/CustomButton";
import { Divider, Typography } from "@mui/material";
import GMEBottomSheetContent from "pages/ktx/payment-panel/sections/GMEBottomSheetContent";
import { isAxiosError } from "axios";
import TravelService from "services/travelService";
import OrderSummarySection from "components/order/OrderSummarySection";
import useHanpassCoupon from "hooks/useHanpassCoupon";

// const HanpassCoupon = lazy(() => import("components/payment/HanpassCoupon"));

const ShoppingCartPayment = () => {
  const navigate = useNavigate();
  const { isKorean } = useLanguages();
  const { paymentId } = useParams();
  const formatPrice = useFormatPrice();

  // state
  // const [reservationIds, setReservationIds] =
  //   useState<Pick<OrderType, "productType" | "orderId">[]>();
  const [reservations, setReservations] = useState<OrderType[]>([]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const response: Pick<OrderType, "productType" | "orderId">[] =
          await PaymentService.getOrderListOfPayment(Number(paymentId));

        response.forEach(async (reservation) => {
          const { productType, orderId } = reservation;
          switch (productType) {
            case "TRAIN": {
              const response = await PaymentService.reserveDetail(
                Number(orderId)
              );
              setReservations((prev) => [...prev, response]);
              break;
            }
            case "TRAVEL": {
              const response =
                await TravelService.getTravelReservation(orderId);
              setReservations((prev) => [...prev, response]);
              break;
            }
            default: {
              const response =
                await TravelService.getTravelReservation(orderId);
              setReservations((prev) => [...prev, response]);
              break;
            }
          }
        });
        // setReservationIds(response);
      } catch (error) {
        console.error(error);
      }
    };

    if (paymentId) {
      getOrders();
    }
  }, [paymentId]);

  const totalPrice =
    reservations?.reduce((acc, reservation) => {
      return acc + reservation.originalPrice;
    }, 0) ?? 0;
  const pointEarned =
    reservations?.reduce((acc, reservation) => {
      return acc + (reservation.pointEarned ?? 0);
    }, 0) ?? 0;

  const [isProcessing, processPayment] = useProcessPayment();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    getDefaultPaymentMethod
  );

  // Lacha 적립금
  const [point, setPoint] = useState("0");
  const numericPoint = Number(point.replaceAll(",", ""));

  // hanpass coupon
  const {
    HanpassCoupon,
    selectedCoupon,
    hanpassCouponDiscountAmount,
    // setSelectedCoupon,
  } = useHanpassCoupon();

  // data
  // const isPending = reservationDetails?.orderStatus === "PENDING";
  // const isPurchased = reservationDetails?.orderStatus === "COMPLETE";
  const isPaymentProcessButtonAvailable = !isProcessing && paymentMethod;

  // 결제 처리 핸들러
  const handlePayment = useCallback(async () => {
    if (paymentId) {
      processPayment(
        Number(paymentId),
        paymentMethod,
        numericPoint,
        selectedCoupon?.memberCouponSeq
      );
    }
  }, [
    numericPoint,
    paymentId,
    paymentMethod,
    processPayment,
    selectedCoupon?.memberCouponSeq,
  ]);

  // 결제 처리 모달
  const [ModalSheet, setModalVisible] = useModalSheet({
    callbackOk: handlePayment,
  });

  // gme
  const [gmeConnect, setGmeConnect] = useState<GMEPaymentRequestResponse>();
  const handleGmeClick = () => {
    const os = getMobileOperatingSystem();

    const { availablePay = false, key = "" } = gmeConnect || {};

    if (os === "Android") {
      if (availablePay) {
        Android.needtocertification(key);
      } else {
        Android.gotodepositapplyguide("deposit insufficient");
      }
    } else {
      if (availablePay) {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "needtocertification",
          value: key,
        });
      } else {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "gotodepositapplyguide",
          value: "deposit insufficient",
        });
      }
    }
  };

  const [GmeBottomSheet, setGmeBottomSheetVisible] = useModalSheet({
    callbackOk: handleGmeClick,
  });

  // 결제 버튼 클릭 핸들러
  const handlePaymentButtonClick = useCallback(async () => {
    try {
      setIsPaymentProcessing(true);
      if (reservations?.length && paymentId) {
        const currentDate = new Date(await TimeService.getCurrentTime());
        const paymentLimitDate = new Date(reservations[0].paymentLimitDate);

        const isExpiredReservation = currentDate > paymentLimitDate;

        if (isExpiredReservation) {
          alert(
            isKorean
              ? "상품 선점 시간이 지났습니다. 다시 예매해주세요"
              : "Your product selection time has expired . Please book again"
          );
          navigate("/order-history");
          return;
        }

        if (ProviderUtils.isPaybooc) {
          setModalVisible(true);
          return;
        }

        if (ProviderUtils.isLottecard) {
          if (
            [PaymentMethod.CREDIT, PaymentMethod.CORPORATE].includes(
              paymentMethod
            )
          ) {
            const lottecardResponse = await PaymentService.paymentRequest(
              paymentId,
              paymentMethod,
              numericPoint
            );

            // 포인트로만 결제하는 경우 바로 결제 완료 페이지로 이동
            if (totalPrice === numericPoint) {
              navigate("/train/creditPayment");
              return;
            }

            navigate(`/payment/process/${paymentId}`, {
              state: { lottecardResponse },
            });
          } else {
            handlePayment();
          }
          return;
        }

        if (ProviderUtils.isGME) {
          if (
            [PaymentMethod.CREDIT, PaymentMethod.CORPORATE].includes(
              paymentMethod
            )
          ) {
            handlePayment();
            return;
          }

          const gmeResponse = await PaymentService.paymentRequest(
            paymentId,
            paymentMethod,
            numericPoint
          );

          setGmeBottomSheetVisible(true);
          setGmeConnect(gmeResponse);

          return;
        }
      }
    } catch (error) {
      alert("결제에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsPaymentProcessing(false);
    }

    handlePayment();
  }, [
    handlePayment,
    isKorean,
    navigate,
    numericPoint,
    paymentId,
    paymentMethod,
    reservations,
    setGmeBottomSheetVisible,
    setModalVisible,
    totalPrice,
  ]);

  const backToShoppingCart = useCallback(async () => {
    if (ProviderUtils.isHanacard) {
      navigate("/hanacard/reservations");
      return;
    }

    navigate("/cart", { replace: true });
  }, [navigate]);

  useEffect(() => {
    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        backToShoppingCart();
      }
    });
    return historyEvent;
  }, [backToShoppingCart]);

  /**
   * GME App payment
   * 심플패스워드 인증 성공 시, APP에서 getCertificationResponse 함수를 호출한다.
   * GME App인 경우 getCertificationResponse 함수 생성해서 결제 로직 진행될 수 있도록 합니다.
   */
  useEffect(() => {
    if (ProviderUtils.isGME) {
      const initGMEPayment = async (key: string) => {
        try {
          await PaymentService.initializeGMEPayment(key);
          history.replace("/cart");
          navigate(`/train/creditPayment`);
          // navigate(`/train/creditPayment?orderId=${orderId}`);
        } catch (error) {
          if (isAxiosError(error)) {
            alert("결제에 실패했습니다. 다시 시도해주세요.");
          }
        }
      };

      window.getCertificationResponse = (key: string, isCertified: boolean) => {
        initGMEPayment(key);

        return {
          certified: isCertified,
          paymentKey: key,
        };
      };
    }
  }, [navigate]);

  const calculateFinalPrice = useCallback(() => {
    let finalPrice = 0;
    finalPrice =
      totalPrice - // originalPrice들의 합
      // reservationDetails.originalPrice -
      numericPoint -
      hanpassCouponDiscountAmount;
    return formatPrice(finalPrice);
  }, [formatPrice, hanpassCouponDiscountAmount, numericPoint, totalPrice]);

  return (
    paymentId && (
      <Layout
        text={<FormattedMessage id="orders.detailTitle" />}
        onBack={backToShoppingCart}
      >
        {!reservations || reservations.length === 0 ? (
          <LoadingSpinner />
        ) : (
          <Stack gap={1.5} sx={{ my: 2 }}>
            {reservations.map((reservationDetails, index) => (
              <Fragment key={reservationDetails.orderId}>
                {/* 상품 하나인 경우 StatusSection 보여주기 */}
                {reservations.length === 1 ? (
                  <OrderStatusSection order={reservationDetails} />
                ) : (
                  <p className="pl-2 text-lg font-bold">상품 {index + 1}</p>
                )}
                <ProductInfoSection order={reservationDetails} />

                {reservationDetails.goodsList && (
                  <RelatedProduct order={reservationDetails} />
                )}
                <Divider />
              </Fragment>
            ))}

            <Stack gap={2} sx={{ textAlign: "center" }}>
              {ProviderUtils.pointAvailable && (
                <PointSection
                  point={point}
                  setPoint={setPoint}
                  priceToPay={totalPrice}
                />
              )}

              {/* 한패스 쿠폰 */}
              {
                ProviderUtils.isHanpass && HanpassCoupon
                // <HanpassCoupon
                //   coupons={hanpassCoupons}
                //   selectedCoupon={selectedCoupon}
                //   setSelectedCoupon={setSelectedCoupon}
                // />
              }

              <OrderSummarySection
                originalPrice={totalPrice}
                pointEarned={pointEarned}
                point={numericPoint}
                hanpassCouponDiscountAmount={hanpassCouponDiscountAmount}
              />

              {/* <BookingGuidelines /> */}
              {/* <AgreementsSection
              agreements={agreements}
              setAgreements={setAgreements}
              handleAllAgree={handleAllAgree}
            /> */}

              {/* 롯데카드 제외하고 결제수단 보여주기 */}
              {!ProviderUtils.isLottecard && (
                <PaymentMethodSection
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              )}
            </Stack>

            {/* 결제 버튼 */}
            <Stack direction="row" gap={1}>
              <CustomButton
                id="payment.paymentProceed"
                onClick={handlePaymentButtonClick}
                disabled={isProcessing || !isPaymentProcessButtonAvailable}
                price={calculateFinalPrice()}
                // size={isPending && !isPurchased ? "medium" : "large"}
              />
            </Stack>

            {/* Paybooc modal */}
            <ModalSheet
              cancellable
              okText={
                <FormattedMessage
                  id="payment.paymentProceed"
                  values={{ price: calculateFinalPrice() }}
                />
              }
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                결제 오류 발생 시, https://lacha.co.kr 에서 결제하실 수
                있습니다.
              </Typography>
            </ModalSheet>

            {ProviderUtils.isGME && gmeConnect && (
              <GmeBottomSheet
                cancellable
                okText={gmeConnect?.availablePay ? "Pay" : "Recharge"}
                cancelText="Cancel"
                buttonDirection="column"
              >
                <GMEBottomSheetContent
                  value={gmeConnect}
                  reservationDetails={reservations}
                />
              </GmeBottomSheet>
            )}

            {(isProcessing || isPaymentProcessing) && (
              <LoadingSpinner overlap />
            )}
          </Stack>
        )}
      </Layout>
    )
  );
};

export default ShoppingCartPayment;
