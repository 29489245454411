import defaultLogo from "assets/images/logo/logo.png";
import payboocLogo from "assets/images/logo/paybooc_logo.png";
import gmeLogo from "assets/images/logo/gme_logo.png";
import lottecardLogo from "assets/images/logo/lottecard_logo.png";
import hanacardLogo from "assets/images/logo/hanacard_logo.png";
import hanacardMyReservationButton from "assets/images/hanacard_button.png";
import { useNavigate } from "react-router-dom";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";
import ReservationListIcon from "components/hanacard/ReservationListIcon";
import { ProviderUtils } from "utils/providerUtils";
import { useEffect, useState } from "react";
import clsx from "clsx";
import MyCartIcon from "components/MyCartIcon";

const getLogo = () => {
  if (ProviderUtils.isPaybooc) {
    return payboocLogo;
  }

  if (ProviderUtils.isLottecard) {
    return lottecardLogo;
  }

  if (ProviderUtils.isHanacard) {
    return hanacardLogo;
  }

  if (ProviderUtils.isGME) {
    return gmeLogo;
  }

  return defaultLogo;
};

const getLogoHeight = () => {
  if (ProviderUtils.isGME) {
    return 20;
  }
};

const getLogoWidth = () => {
  if (ProviderUtils.isGME) {
    return 74;
  }

  if (
    ProviderUtils.isHanacard ||
    ProviderUtils.isLottecard ||
    ProviderUtils.isPaybooc
  ) {
    return 120;
  }

  return 54;
};

const Header = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);

  const handleMenuClick = () => {
    navigate("/user-settings");
  };

  useEffect(() => {
    const toggleHeader = () => {
      if (window.scrollY > HEADER_HEIGHT * 1.5) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    window.addEventListener("scroll", toggleHeader);

    return () => {
      window.removeEventListener("scroll", toggleHeader);
    };
  }, []);

  return (
    <header
      className={clsx(
        `fixed top-0 z-50 flex w-full items-center justify-between bg-white px-4 pt-[env(safe-area-inset-top)] shadow-[0_2px_4px_rgba(0,0,0,0.1)] transition duration-150 ease-in-out`,
        {
          "translate-y-0": visible,
          "translate-y-[-100%]": !visible,
        }
      )}
      style={{
        height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,
        direction: "ltr",

        //   padding: "0 16px",
        //   paddingTop: `env(safe-area-inset-top)`,
        //   transition: "transform 0.15s ease",
        //   transform: visible ? "translateY(0)" : "translateY(-100%)",
      }}
    >
      <section className="flex items-center justify-between gap-2">
        {/* Lotte Card에서만 보여주기 */}
        {ProviderUtils.isLottecard && (
          <IconButton
            onClick={closeWebview}
            size="small"
            edge="end"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <img
          src={getLogo()}
          alt="LACHA Logo"
          width={getLogoWidth()}
          height={getLogoHeight()}
          className="h-[55%]"
        />
      </section>
      <section className="flex items-center gap-2 [&>*]:cursor-pointer">
        {/* {ProviderUtils.darkModeAvailable && <ThemeToggle />} */}
        <LanguageSelectIcon />
        <MyCartIcon />
        {ProviderUtils.isHanacard ? (
          <>
            <ReservationListIcon handleMenuClick={handleMenuClick} />
            <img
              onClick={handleMenuClick}
              src={hanacardMyReservationButton}
              alt="hanacard my reservation"
              className="h-10 rounded-2xl"
            />
          </>
        ) : (
          <Menu aria-label="menu" onClick={handleMenuClick} />
        )}
      </section>
    </header>
  );
};

export default Header;
